define('vimme/controllers/teacher/resources', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['weeks', 'page', 'sort'],
        weeks: null,
        page: 1,
        sort: 'week',

        resourcesCache: [],
        resources: (function (key, value) {
            var _this = this;

            if (arguments.length > 1) {
                this.set('resourcesCache', value);
                return value;
            }
            this.store.find('resource', {
                grades: this.get('model.grade'),
                weeks: this.get('weeks'),
                page: this.get('page'),
                sort: this.get('sort')
            }).then(function (resources) {
                _this.set('resources', resources);
            });
            return this.get('resourcesCache');
        }).property('weeks', 'page', 'sort')

    });

    // import Ember from "ember";

    // export default Ember.Controller.extend({

    //     needs: ['application'],

    //     searchTerm: null,

    //     filteredWeeks: function() {

    //         const escape = function(term) {
    //             return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
    //         };

    //         const weeks = this.get('model.weeks');
    //         const term = this.get('searchTerm');
    //         const filter = new RegExp(escape(term), 'i');
    //         let unsorted;

    //         if (term) {
    //             unsorted = weeks.filter(function(week) {
    //                 return filter.test(week.get('longName'));
    //             });
    //         } else {
    //             unsorted = weeks;
    //         }

    //         return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
    //             content: unsorted.toArray(),
    //             sortProperties: ['number'],
    //             sortAscending: true
    //         });

    //     }.property('searchTerm', 'model.weeks.[]')

    // });
});