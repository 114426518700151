define("vimme/components/vimme-resource-editor/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 8
            },
            "end": {
              "line": 45,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resource-editor/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "input-container input-container--quiz-question-image");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "for", "image");
          dom.setAttribute(el2, "class", "label");
          var el3 = dom.createTextNode("Upload");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-icon-only icon-search");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 3, 3);
          morphs[1] = dom.createMorphAt(element0, 7, 7);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "text", "disabled", true, "value", ["subexpr", "@mut", [["get", "uploadPath", ["loc", [null, [41, 56], [41, 66]]]]], [], []], "id", "image", "class", "input"], ["loc", [null, [41, 16], [41, 93]]]], ["inline", "vimme-file-input", [], ["file", ["subexpr", "@mut", [["get", "upload", ["loc", [null, [43, 40], [43, 46]]]]], [], []]], ["loc", [null, [43, 16], [43, 48]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 8
            },
            "end": {
              "line": 50,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resource-editor/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "input-container input-container--quiz-question-entry");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "for", "image");
          dom.setAttribute(el2, "class", "label");
          var el3 = dom.createTextNode("Url");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "resource.url", ["loc", [null, [48, 42], [48, 54]]]]], [], []], "id", "image", "class", "input"], ["loc", [null, [48, 16], [48, 81]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 56,
            "column": 6
          }
        },
        "moduleName": "vimme/components/vimme-resource-editor/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "quiz-question-type");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quiz-question-type__label");
        var el3 = dom.createTextNode("Resource Type:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--category");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "quiz-question-choices");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--grade");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--week");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-container input-container--quiz-question-entry");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "for", "title");
        dom.setAttribute(el4, "class", "label");
        var el5 = dom.createTextNode("Title");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "buttons");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-delete icon-delete");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-update icon-check");
        var el3 = dom.createTextNode("Save");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4, 1]);
        var element3 = dom.childAt(fragment, [6]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 3, 3);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createAttrMorph(element4, 'disabled');
        morphs[6] = dom.createElementMorph(element4);
        morphs[7] = dom.createMorphAt(element4, 0, 0);
        morphs[8] = dom.createAttrMorph(element5, 'disabled');
        morphs[9] = dom.createElementMorph(element5);
        return morphs;
      },
      statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "resourceTypes", ["loc", [null, [5, 20], [5, 33]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.value", "selection", ["subexpr", "@mut", [["get", "selectedResourceType", ["loc", [null, [8, 22], [8, 42]]]]], [], []]], ["loc", [null, [4, 8], [9, 10]]]], ["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "grades", ["loc", [null, [15, 20], [15, 26]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.shortTitle", "selection", ["subexpr", "@mut", [["get", "resource.grade", ["loc", [null, [18, 22], [18, 36]]]]], [], []], "placeholder", "Select Grade"], ["loc", [null, [14, 8], [20, 10]]]], ["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "weeks", ["loc", [null, [24, 20], [24, 25]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.longName", "selection", ["subexpr", "@mut", [["get", "resource.week", ["loc", [null, [27, 22], [27, 35]]]]], [], []], "placeholder", "Select Week"], ["loc", [null, [23, 8], [29, 10]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "resource.title", ["loc", [null, [36, 26], [36, 40]]]]], [], []], "id", "title", "class", "input"], ["loc", [null, [36, 12], [36, 67]]]], ["block", "if", [["get", "showUpload", ["loc", [null, [38, 14], [38, 24]]]]], [], 0, 1, ["loc", [null, [38, 8], [50, 15]]]], ["attribute", "disabled", ["get", "isSaving", ["loc", [null, [54, 78], [54, 86]]]]], ["element", "action", ["cancel"], [], ["loc", [null, [54, 47], [54, 66]]]], ["content", "cancelText", ["loc", [null, [54, 89], [54, 103]]]], ["attribute", "disabled", ["get", "isUnsavable", ["loc", [null, [55, 75], [55, 86]]]]], ["element", "action", ["save"], [], ["loc", [null, [55, 46], [55, 63]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});