define("vimme/templates/sub-admin/resources", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/sub-admin/resources.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "vimme-resources-list", [], ["resources", ["subexpr", "@mut", [["get", "resources", ["loc", [null, [2, 14], [2, 23]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [3, 11], [3, 23]]]]], [], []], "weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [4, 10], [4, 21]]]]], [], []], "selectedGrades", ["subexpr", "@mut", [["get", "grades", ["loc", [null, [5, 19], [5, 25]]]]], [], []], "viewOnly", true, "selectedWeeks", ["subexpr", "@mut", [["get", "weeks", ["loc", [null, [7, 18], [7, 23]]]]], [], []], "page", ["subexpr", "@mut", [["get", "page", ["loc", [null, [8, 9], [8, 13]]]]], [], []], "sort", ["subexpr", "@mut", [["get", "sort", ["loc", [null, [9, 9], [9, 13]]]]], [], []], "editable", false], ["loc", [null, [1, 0], [11, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});