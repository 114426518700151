define('vimme/routes/school-admin/schedule-tests', ['exports', 'vimme/routes/school-admin', 'ember'], function (exports, _vimmeRoutesSchoolAdmin, _ember) {
    exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
        model: function model() {
            var _this = this;

            return _ember['default'].RSVP.hash({
                tests: _this.modelFor('schoolAdmin').tests,
                teacherTests: _this.store.find('teacherTest').then(function (teacherTests) {
                    return teacherTests.reduce(function (prev, teacherTest) {
                        if (!teacherTest.get('teacher.isPlaceholder')) {
                            prev.addObject(teacherTest);
                        }
                        return prev;
                    }, []);
                }),
                teachers: _this.store.find('teacher').then(function (teachers) {
                    return teachers.reduce(function (prev, teacher) {
                        if (!teacher.get('isPlaceholder')) {
                            prev.addObject(teacher);
                        }
                        return prev;
                    }, []);
                }),
                grades: _this.store.find('grade')
            });
        }
    });
});