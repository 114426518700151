define('vimme/components/number-input', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].TextField.extend({

        type: 'number',

        didInsertElement: function didInsertElement() {

            this.$().keypress(function (e) {

                if (e.keyCode !== 46 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) {

                    return false;
                }
            });
        }

    });
});