define("vimme/templates/admin/builder/quiz-questions/quiz-question", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/admin/builder/quiz-questions/quiz-question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "vimme-quiz-question-editor", [], ["quizQuestion", ["subexpr", "@mut", [["get", "model.quizQuestion", ["loc", [null, [2, 17], [2, 35]]]]], [], []], "categories", ["subexpr", "@mut", [["get", "model.categories", ["loc", [null, [3, 15], [3, 31]]]]], [], []], "difficulties", ["subexpr", "@mut", [["get", "model.difficulties", ["loc", [null, [4, 17], [4, 35]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [5, 11], [5, 23]]]]], [], []], "isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [6, 13], [6, 21]]]]], [], []], "cancel", "delete", "cancelText", "Delete", "save", "save"], ["loc", [null, [1, 0], [10, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});