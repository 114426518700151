define('vimme/controllers/school-admin/schedule-tests/day', ['exports', 'ember'], function (exports, _ember) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    exports['default'] = _ember['default'].Controller.extend({

        AMPM: [{ label: 'am', value: false }, { label: 'pm', value: true }],

        startDay: null,
        startTime: null,
        startHour: null,
        startMinute: null,
        startPM: false,

        endDay: null,
        endHour: null,
        endMinute: null,
        endPM: false,

        selected: [],

        isScheduling: false,
        isNotScheduling: _ember['default'].computed.not('isScheduling'),

        scheduleTestsController: _ember['default'].inject.controller('school-admin.schedule-tests'),

        updateStartHourMinute: (function () {

            var startTime = this.get('startTime'),
                startHour = null,
                startMinute = null,
                other = null;

            if (startTime) {
                var _startTime$split = startTime.split(':');

                var _startTime$split2 = _slicedToArray(_startTime$split, 3);

                startHour = _startTime$split2[0];
                startMinute = _startTime$split2[1];
                other = _startTime$split2[2];
            }

            if (other) {
                startHour = 13;
            }

            this.set('startHour', startHour);
            this.set('startMinute', startMinute);
        }).on('init').observes('startTime'),

        updateEndHourMinute: (function () {

            var endTime = this.get('endTime'),
                endHour = null,
                endMinute = null,
                other = null;

            if (endTime) {
                var _endTime$split = endTime.split(':');

                var _endTime$split2 = _slicedToArray(_endTime$split, 3);

                endHour = _endTime$split2[0];
                endMinute = _endTime$split2[1];
                other = _endTime$split2[2];
            }

            if (other) {
                endHour = 13;
            }

            this.set('endHour', endHour);
            this.set('endMinute', endMinute);
        }).on('init').observes('endTime'),

        startDate: (function () {

            var startDay = this.get('startDay'),
                startHour = this.get('startHour'),
                startMinute = this.get('startMinute'),
                startPM = this.get('startPM'),
                startDate;

            if (startDay) {

                startDate = moment(startDay);

                if (startHour) {
                    if (isNaN(startHour) || parseInt(startHour, 10) < 1 || parseInt(startHour, 10) > 12) {
                        return null;
                    }
                    if (parseInt(startHour, 10) !== 12) {
                        startDate.add(startHour, 'hours');
                    }
                }

                if (startMinute) {
                    if (isNaN(startMinute) || parseInt(startMinute, 10) < 0 || parseInt(startMinute, 10) > 59) {
                        return null;
                    }
                    if (startMinute.length === 1) {
                        if (parseInt(startMinute, 10) > 5) {
                            return null;
                        }
                        startMinute += '0';
                    }
                    startDate.add(startMinute, 'minutes');
                }

                if (startPM) {
                    startDate.add(12, 'hours');
                }

                if (startDate.isValid()) {
                    return startDate.toDate();
                }
            }

            return null;
        }).property('startDay', 'startHour', 'startMinute', 'startPM'),

        endDate: (function () {

            var endDay = this.get('endDay'),
                endHour = this.get('endHour'),
                endMinute = this.get('endMinute'),
                endPM = this.get('endPM'),
                endDate;

            if (endDay) {

                endDate = moment(endDay);

                if (endHour) {
                    if (isNaN(endHour) || parseInt(endHour, 10) < 1 || parseInt(endHour, 10) > 12) {
                        return null;
                    }
                    if (parseInt(endHour, 10) !== 12) {
                        endDate.add(endHour, 'hours');
                    }
                }

                if (endMinute) {
                    if (isNaN(endMinute) || parseInt(endMinute, 10) < 0 || parseInt(endMinute, 10) > 59) {
                        return null;
                    }
                    if (endMinute.length === 1) {
                        endMinute += '0';
                    }
                    endDate.add(endMinute, 'minutes');
                }

                if (endPM) {
                    endDate.add(12, 'hours');
                }

                if (endDate.isValid()) {
                    return endDate.toDate();
                }
            }

            return null;
        }).property('endDay', 'endHour', 'endMinute', 'endPM'),

        endDateIsAfterStartDate: _ember['default'].computed('startDate', 'endDate', function () {

            var startDate = moment(this.get('startDate')),
                endDate = moment(this.get('endDate'));

            return startDate.isValid() && endDate.isValid() && endDate.isAfter(startDate);
        }),

        isSchedulable: _ember['default'].computed.and('selected.length', 'startDate', 'endDate', 'endDateIsAfterStartDate', 'isNotScheduling', 'isNotUnscheduling'),
        isNotSchedulable: _ember['default'].computed.not('isSchedulable'),

        isReallySchedulable: (function () {

            var selected = this.get('selected'),
                startDate = this.get('startDate'),
                endDate = this.get('endDate'),
                blocker = false;

            if (selected) {
                blocker = !!selected.find(function (teacherTest) {
                    var notEmpty = !!teacherTest.get('studentTests.length'),
                        startChanged = !!moment(teacherTest.get('startDate')).diff(startDate),
                        endReduced = moment(teacherTest.get('endDate')).isAfter(endDate);

                    return notEmpty && (startChanged || endReduced);
                });
            }

            return !blocker && this.get('isSchedulable');
        }).property('isSchedulable', 'selected.@each'),
        isNotReallySchedulable: _ember['default'].computed.not('isReallySchedulable'),

        isUnscheduling: false,
        isNotUnscheduling: _ember['default'].computed.not('isUnscheduling'),

        isUnschedulable: (function () {

            return !this.get('selected.length') || this.get('isScheduling') || this.get('isUnscheduling') || !!this.get('selected').find(function (teacherTest) {
                return !!teacherTest.get('studentTests.length');
            });
        }).property('isScheduling', 'isUnscheduling', 'selected.@each'),
        isNotUnschedulable: _ember['default'].computed.not('isUnschedulable'),

        actions: {

            selectStartDay: function selectStartDay(day) {
                this.set('startDay', day);
                this.set('showStartPicker', false);
            },

            selectEndDay: function selectEndDay(day) {
                this.set('endDay', day);
                this.set('showEndPicker', false);
            },

            toggleStartPicker: function toggleStartPicker() {
                this.toggleProperty('showStartPicker');
            },

            toggleEndPicker: function toggleEndPicker() {
                this.toggleProperty('showEndPicker');
            },

            add: function add(teacherTest) {

                this.get('selected').addObject(teacherTest);
            },

            remove: function remove(teacherTest) {

                this.get('selected').removeObject(teacherTest);
            },

            schedule: function schedule() {
                var _this = this;

                this.set('isScheduling', true);

                var selected = this.get('selected');

                var teacherTestsToSave = _ember['default'].RSVP.all(selected.map(function (teacherTest) {

                    teacherTest.set('startDate', _this.get('startDate'));
                    teacherTest.set('endDate', _this.get('endDate'));

                    return teacherTest.save()['catch'](function () {
                        teacherTest.rollback();
                    });
                }));

                teacherTestsToSave['finally'](function () {
                    _this.set('isScheduling', false);
                    _this.set('showStartPicker', false);
                    _this.set('showEndPicker', false);
                });
            },

            unschedule: function unschedule() {
                var _this2 = this;

                this.set('isUnscheduling', true);

                var selected = this.get('selected');

                var teacherTestsToSave = _ember['default'].RSVP.all(selected.map(function (teacherTest) {
                    return teacherTest.destroyRecord().then(function () {
                        selected.removeObject(teacherTest);
                        _this2.get('model.teacherTests').removeObject(teacherTest);
                        var parentTeacherTests = _this2.get('scheduleTestsController.model.teacherTests');
                        if (parentTeacherTests) {
                            parentTeacherTests.removeObject(teacherTest);
                        }
                    })['catch'](function () {
                        teacherTest.rollback();
                    });
                }));

                teacherTestsToSave['finally'](function () {
                    _this2.set('isUnscheduling', false);
                    _this2.set('showStartPicker', false);
                    _this2.set('showEndPicker', false);
                });
            }

        }

    });
});