define('vimme/controllers/school-admin/management/teachers/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        schoolAdminController: _ember['default'].inject.controller('schoolAdmin'),
        school: _ember['default'].computed.alias('schoolAdminController.model.user.school'),

        lastName: '',
        firstName: '',
        grade: null,
        email: '',
        password: '',

        username: _ember['default'].computed.alias('email'),

        resetVals: (function () {

            this.set('lastName', '');
            this.set('firstName', '');
            this.set('grade', null);
            this.set('email', '');
            this.set('password', '');
        }).on('init'),

        isValid: (function () {

            var lastName = this.get('lastName'),
                firstName = this.get('firstName'),
                grade = this.get('grade'),
                email = this.get('email'),
                password = this.get('password');

            return !!lastName && !!firstName && !!grade && !!email && !!password && password.length >= 5;
        }).property('lastName', 'firstName', 'email', 'password', 'grade'),

        isUnsavable: _ember['default'].computed.not('isValid'),

        actions: {

            save: function save() {
                var _this = this;

                this.get('school').then(function (school) {

                    var teacher = _this.store.createRecord('teacher', {

                        lastName: _this.get('lastName'),
                        firstName: _this.get('firstName'),
                        school: school,
                        grade: _this.get('grade'),
                        email: _this.get('email'),
                        username: _this.get('username'),
                        password: _this.get('password')

                    });

                    teacher.save().then(function () {

                        _this.set('lastName', '');
                        _this.set('firstName', '');
                        _this.set('grade', null);
                        _this.set('email', '');
                        _this.set('password', '');
                    })['catch'](function () {
                        teacher.deleteRecord();
                    });
                });
            }

        }

    });
});