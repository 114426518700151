define('vimme/serializers/quiz', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].ActiveModelSerializer.extend({

        serialize: function serialize(record) {
            var json = {};

            json.id = record.id ? record.id : undefined;
            json.title = record.attr('title');

            json.teacher_id = record.belongsTo('teacher', { id: true });

            json.quiz_question_ids = record.hasMany('quizQuestions', { ids: true });

            return json;
        }

    });
});