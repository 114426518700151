define('vimme/utils/color', ['exports'], function (exports) {
    exports['default'] = function (percent) {

        if (percent >= 85) {
            return 'benchmark';
        } else if (percent >= 63) {
            return 'strategic';
        } else {
            return 'intensive';
        }
    };
});