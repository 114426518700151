define('vimme/controllers/admin/questions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        needs: ['admin/test', 'application'],

        queryParams: [{ testReviewID: 'test-review-id' }],

        test: _ember['default'].computed.alias('controllers.admin/test.model'),

        testReviewID: null,

        rulerIN: false,
        rulerCM: false,
        protractor: false,

        // set on question route
        currentQuestion: null,

        studentTest: null,
        studentQuestions: null,

        showProtractor: (function () {

            return parseInt(this.get('test.grade.id'), 10) !== 2;
        }).property('test.grade.id'),

        isReviewing: (function () {

            return !!(this.get('testReviewID') && this.get('studentTest'));
        }).property('testReviewID', 'studentTest'),

        handleQueryParam: (function () {

            var _this = this,
                studentTests = this.get('model.studentTests'),
                testReviewID = this.get('testReviewID'),
                studentTest;

            if (studentTests && studentTests.get('length') > 0 && testReviewID) {

                studentTest = studentTests.findBy('id', testReviewID);

                if (!studentTest) {

                    this.set('testReviewID', null);

                    this.replaceRoute({ queryParams: { testReviewID: null } });
                } else {

                    studentTest.get('studentQuestions').then(function (studentQuestions) {

                        _this.set('studentTest', studentTest);
                        _this.set('studentQuestions', studentQuestions);
                    });
                }
            } else {

                this.set('testReviewID', null);

                this.replaceRoute({ queryParams: { testReviewID: null } });
            }
        }).observes('model'),

        length: _ember['default'].computed.alias('model.questions.length'),

        currentIndex: (function () {

            return this.get('model.questions').indexOf(this.get('currentQuestion')) + 1;
        }).property('currentQuestion'),

        previousIndex: (function () {

            var currentIndex = this.get('currentIndex'),
                length = this.get('length');

            if (currentIndex <= 1) {
                return length;
            }

            return --currentIndex;
        }).property('currentIndex'),

        nextIndex: (function () {

            var currentIndex = this.get('currentIndex'),
                length = this.get('length');

            if (currentIndex > length) {
                return 1;
            }

            return ++currentIndex;
        }).property('currentIndex'),

        requiresTools: (function () {

            this.set('rulerIN', false);
            this.set('rulerCM', false);
            this.set('protractor', false);

            return this.get('currentQuestion.requiresTools');
        }).property('currentQuestion'),

        actions: {
            exit: function exit(route) {
                var oldRoute = this.get('controllers.application.savedRoute'),
                    oldModel = this.get('controllers.application.savedModel');

                if (oldRoute && oldModel) {
                    this.transitionToRoute(oldRoute, oldModel);
                    this.set('controllers.application.savedRoute', null);
                    this.set('controllers.application.savedModel', null);
                } else if (oldRoute) {
                    this.transitionToRoute(oldRoute);
                    this.set('controllers.application.savedRoute', null);
                } else {
                    this.transitionToRoute(route);
                }
            },
            toggleRulerIN: function toggleRulerIN() {
                this.set('rulerCM', false);
                this.set('protractor', false);
                this.toggleProperty('rulerIN');
            },
            toggleRulerCM: function toggleRulerCM() {
                this.set('rulerIN', false);
                this.set('protractor', false);
                this.toggleProperty('rulerCM');
            },
            toggleProtractor: function toggleProtractor() {
                this.set('rulerCM', false);
                this.set('rulerIN', false);
                this.toggleProperty('protractor');
            }
        }

    });
});