define('vimme/routes/student/questions', ['exports', 'vimme/routes/student', 'ember'], function (exports, _vimmeRoutesStudent, _ember) {
    exports['default'] = _vimmeRoutesStudent['default'].extend({
        beforeModel: function beforeModel(transition) {

            this._super(transition);

            if (transition.targetName === 'student.questions.index') {
                this.replaceWith('student.questions.question', 1);
            }
        },
        model: function model() {
            var teacherTest = this.modelFor('student.test').get('teacherTests.firstObject');
            return _ember['default'].RSVP.hash({
                test: this.modelFor('student.test'),
                questions: this.modelFor('student.test').get('questions'),
                answers: this.modelFor('student.test').get('answers'),
                studentQuestions: this.modelFor('student.test').get('studentQuestions'),
                teacherTest: this.modelFor('student.test').get('teacherTests.firstObject'),
                studentTest: teacherTest.get('studentTests.firstObject')
            });
        },
        afterModel: function afterModel(model) {
            if (model.questions.get('length') === 0) {
                this.replaceWith('teacher');
            }
        },
        resetController: function resetController(controller, isExiting) {
            this._super.apply(this, arguments);

            if (isExiting) {
                controller.get('model.studentQuestions').reload();
                controller.get('model.answers').reload();
            }
        }
    });
});