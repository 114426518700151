define('vimme/routes/sub-admin/reports', ['exports', 'ember', 'vimme/routes/sub-admin'], function (exports, _ember, _vimmeRoutesSubAdmin) {
  exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'sub-admin.reports.index') {
        if (transition.sequence === 0) {
          this.replaceWith('sub-admin.reports.week');
        } else {
          this.transitionTo('sub-admin.reports.week');
        }
      }
    },
    model: function model() {
      var grades = this.store.find('grade'),
          schools = this.store.find('school'),
          schoolYears = this.store.find('schoolYear'),
          weeks = this.store.find('week');

      var hash = {
        grades: grades,
        schools: schools,
        schoolYears: schoolYears,
        weeks: weeks
      };

      return _ember['default'].RSVP.hash(hash);
    }
  });
});