define("vimme/models/student-test", ["exports", "ember-data", "vimme/utils/round"], function (exports, _emberData, _vimmeUtilsRound) {
    exports["default"] = _emberData["default"].Model.extend({

        isComplete: _emberData["default"].attr('boolean'),
        total: _emberData["default"].attr('number'),
        score: _emberData["default"].attr('number'),

        createdAt: _emberData["default"].attr('isodate'),
        updatedAt: _emberData["default"].attr('isodate'),

        student: _emberData["default"].belongsTo('student', { async: true }),
        teacherTest: _emberData["default"].belongsTo('teacherTest', { async: true }),
        studentQuestions: _emberData["default"].hasMany('studentQuestion', { async: true }),

        percent: (function () {

            var percent = this.get('score') / this.get('total') * 100;

            return percent;
        }).property('total', 'score'),

        formattedPercent: (function () {

            return (0, _vimmeUtilsRound["default"])(this.get('percent')) + '%';
        }).property('percent'),

        color: (function () {

            var percent = (0, _vimmeUtilsRound["default"])(this.get('percent'));

            if (percent >= 85) {
                return 'benchmark';
            } else if (percent >= 63) {
                return 'strategic';
            } else {
                return 'intensive';
            }
        }).property('percent')

    });
});