define('vimme/routes/school-admin', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin', 'vimme/utils/embed-zendesk'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin, _vimmeUtilsEmbedZendesk) {
    exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
        beforeModel: function beforeModel(transition) {
            var session = this.get('session.content');

            if (session.role !== 'SchoolAdmin') {
                this.replaceWith('index');
            } else if (transition.targetName === 'school-admin.index') {
                if (transition.sequence === 0) {
                    this.replaceWith('school-admin.schedule-tests');
                } else {
                    this.transitionTo('school-admin.schedule-tests');
                }
            }
        },
        model: function model() {
            var store = this.store,
                session = this.get('session.content');

            var tests = store.all('test');

            return _ember['default'].RSVP.hash({
                tests: tests.get('length') ? tests : store.find('test'),
                user: store.find(session.role, session.id)
            });
        },
        afterModel: function afterModel(model) {
            var user = model.user;
            (0, _vimmeUtilsEmbedZendesk['default'])();
            if (user) {
                window.zE(function () {
                    window.zE.identify({
                        name: user.get('fullName'),
                        email: user.get('email')
                    });
                });
            }
            this._super.apply(this, arguments);
        }
    });
});