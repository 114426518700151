define("vimme/components/vimme-quiz-editor/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 12
            },
            "end": {
              "line": 22,
              "column": 12
            }
          },
          "moduleName": "vimme/components/vimme-quiz-editor/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "quiz-builder__question no-swipe sortable-item");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "quiz-builder__question-reorder icon-reorder btn btn-icon-only instant");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "quiz-builder__question-text");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "quiz-builder__question-delete icon-delete btn btn-icon-only btn-cancel");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [7]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 5, 5);
          morphs[2] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["inline", "trim-text", [["get", "quizQuestion.title", ["loc", [null, [18, 74], [18, 92]]]]], [], ["loc", [null, [18, 62], [18, 94]]]], ["inline", "vimme-hoverable-icon", [], ["class", "btn btn-icon-only icon-eye", "action", "togglePreview", "value", ["subexpr", "@mut", [["get", "quizQuestion", ["loc", [null, [19, 107], [19, 119]]]]], [], []]], ["loc", [null, [19, 20], [19, 121]]]], ["element", "action", ["remove", ["get", "quizQuestion", ["loc", [null, [20, 120], [20, 132]]]]], [], ["loc", [null, [20, 102], [20, 134]]]]],
        locals: ["quizQuestion"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-quiz-editor/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-quiz-question", [], ["quizQuestion", ["subexpr", "@mut", [["get", "currentQuizQuestion", ["loc", [null, [28, 21], [28, 40]]]]], [], []], "showImage", ["subexpr", "@mut", [["get", "currentQuizQuestion.imagePath", ["loc", [null, [29, 18], [29, 47]]]]], [], []], "index", ["subexpr", "@mut", [["get", "currentIndex", ["loc", [null, [30, 14], [30, 26]]]]], [], []], "alignTop", ["subexpr", "@mut", [["get", "alignTop", ["loc", [null, [31, 17], [31, 25]]]]], [], []], "alignLeft", ["subexpr", "@mut", [["get", "alignLeft", ["loc", [null, [32, 18], [32, 27]]]]], [], []]], ["loc", [null, [27, 4], [33, 6]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 7
          }
        },
        "moduleName": "vimme/components/vimme-quiz-editor/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "quiz-builder__buttons");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--title");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "label");
        dom.setAttribute(el3, "for", "title");
        var el4 = dom.createTextNode("Quiz/Homework Title");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-cancel icon-delete");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-save icon-check");
        var el3 = dom.createTextNode("Save");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-orange icon-print");
        var el3 = dom.createTextNode("Print");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "quiz-builder__questions-container");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "label");
        var el3 = dom.createTextNode("Questions");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quiz-builder__questions");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "quiz-builder__add-question btn btn-save btn-icon-left icon-add");
        var el4 = dom.createTextNode("Add Question");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ol");
        dom.setAttribute(el3, "class", "quiz-builder__questions-list sortable-list");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element2, [5]);
        var element5 = dom.childAt(element2, [7]);
        var element6 = dom.childAt(fragment, [2, 3]);
        var element7 = dom.childAt(element6, [1]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 3, 3);
        morphs[1] = dom.createAttrMorph(element3, 'disabled');
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(element3, 0, 0);
        morphs[4] = dom.createAttrMorph(element4, 'disabled');
        morphs[5] = dom.createElementMorph(element4);
        morphs[6] = dom.createAttrMorph(element5, 'disabled');
        morphs[7] = dom.createElementMorph(element5);
        morphs[8] = dom.createElementMorph(element7);
        morphs[9] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        morphs[10] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "input", [], ["class", "input", "type", "text", "id", "title", "value", ["subexpr", "@mut", [["get", "quiz.title", ["loc", [null, [4, 59], [4, 69]]]]], [], []]], ["loc", [null, [4, 8], [4, 71]]]], ["attribute", "disabled", ["get", "isNotCancelable", ["loc", [null, [6, 78], [6, 93]]]]], ["element", "action", ["cancel"], [], ["loc", [null, [6, 47], [6, 66]]]], ["inline", "if", [["get", "isNew", ["loc", [null, [6, 101], [6, 106]]]], "Cancel", "Delete"], [], ["loc", [null, [6, 96], [6, 126]]]], ["attribute", "disabled", ["get", "isNotSavable", ["loc", [null, [7, 73], [7, 85]]]]], ["element", "action", ["save"], [], ["loc", [null, [7, 44], [7, 61]]]], ["attribute", "disabled", ["get", "isNotPrintable", ["loc", [null, [8, 76], [8, 90]]]]], ["element", "action", ["print"], [], ["loc", [null, [8, 46], [8, 64]]]], ["element", "action", ["showAdd"], [], ["loc", [null, [13, 87], [13, 107]]]], ["block", "each", [["get", "quiz.quizQuestions", ["loc", [null, [15, 20], [15, 38]]]]], [], 0, null, ["loc", [null, [15, 12], [22, 21]]]], ["block", "if", [["get", "currentQuizQuestion", ["loc", [null, [26, 6], [26, 25]]]]], [], 1, null, ["loc", [null, [26, 0], [34, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});