define('vimme/controllers/teacher/testing-calendar', ['exports', 'ember', 'vimme/utils/date-in-range', 'vimme/config/environment'], function (exports, _ember, _vimmeUtilsDateInRange, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        isDemo: (function () {
            return _vimmeConfigEnvironment['default'].environment === 'demo' || _vimmeConfigEnvironment['default'].environment === 'demo-dev';
        }).property(),

        selectedDate: null,
        selectedItems: null,

        pacingGuide: (function () {
            return this.assets.resolve('assets/pacing-guides/grade-' + this.get('session.grade') + '-pacing-guide.pdf');
        }).property('session.grade'),

        sortedTeacherTests: (function () {

            var tests = this.get('model.teacherTests'),
                sorted;

            sorted = _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                content: tests.toArray(),
                sortProperties: ['startDate'],
                sortAscending: true
            });

            return sorted;
        }).property('model.teacherTests.@each.startDate'),

        selectDay: function selectDay(day) {

            var found = false;

            this.set('selectedDate', day);

            found = this.get('sortedTeacherTests').find(function (test) {
                return !!day && (0, _vimmeUtilsDateInRange['default'])(day, test.get('startDate'), test.get('endDate'));
            });

            if (found) {
                this.transitionToRoute('teacher.testing-calendar.test', found.get('test.id'));
            }
        },

        actions: {

            select: function select(day) {
                this.selectDay(day);
            },

            selectToday: function selectToday() {
                this.selectDay(moment().startOf('day').toDate());
            }

        }

    });
});