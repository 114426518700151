define('vimme/routes/teacher/questions/question', ['exports', 'vimme/routes/teacher'], function (exports, _vimmeRoutesTeacher) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model(params) {
            var questions = this.modelFor('teacher.questions').questions,
                length = questions.get('length');

            if (params.question_id > 0 && params.question_id <= length) {

                return questions.objectAt(params.question_id - 1);
            }

            this.replaceWith('teacher.questions.question', 1);
        },
        setupController: function setupController(controller, model) {

            controller.set('model', model);

            // controller.set('hideAnswers', true);

            this.controllerFor('teacher.questions').set('currentQuestion', model);
        }
    });
});