define('vimme/controllers/admin/builder/resources', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        application: _ember['default'].inject.controller(),
        'new': _ember['default'].inject.controller('admin.builder.resources.new'),

        actions: {
            newResource: function newResource() {
                if (this.get('application.currentRouteName') !== 'admin.builder.resources.new') {
                    this.transitionToRoute('admin.builder.resources.new');
                } else {
                    this.store.all('resource').filterBy('id', null).invoke('deleteRecord');
                    this.set('new.model.resource', this.store.createRecord('resource', {
                        title: null
                    }));
                }
            }
        }

    });
});