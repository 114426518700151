define("vimme/models/per-teacher-average", ["exports", "ember-data", "vimme/utils/round"], function (exports, _emberData, _vimmeUtilsRound) {
    exports["default"] = _emberData["default"].Model.extend({

        total: _emberData["default"].attr('number'),
        scores: _emberData["default"].attr('string'),

        teacher: _emberData["default"].belongsTo('teacher', { async: true }),
        test: _emberData["default"].belongsTo('test', { async: true }),
        teacherTest: _emberData["default"].belongsTo('teacherTest', { async: true }),

        score: (function () {

            var scores = this.get('scores').split(',');

            scores.sort(function (a, b) {
                return parseInt(a, 10) - parseInt(b, 10);
            });

            if (scores.length > 2) {

                scores = scores.slice(2);
            }

            return scores.reduce(function (prev, curr) {
                return parseInt(prev, 10) + parseInt(curr, 10);
            }) / scores.length;
        }).property('scores'),

        percent: (function () {

            var score = this.get('score'),
                total = this.get('total');

            return score / total * 100;
        }).property('score', 'total'),

        formattedPercent: (function () {

            return (0, _vimmeUtilsRound["default"])(this.get('percent')) + '%';
        }).property('percent'),

        color: (function () {

            var percent = (0, _vimmeUtilsRound["default"])(this.get('percent'));

            if (percent >= 85) {
                return 'benchmark';
            } else if (percent >= 63) {
                return 'strategic';
            } else {
                return 'intensive';
            }
        }).property('percent')

    });
});