define('vimme/components/vimme-zone-date-row/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-zone-date-row', 'tr'],

        teacherTest: null,

        selected: false,

        actions: {

            toggle: function toggle(teacherTest) {
                if (this.get('selected')) {
                    this.sendAction("remove", teacherTest);
                } else {
                    this.sendAction("add", teacherTest);
                }
                this.toggleProperty('selected');
            }

        }

    });
});