define('vimme/routes/admin/builder/tests/test/questions', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model() {
            var test = this.modelFor('admin.builder.tests.test').test;
            var questions = test.get('questions');
            var answers = test.get('answers');
            return _ember['default'].RSVP.hash({
                test: test,
                questions: questions,
                answers: answers
            });
        },
        actions: {
            saveQuestion: function saveQuestion(question) {
                return question.save().then(function (question) {
                    question.get('answers').filterBy('id', null).invoke('deleteRecord');
                    return question;
                });
            },
            cancel: function cancel() {
                this.transitionTo('admin.builder.tests.test.questions');
            },
            reroute: function reroute(question) {
                this.transitionTo('admin.builder.tests.test.questions.question', question.get('id'));
            }
        }
    });
});