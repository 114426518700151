define("vimme/templates/components/vimme-protractor", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/components/vimme-protractor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "vimme-protractor-reset btn btn-icon-only icon-reset");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "draggable", "false");
        dom.setAttribute(el1, "src", "assets/images/protractor.png");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [4]);
        var morphs = new Array(3);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createElementMorph(element1);
        return morphs;
      },
      statements: [["element", "action", ["resetRotate"], [], ["loc", [null, [1, 68], [1, 92]]]], ["attribute", "class", ["concat", ["vimme-protractor-rotate btn btn-icon-only ", ["subexpr", "if", [["get", "rotating", ["loc", [null, [3, 62], [3, 70]]]], "icon-move", "icon-rotate"], [], ["loc", [null, [3, 57], [3, 98]]]]]]], ["element", "action", ["toggleRotate"], [], ["loc", [null, [3, 100], [3, 125]]]]],
      locals: [],
      templates: []
    };
  })());
});