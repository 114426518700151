define("vimme/models/school-year", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        name: _emberData["default"].attr('string'),
        processingState: _emberData["default"].attr('string'),

        createdAt: _emberData["default"].attr('isodate'),
        updatedAt: _emberData["default"].attr('isodate'),

        school: _emberData["default"].belongsTo('school', { async: true }),

        schoolYearGradeReports: _emberData["default"].hasMany('schoolYearGradeReport', { async: true }),

        isStarted: _ember["default"].computed.equal('processingState', 'started'),
        isQueued: _ember["default"].computed.equal('processingState', 'queued'),
        isCompleted: _ember["default"].computed.equal('processingState', 'completed'),

        nameSchool: _ember["default"].computed('name', 'school.title', function () {
            return this.get('name') + ' (' + this.get('school.title') + ')';
        })
    });
});