define('vimme/routes/admin', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _simpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsAuthenticatedRouteMixin['default'], {
        beforeModel: function beforeModel(transition) {
            var session = this.get('session.content');

            this._super(transition);

            if (session.role !== 'Admin') {
                this.replaceWith('index');
            } else if (transition.targetName === 'admin.index') {
                if (transition.sequence === 0) {
                    this.replaceWith('admin.management');
                } else {
                    this.transitionTo('admin.management');
                }
            }
        },
        model: function model() {
            var store = this.store,
                session = this.get('session.content');

            return _ember['default'].RSVP.hash({
                tests: store.find('test'),
                user: store.find(session.role, session.id)
            });
        }
    });
});