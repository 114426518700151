define('vimme/routes/admin/builder/tests/test/questions/index', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model() {
            var test = this.modelFor('admin.builder.tests.test.questions').test;
            var questions = this.modelFor('admin.builder.tests.test.questions').questions;
            return _ember['default'].RSVP.hash({
                test: test,
                questions: questions
            });
        }
    });
});