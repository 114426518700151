define('vimme/routes/application', ['exports', 'ember', 'simple-auth/mixins/application-route-mixin', 'simple-auth/configuration', 'vimme/config/environment'], function (exports, _ember, _simpleAuthMixinsApplicationRouteMixin, _simpleAuthConfiguration, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Route.extend(_simpleAuthMixinsApplicationRouteMixin['default'], {
        actions: {
            // error: function() {
            //     this.transitionTo('index');
            // },
            sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
                var session = this.get('session.content'),
                    attemptedTransition = this.get('session.attemptedTransition');

                if (attemptedTransition) {
                    attemptedTransition.retry();
                    this.set('session.attemptedTransition', null);
                } else if (session.role === 'Admin') {
                    this.transitionTo('admin.management');
                } else if (session.role === 'SchoolAdmin') {
                    this.transitionTo('school-admin.schedule-tests');
                } else if (session.role === 'SubAdmin') {
                    this.transitionTo('sub-admin.reports.week');
                } else if (session.role === 'Teacher') {
                    this.transitionTo('teacher.testing-calendar');
                } else if (session.role === 'Student') {
                    this.transitionTo('student.testing-calendar');
                } else {
                    this.transitionTo(_simpleAuthConfiguration['default'].routeAfterAuthentication);
                }
            },
            gotoTest: function gotoTest(id, model) {

                var role = this.controller.get('session.role').dasherize();

                this.controller.set('savedRoute', this.controller.get('currentRouteName'));

                if (model) {
                    this.controller.set('savedModel', model);
                }

                this.transitionTo(role + '.questions', id, { queryParams: { testReviewID: 'resetme' } });
            },
            gotoTestReview: function gotoTestReview(id, questionNum, queryParam, model) {

                var role = this.controller.get('session.role').dasherize();

                if (!questionNum) {
                    questionNum = 1;
                }

                this.controller.set('savedRoute', this.controller.get('currentRouteName'));

                if (model) {
                    this.controller.set('savedModel', model);
                }

                this.transitionTo(role + '.questions.question', id, questionNum, { queryParams: { testReviewID: queryParam } });
            }
        },
        beforeModel: function beforeModel(transition) {

            var _this = this,
                hash = {
                url: _vimmeConfigEnvironment['default'].API.host + '/preloads',
                success: function success(data) {
                    _this.store.pushPayload(data);
                }
            };

            this._super(transition);

            return _ember['default'].$.ajax(hash);
        }
    });
});