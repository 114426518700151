define('vimme/routes/admin/teachers/teacher', ['exports', 'vimme/routes/admin'], function (exports, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model(params) {
            return this.store.find('teacher', params.teacher_id);
        },
        setupController: function setupController(controller, model) {
            var schools = this.store.find('school');

            schools.then(function (schools) {
                controller.set('model', model);
                controller.set('schools', schools);
            });
        }
    });
});