define('vimme/initializers/authentication', ['exports', 'simple-auth-oauth2/authenticators/oauth2', 'vimme/config/environment'], function (exports, _simpleAuthOauth2AuthenticatorsOauth2, _vimmeConfigEnvironment) {

    var CustomAuthenticator = _simpleAuthOauth2AuthenticatorsOauth2['default'].extend({
        makeRequest: function makeRequest(url, data) {
            data.client_id = _vimmeConfigEnvironment['default'].API.clientID;
            data.client_secret = _vimmeConfigEnvironment['default'].API.clientSecret;
            return this._super(url, data);
        }
    });

    exports['default'] = {
        name: 'authentication',
        initialize: function initialize(container) {
            container.register('authenticator:custom', CustomAuthenticator);
        }
    };
});