define('vimme/controllers/admin/management/school-admin', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        showDialog: false,
        showDialogPassword: false,

        password: '',
        passwordConfirmation: '',

        school: null,

        setSchool: (function () {

            var schools = this.get('model.schools');

            if (schools) {
                this.set('school', schools.findBy('id', this.get('model.schoolAdmin.school.id')));
            }
        }).on('init').observes('model.{schoolAdmin.school.id,schools}'),

        validPassword: _ember['default'].computed('password', 'passwordConfirmation', function () {
            var password = this.get('password'),
                passwordConfirmation = this.get('passwordConfirmation');

            return !!password && password.length >= 5 && password === passwordConfirmation;
        }),
        invalidPassword: _ember['default'].computed.not('validPassword'),

        isDirty: (function () {

            return !!this.get('model.schoolAdmin.isDirty') || this.get('model.schoolAdmin.school.id') !== this.get('school.id');
        }).property('model.schoolAdmin.{isDirty,school.id}', 'school.id'),

        isClean: _ember['default'].computed.not('isDirty'),

        isValid: (function () {

            var firstName = (this.get('model.schoolAdmin.firstName') + '').trim(),
                lastName = (this.get('model.schoolAdmin.lastName') + '').trim(),
                username = (this.get('model.schoolAdmin.username') + '').trim();

            return !!firstName && !!lastName && !!username;
        }).property('model.schoolAdmin.{firstName,lastName,username}'),

        isUnsavable: (function () {

            return this.get('isClean') || !this.get('isValid');
        }).property('isClean', 'isValid'),

        actions: {

            showDialog: function showDialog() {
                this.set('showDialog', true);
            },

            cancelDialog: function cancelDialog() {
                this.set('showDialog', false);
            },

            showDialogPassword: function showDialogPassword() {
                this.set('showDialogPassword', true);
            },

            cancelDialogPassword: function cancelDialogPassword() {
                this.set('showDialogPassword', false);
            },

            save: function save(schoolAdmin) {

                schoolAdmin.set('school', this.get('school'));
                schoolAdmin.set('username', schoolAdmin.get('email'));

                schoolAdmin.save();
            },

            'delete': function _delete(schoolAdmin) {
                var _this = this;

                schoolAdmin.destroyRecord().then(function () {
                    _this.set('showDialog', false);
                    _this.transitionToRoute('admin.management');
                })['catch'](function () {
                    schoolAdmin.rollback();
                });
            },

            changePassword: function changePassword(password, passwordConfirmation) {
                var _this2 = this;

                _ember['default'].$.ajax({
                    type: 'PUT',
                    url: _vimmeConfigEnvironment['default'].API.host + '/school-admins/' + this.get('model.schoolAdmin.id') + '/password',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        school_admin: {
                            password: password,
                            password_confirmation: passwordConfirmation
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function (schoolAdmin) {
                    _this2.store.pushPayload(schoolAdmin);
                    _this2.set('showDialogPassword', false);
                    _this2.set('password', '');
                    _this2.set('passwordConfirmation', '');
                });
            }

        }

    });
});