define('vimme/components/vimme-test-question-editor/component', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-test-question-editor'],

        // inputs
        question: null,

        isSaving: false,

        cancelText: 'Cancel',

        image: null,
        grade: 1,

        selectedQuestionType: 'normal',

        updateQuestionType: (function () {
            var question = this.get('question');
            if (question.get('questionType')) {
                this.set('selectedQuestionType', question.get('questionType'));
            }
        }).on('init'),

        testQuestionTypes: [{
            id: 'normal',
            value: 'Single Choice'
        }, {
            id: 'multiple',
            value: 'Multiple Choices'
        }, {
            id: 'text',
            value: 'Text'
        }],

        imagePath: (function () {

            var image = this.get('image');
            var imagePath = this.get('question.imagePath');

            if (image) {
                imagePath = image.name;
            }

            if (imagePath) {
                var splitForward = imagePath.split('/');
                var splitBack = imagePath.split('\\');
                if (splitForward.length > 0) {
                    imagePath = splitForward[splitForward.length - 1];
                } else if (splitBack.length > 0) {
                    imagePath = splitBack[splitBack.length - 1];
                }
                imagePath = decodeURI(imagePath);
            }

            return imagePath;
        }).property('question.imagePath', 'image'),

        isUnsavable: (function () {
            var isUnsavable = this.get('isSaving') || !this.get('question.title') || !this.get('question.test') || !this.get('question.answers.length');
            var image = this.get('showImage') && !this.get('imagePath');
            return isUnsavable || image;
        }).property('isSaving', 'showImage', 'imagePath', 'question.{title,test,answers.length}'),
        isSavable: _ember['default'].computed.not('isUnsavable'),

        image64: null,

        setImage64: (function () {
            var _this = this;

            var image = this.get('image');
            if (image) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    _this.set('image64', e.target.result);
                };
                reader.readAsDataURL(image);
            } else {
                this.set('image64', null);
            }
        }).observes('image'),

        actions: {
            save: function save() {
                var _this2 = this;

                var image = this.get('image');
                var question = this.get('question');

                this.set('isSaving', true);

                if (image) {

                    _ember['default'].$.ajax({
                        type: 'POST',
                        url: _vimmeConfigEnvironment['default'].API.host + '/questions-upload-url',
                        contentType: 'application/json',
                        dataType: 'json',
                        data: JSON.stringify({
                            type: image.type,
                            name: image.name
                        })
                    }).then(function (data) {

                        _ember['default'].$.ajax({
                            type: 'PUT',
                            url: data.upload_url,
                            cache: false,
                            processData: false,
                            contentType: false,
                            headers: {
                                'Cache-Control': 'max-age=86400'
                            },
                            data: image
                        }).then(function () {
                            question.set('imagePath', 'https://s3.amazonaws.com/' + _vimmeConfigEnvironment['default'].S3.bucket + '/questions/' + encodeURI(data.filename));
                            question.set('image', null);
                            question.set('questionType', _this2.get('selectedQuestionType'));
                            _this2.attrs.saveQuestion(question).then(function (question) {
                                _this2.set('isSaving', false);
                                if (_this2.attrs.reroute) {
                                    _this2.attrs.reroute(question);
                                }
                            })['catch'](function () {
                                alert('error saving question');
                                _this2.set('isSaving', false);
                            });
                        }, function () {
                            // upload error
                            _this2.set('isSaving', false);
                        });
                    }, function () {
                        // upload url generation error
                        _this2.set('isSaving', false);
                    });
                } else if (question.get('imagePath')) {
                    question.set('questionType', this.get('selectedQuestionType'));
                    this.attrs.saveQuestion(question).then(function (question) {
                        _this2.set('isSaving', false);
                        if (_this2.attrs.reroute) {
                            _this2.attrs.reroute(question);
                        }
                    })['catch'](function () {
                        alert('error saving question');
                        _this2.set('isSaving', false);
                    });
                } else {
                    question.set('imagePath', null);
                    question.set('questionType', this.get('selectedQuestionType'));
                    this.attrs.saveQuestion(question).then(function (question) {
                        _this2.set('isSaving', false);
                        if (_this2.attrs.reroute) {
                            _this2.attrs.reroute(question);
                        }
                    })['catch'](function () {
                        alert('error saving question');
                        _this2.set('isSaving', false);
                    });
                }
            },
            deleteAnswer: function deleteAnswer(answer) {
                answer.deleteRecord();
            },
            cancelEdit: function cancelEdit() {
                var _this3 = this;

                this.set('image', null);
                var answers = this.get('question.answers');
                this.get('question').rollback();
                answers.filterBy('id', null).invoke('deleteRecord');
                answers.invoke('rollback');
                answers.reload().then(function () {
                    return _this3.attrs.cancel();
                });
            },
            openQuestion: function openQuestion() {
                this.set('showQuestion', true);
            },
            hideQuestion: function hideQuestion() {
                this.set('showQuestion', false);
            },
            clear: function clear() {
                this.set('image', null);
                this.set('question.imagePath', null);
                this.set('question.image', null);
            },
            newAnswer: function newAnswer() {
                var store = this.get('targetObject.store');
                store.createRecord('answer', {
                    question: this.get('question'),
                    isCorrect: this.get('selectedQuestionType') === 'text' ? true : false
                });
            }
        }

    });
});