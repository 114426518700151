define('vimme/controllers/teacher/reports/week', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['print'],

        print: false,

        currentSortDirection: 'desc',
        currentSortColumn: 'average-column',

        teacherAverages: (function () {

            var data = this.get('model.teacherAveragesRaw');
            var averages = [];
            var keys = {};

            if (data.get('length') > 0) {

                data.forEach(function (item) {
                    var teacherID = item.get('teacher.id');

                    if (keys[teacherID] === undefined) {
                        averages.push({
                            teacher: item.get('teacher'),
                            weeklyAverages: [],
                            total: item.get('total')
                        });
                        keys[teacherID] = averages.length - 1;
                    }

                    averages[keys[teacherID]].weeklyAverages.push(item);
                });

                return averages;
            }
        }).property('model.teacherAveragesRaw'),

        averages: (function () {

            var studentTests = this.get('model.studentTests').filterBy('teacherTest.test.isPractice', false);
            var averages = [];
            var keys = {};

            if (studentTests && studentTests.get('length') > 0) {

                studentTests.forEach(function (item) {
                    var studentID = item.get('student.id');

                    if (keys[studentID] === undefined) {
                        averages.push({
                            student: item.get('student'),
                            weeklyAverages: [],
                            total: item.get('total')
                        });
                        keys[studentID] = averages.length - 1;
                    }

                    averages[keys[studentID]].weeklyAverages.push(item);
                });
            }

            averages = averages.toArray().sort(function (a, b) {
                var aAvgs = a.weeklyAverages,
                    bAvgs = b.weeklyAverages,
                    aSum = aAvgs.map(function (item) {
                    return item.get('percent');
                }).reduce(function (last, next) {
                    return last + next;
                }, 0),
                    bSum = bAvgs.map(function (item) {
                    return item.get('percent');
                }).reduce(function (last, next) {
                    return last + next;
                }, 0),
                    aRatio = aSum / aAvgs.get('length'),
                    bRatio = bSum / bAvgs.get('length');

                return bRatio - aRatio;
            });

            return averages;
        }).property('model.studentTests'),

        actions: {
            sortAveragesWeek: function sortAveragesWeek(week) {
                var averages = this.get('averages'),
                    weekNum = week.get('number'),
                    sorted;

                if (this.get('currentSortColumn') === 'W' + weekNum && this.get('currentSortDirection') === 'desc') {

                    this.set('currentSortDirection', 'asc');

                    sorted = averages.toArray().sort(function (a, b) {

                        var aItem = a.weeklyAverages.find(function (weeklyAverage) {
                            if (weeklyAverage.get('test')) {
                                return weeklyAverage.get('test.week.number') === weekNum;
                            } else if (weeklyAverage.get('teacherTest')) {
                                return weeklyAverage.get('teacherTest.test.week.number') === weekNum;
                            }
                        });
                        var bItem = b.weeklyAverages.find(function (weeklyAverage) {
                            if (weeklyAverage.get('test')) {
                                return weeklyAverage.get('test.week.number') === weekNum;
                            } else if (weeklyAverage.get('teacherTest')) {
                                return weeklyAverage.get('teacherTest.test.week.number') === weekNum;
                            }
                        });

                        if (!aItem && !bItem) {
                            return 0;
                        } else if (!aItem) {
                            return 1;
                        } else if (!bItem) {
                            return -1;
                        }

                        return aItem.get('percent') - bItem.get('percent');
                    });
                } else {

                    this.set('currentSortDirection', 'desc');
                    this.set('currentSortColumn', 'W' + weekNum);

                    sorted = averages.toArray().sort(function (a, b) {

                        var aItem = a.weeklyAverages.find(function (weeklyAverage) {
                            if (weeklyAverage.get('test')) {
                                return weeklyAverage.get('test.week.number') === weekNum;
                            } else if (weeklyAverage.get('teacherTest')) {
                                return weeklyAverage.get('teacherTest.test.week.number') === weekNum;
                            }
                        });
                        var bItem = b.weeklyAverages.find(function (weeklyAverage) {
                            if (weeklyAverage.get('test')) {
                                return weeklyAverage.get('test.week.number') === weekNum;
                            } else if (weeklyAverage.get('teacherTest')) {
                                return weeklyAverage.get('teacherTest.test.week.number') === weekNum;
                            }
                        });

                        if (!aItem && !bItem) {
                            return 0;
                        } else if (!aItem) {
                            return 1;
                        } else if (!bItem) {
                            return -1;
                        }

                        return bItem.get('percent') - aItem.get('percent');
                    });
                }

                this.set('averages', sorted);
            },
            sortAveragesOther: function sortAveragesOther(column) {
                var averages = this.get('averages'),
                    currentSortColumn = this.get('currentSortColumn'),
                    currentSortDirection = this.get('currentSortDirection'),
                    sorted;

                if (column === currentSortColumn && currentSortDirection === 'desc') {

                    this.set('currentSortDirection', 'asc');

                    sorted = averages.toArray().reverse();
                } else {

                    this.set('currentSortDirection', 'desc');
                    this.set('currentSortColumn', column);

                    if (column === 'grade-column') {
                        sorted = averages.toArray().sort(function (a, b) {
                            return a.grade.get('id') - b.grade.get('id');
                        });
                    } else if (column === 'student-column') {
                        sorted = averages.toArray().sort(function (a, b) {
                            var aName = a.student.get('lastThenFirst').toLowerCase(),
                                bName = b.student.get('lastThenFirst').toLowerCase();

                            if (aName < bName) {
                                return -1;
                            } else if (aName > bName) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                    } else if (column === 'teacher-column') {
                        sorted = averages.toArray().sort(function (a, b) {
                            var aName = a.teacher.get('lastThenFirst').toLowerCase(),
                                bName = b.teacher.get('lastThenFirst').toLowerCase();

                            if (aName < bName) {
                                return -1;
                            } else if (aName > bName) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                    } else if (column === 'average-column') {
                        sorted = averages.toArray().sort(function (a, b) {
                            var aTotal = a.weeklyAverages.map(function (item) {
                                return item.get('percent');
                            }).reduce(function (last, next) {
                                return last + next;
                            }, 0);
                            var bTotal = b.weeklyAverages.map(function (item) {
                                return item.get('percent');
                            }).reduce(function (last, next) {
                                return last + next;
                            }, 0);

                            var aRatio = aTotal / a.weeklyAverages.get('length');
                            var bRatio = bTotal / b.weeklyAverages.get('length');

                            return bRatio - aRatio;
                        });
                    } else if (column === 'trend-column') {
                        sorted = averages.toArray().sort(function (a, b) {
                            var aRest = a.weeklyAverages.slice(0, a.weeklyAverages.get('length') - 1),
                                aTail = a.weeklyAverages.slice(a.weeklyAverages.get('length') - 1, a.weeklyAverages.get('length')),
                                bRest = b.weeklyAverages.slice(0, b.weeklyAverages.get('length') - 1),
                                bTail = b.weeklyAverages.slice(b.weeklyAverages.get('length') - 1, b.weeklyAverages.get('length')),
                                aTot = aRest.map(function (item) {
                                return item.get('percent');
                            }).reduce(function (last, next) {
                                return last + next;
                            }, 0),
                                bTot = bRest.map(function (item) {
                                return item.get('percent');
                            }).reduce(function (last, next) {
                                return last + next;
                            }, 0),
                                aPct = aTot / aRest.get('length'),
                                bPct = bTot / bRest.get('length'),
                                aDiff = aTail[0].get('percent') - aPct,
                                bDiff = bTail[0].get('percent') - bPct;

                            return bDiff - aDiff;
                        });
                    } else {
                        sorted = averages;
                    }
                }

                this.set('averages', sorted);
            },
            printView: function printView() {
                this.toggleProperty('print');
            }
        }

    });
});