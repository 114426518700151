define('vimme/routes/student/progress-reports', ['exports', 'vimme/routes/student', 'ember'], function (exports, _vimmeRoutesStudent, _ember) {
    exports['default'] = _vimmeRoutesStudent['default'].extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                tests: this.modelFor('student').tests,
                teacherTests: this.store.find('teacherTest'),
                studentTests: this.store.find('studentTest')
            });
        }
    });
});