define('vimme/routes/admin/reports/question', ['exports', 'vimme/routes/admin'], function (exports, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model() {
            return this.modelFor('admin.reports');
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            controller.set('selectedSchoolYear', null);
        }
    });
});