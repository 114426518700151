define('vimme/controllers/school-admin/management/teachers/teacher', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        showDialog: false,
        showDialogPassword: false,
        showDialogAssignStudents: false,

        password: '',
        passwordConfirmation: '',

        selectedStudents: [],

        grade: null,

        setGrade: (function () {

            var grades = this.get('model.grades');

            if (grades) {
                this.set('grade', grades.findBy('id', this.get('model.teacher.grade.id')));
            }
        }).on('init').observes('model.teacher.grade.id'),

        canEditGrade: (function () {
            var teacher = this.get('model.teacher');
            return !teacher.get('isPlaceholder') && !teacher.get('students.length');
        }).property('model.teacher.{students.length,isPlaceholder}'),

        validPassword: _ember['default'].computed('password', 'passwordConfirmation', function () {
            var password = this.get('password'),
                passwordConfirmation = this.get('passwordConfirmation');

            return !!password && password.length >= 5 && password === passwordConfirmation;
        }),
        invalidPassword: _ember['default'].computed.not('validPassword'),

        isDirty: (function () {

            return !!this.get('model.teacher.isDirty') || this.get('model.teacher.grade.id') !== this.get('grade.id');
        }).property('model.teacher.{isDirty,grade.id}', 'grade.id'),

        isClean: _ember['default'].computed.not('isDirty'),

        isValid: (function () {

            var firstName = (this.get('model.teacher.firstName') + '').trim(),
                lastName = (this.get('model.teacher.lastName') + '').trim(),
                username = (this.get('model.teacher.username') + '').trim();

            return !!firstName && !!lastName && !!username;
        }).property('model.teacher.{firstName,lastName,username}'),

        isUnsavable: (function () {

            return this.get('isClean') || !this.get('isValid');
        }).property('isClean', 'isValid'),

        isDeletable: _ember['default'].computed.alias('canEditGrade'),
        isNotDeletable: _ember['default'].computed.not('isDeletable'),

        actions: {

            showDialog: function showDialog() {
                this.set('showDialog', true);
            },

            cancelDialog: function cancelDialog() {
                this.set('showDialog', false);
            },

            showDialogPassword: function showDialogPassword() {
                this.set('showDialogPassword', true);
            },

            cancelDialogPassword: function cancelDialogPassword() {
                this.set('showDialogPassword', false);
            },

            showDialogAssignStudents: function showDialogAssignStudents() {
                this.set('selectedStudents', []);
                this.set('showDialogAssignStudents', true);
            },

            cancelDialogAssignStudents: function cancelDialogAssignStudents() {
                this.set('selectedStudents', []);
                this.set('showDialogAssignStudents', false);
            },

            assignStudents: function assignStudents() {
                var _this = this;

                var selectedStudents = this.get('selectedStudents');
                console.log(selectedStudents);
                var promises = selectedStudents.map(function (student) {
                    return _ember['default'].$.ajax({
                        type: 'POST',
                        url: _vimmeConfigEnvironment['default'].API.host + '/student-transfers',
                        contentType: 'application/json',
                        data: JSON.stringify({
                            student_transfer: {
                                student_id: student.get('id'),
                                teacher_id: _this.get('model.teacher.id')
                            }
                        })
                    }).then(function (data) {
                        _this.store.pushPayload(data);
                        return _this.store.find('student', data.student.id).then(function (student) {
                            return student.get('studentTests').reload();
                        });
                    }, function (error) {
                        var message = _ember['default'].get(error, 'responseJSON.message');
                        if (message) {
                            alert(message);
                        } else {
                            alert('An error has occurred.');
                        }
                    });
                });
                _ember['default'].RSVP.all(promises).then(function () {
                    _this.set('selectedStudents', []);
                    _this.set('showDialogAssignStudents', false);
                });
            },

            save: function save(teacher) {

                teacher.set('grade', this.get('grade'));
                teacher.set('username', teacher.get('email'));

                teacher.save();
            },

            'delete': function _delete(teacher) {
                var _this2 = this;

                teacher.destroyRecord().then(function () {
                    _this2.set('showDialog', false);
                    _this2.transitionToRoute('school-admin.management.teachers');
                })['catch'](function () {
                    teacher.rollback();
                });
            },

            changePassword: function changePassword(password, passwordConfirmation) {
                var _this3 = this;

                _ember['default'].$.ajax({
                    type: 'PUT',
                    url: _vimmeConfigEnvironment['default'].API.host + '/teachers/' + this.get('model.teacher.id') + '/password',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        teacher: {
                            password: password,
                            password_confirmation: passwordConfirmation
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function (teacher) {
                    _this3.store.pushPayload(teacher);
                    _this3.set('showDialogPassword', false);
                    _this3.set('password', '');
                    _this3.set('passwordConfirmation', '');
                });
            }

        }

    });
});