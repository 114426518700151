define('vimme/controllers/teacher/testing-calendar/test', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        needs: ['teacher/testing-calendar'],

        showDialog: false,

        setCalendarDate: (function () {

            var selectedDate = this.get('controllers.teacher/testing-calendar.selectedDate');

            if (!selectedDate) {

                this.set('controllers.teacher/testing-calendar.selectedDate', this.get('model.dateAvailable'));
            }
        }).observes('model.dateAvailable'),

        teacherTest: _ember['default'].computed.alias('model.teacherTests.firstObject'),

        isNotViewable: _ember['default'].computed.not('teacherTest.isViewable'),
        isNotActive: _ember['default'].computed.not('teacherTest.isActive'),

        actions: {

            toggleReview: function toggleReview() {

                var teacherTest = this.get('teacherTest');

                if (teacherTest) {

                    teacherTest.toggleProperty('isReviewable');
                    teacherTest.save()['catch'](function () {
                        teacherTest.toggleProperty('isReviewable');
                    });
                }
            }

        }

    });
});