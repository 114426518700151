define('vimme/routes/admin/teachers/index', ['exports', 'vimme/routes/admin'], function (exports, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model() {
            return this.modelFor('admin.teachers');
        },
        setupController: function setupController(controller, model) {
            var schools = this.store.find('school');

            schools.then(function (schools) {
                controller.set('model', model);
                controller.set('schools', schools);
            });
        }
    });
});