define("vimme/components/vimme-resources-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 14,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "input-container input-container--grades");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "grades", ["loc", [null, [6, 24], [6, 30]]]]], [], []], "optionValuePath", "content.shortTitle", "optionLabelPath", "content.shortTitle", "selection", ["subexpr", "@mut", [["get", "localSelectedGrades", ["loc", [null, [9, 26], [9, 45]]]]], [], []], "placeholder", "Select Grade", "select-item", "gradeChanged"], ["loc", [null, [5, 12], [12, 14]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 16
              },
              "end": {
                "line": 33,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-resources-list/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("Grade");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["th td-grade sortable ", ["subexpr", "if", [["get", "sortedByGrade", ["loc", [null, [32, 58], [32, 71]]]], "sorted"], [], ["loc", [null, [32, 53], [32, 82]]]]]]], ["element", "action", ["sortBy", "grade"], [], ["loc", [null, [32, 84], [32, 111]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 12
            },
            "end": {
              "line": 35,
              "column": 12
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Resource");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Week");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(fragment, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createElementMorph(element6);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[3] = dom.createAttrMorph(element7, 'class');
          morphs[4] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["th td-resource sortable ", ["subexpr", "if", [["get", "sortedByTitle", ["loc", [null, [30, 57], [30, 70]]]], "sorted"], [], ["loc", [null, [30, 52], [30, 81]]]]]]], ["element", "action", ["sortBy", "title"], [], ["loc", [null, [30, 83], [30, 110]]]], ["block", "unless", [["get", "hideGrades", ["loc", [null, [31, 26], [31, 36]]]]], [], 0, null, ["loc", [null, [31, 16], [33, 27]]]], ["attribute", "class", ["concat", ["th td-week sortable ", ["subexpr", "if", [["get", "sortedByWeek", ["loc", [null, [34, 53], [34, 65]]]], "sorted"], [], ["loc", [null, [34, 48], [34, 76]]]]]]], ["element", "action", ["sortBy", "week"], [], ["loc", [null, [34, 78], [34, 104]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 12
            },
            "end": {
              "line": 40,
              "column": 12
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "th td-edit");
          var el2 = dom.createTextNode("Edit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Resource");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Grade");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("Week");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [3]);
          var element3 = dom.childAt(fragment, [5]);
          var element4 = dom.childAt(fragment, [7]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createAttrMorph(element3, 'class');
          morphs[3] = dom.createElementMorph(element3);
          morphs[4] = dom.createAttrMorph(element4, 'class');
          morphs[5] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["th td-resource sortable ", ["subexpr", "if", [["get", "sortedByTitle", ["loc", [null, [37, 57], [37, 70]]]], "sorted"], [], ["loc", [null, [37, 52], [37, 81]]]]]]], ["element", "action", ["sortBy", "title"], [], ["loc", [null, [37, 83], [37, 110]]]], ["attribute", "class", ["concat", ["th td-grade sortable ", ["subexpr", "if", [["get", "sortedByGrade", ["loc", [null, [38, 54], [38, 67]]]], "sorted"], [], ["loc", [null, [38, 49], [38, 78]]]]]]], ["element", "action", ["sortBy", "grade"], [], ["loc", [null, [38, 80], [38, 107]]]], ["attribute", "class", ["concat", ["th td-week sortable ", ["subexpr", "if", [["get", "sortedByWeek", ["loc", [null, [39, 53], [39, 65]]]], "sorted"], [], ["loc", [null, [39, 48], [39, 76]]]]]]], ["element", "action", ["sortBy", "week"], [], ["loc", [null, [39, 78], [39, 104]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 8
            },
            "end": {
              "line": 46,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-resource-row", [], ["resource", ["subexpr", "@mut", [["get", "resource", ["loc", [null, [45, 42], [45, 50]]]]], [], []], "viewOnly", ["subexpr", "@mut", [["get", "viewOnly", ["loc", [null, [45, 60], [45, 68]]]]], [], []], "hideGrades", ["subexpr", "@mut", [["get", "hideGrades", ["loc", [null, [45, 80], [45, 90]]]]], [], []], "setVideoUrl", ["subexpr", "action", ["setVideoUrl"], [], ["loc", [null, [45, 103], [45, 125]]]]], ["loc", [null, [45, 12], [45, 127]]]]],
        locals: ["resource"],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 8
            },
            "end": {
              "line": 50,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tr");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "td td-none");
          var el3 = dom.createElement("em");
          var el4 = dom.createTextNode("No Resources");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 16
              },
              "end": {
                "line": 57,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-resources-list/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("< Prev");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["prev"], [], ["loc", [null, [56, 23], [56, 40]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 16
              },
              "end": {
                "line": 63,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-resources-list/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Next >");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["next"], [], ["loc", [null, [62, 23], [62, 40]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 8
            },
            "end": {
              "line": 65,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tfoot__prev");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("-");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" / ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tfoot__next");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [9]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "pagination.links.previous", ["loc", [null, [55, 22], [55, 47]]]]], [], 0, null, ["loc", [null, [55, 16], [57, 23]]]], ["content", "paginationStart", ["loc", [null, [59, 12], [59, 31]]]], ["content", "paginationEnd", ["loc", [null, [59, 32], [59, 49]]]], ["content", "pagination.total", ["loc", [null, [59, 52], [59, 72]]]], ["block", "if", [["get", "pagination.links.next", ["loc", [null, [61, 22], [61, 43]]]]], [], 1, null, ["loc", [null, [61, 16], [63, 23]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 0
            },
            "end": {
              "line": 71,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-resources-list/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-video", [], ["video", ["subexpr", "@mut", [["get", "videoUrl", ["loc", [null, [70, 24], [70, 32]]]]], [], []], "close", ["subexpr", "action", ["setVideoUrl", null], [], ["loc", [null, [70, 39], [70, 66]]]]], ["loc", [null, [70, 4], [70, 68]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 71,
            "column": 7
          }
        },
        "moduleName": "vimme/components/vimme-resources-list/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "filter-bar");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "filter-bar-label");
        var el3 = dom.createTextNode("Filter By:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--weeks");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "table");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "thead");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "tr");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tbody");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tfoot");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(fragment, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element8, 3, 3);
        morphs[1] = dom.createMorphAt(dom.childAt(element8, [5]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element9, [1, 1]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element9, [5]), 1, 1);
        morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "hideGrades", ["loc", [null, [3, 14], [3, 24]]]]], [], 0, null, ["loc", [null, [3, 4], [14, 15]]]], ["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "weeks", ["loc", [null, [17, 20], [17, 25]]]]], [], []], "optionValuePath", "content.longName", "optionLabelPath", "content.longName", "selection", ["subexpr", "@mut", [["get", "localSelectedWeeks", ["loc", [null, [20, 22], [20, 40]]]]], [], []], "placeholder", "Select Week", "select-item", "weekChanged"], ["loc", [null, [16, 8], [23, 10]]]], ["block", "if", [["get", "viewOnly", ["loc", [null, [29, 18], [29, 26]]]]], [], 1, 2, ["loc", [null, [29, 12], [40, 19]]]], ["block", "each", [["get", "resources", ["loc", [null, [44, 16], [44, 25]]]]], [], 3, 4, ["loc", [null, [44, 8], [50, 17]]]], ["block", "if", [["get", "pagination", ["loc", [null, [53, 14], [53, 24]]]]], [], 5, null, ["loc", [null, [53, 8], [65, 15]]]], ["block", "if", [["get", "videoUrl", ["loc", [null, [69, 6], [69, 14]]]]], [], 6, null, ["loc", [null, [69, 0], [71, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});