define('vimme/controllers/admin/teachers/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        lastName: '',
        firstName: '',
        email: '',
        password: '',

        gradeID: '',

        schools: null,
        selectedSchool: null,

        username: _ember['default'].computed.oneWay('email'),

        actions: {

            submit: function submit() {

                var grade = this.store.find('grade', this.get('gradeID')),
                    _this = this;

                if (grade) {

                    grade.then(function (grade) {

                        var teacher = _this.store.createRecord('teacher', {

                            lastName: _this.get('lastName'),
                            firstName: _this.get('firstName'),
                            grade: grade,
                            school: _this.get('selectedSchool'),
                            username: _this.get('username'),
                            email: _this.get('email'),
                            password: _this.get('password')

                        });

                        teacher.save().then(function () {

                            _this.set('lastName', '');
                            _this.set('firstName', '');
                            _this.set('gradeID', '');
                            _this.set('selectedSchool', null);
                            _this.set('email', '');
                            _this.set('password', '');
                        })['catch'](function () {

                            teacher.deleteRecord();
                        });
                    });
                }
            }

        }

    });
});