define('vimme/controllers/admin/teachers', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({

        sortedTeachers: (function () {

            var _this = this;

            return _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                content: _this.get('content').toArray(),
                sortProperties: ['lastName'],
                sortAscending: true
            });
        }).property('content.@each')

    });
});