define('vimme/models/resource', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        description: _emberData['default'].attr('string'),
        title: _emberData['default'].attr('string'),
        url: _emberData['default'].attr('string'),

        grade: _emberData['default'].belongsTo('grade', { async: true }),
        week: _emberData['default'].belongsTo('week', { async: true }),

        createdAt: _emberData['default'].attr('isodate'),
        updatedAt: _emberData['default'].attr('isodate'),

        type: (function () {
            var url = this.get('url') || '';
            if (url.indexOf('vimeo') >= 0) {
                return 'video';
            }
            if (url.indexOf('s3.amazonaws') >= 0) {
                return 'upload';
            }
            return 'link';
        }).property('url'),

        isVideo: (function () {
            return this.get('type') === 'video';
        }).property('type'),

        isUpload: (function () {
            return this.get('type') === 'upload';
        }).property('type')

    });
});