define('vimme/components/vimme-test-question/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    isUnanswered: computed('studentQuestion.{answers,textAnswer}', 'question.isTextQuestion', function () {
      var isTextQuestion = this.get('question.isTextQuestion');
      var textAnswer = this.get('studentQuestion.textAnswer');
      var answers = this.get('studentQuestion.answers');
      return !isTextQuestion && !answers || typeof textAnswer === 'undefined';
    }),

    missingAnswers: computed('studentQuestion.answers', 'question.{answers,isMultipleQuestion}', function () {
      if (!this.get('question.isMultipleQuestion')) {
        return [];
      }
      var correctStudentAnswerIds = this.get('studentQuestion.answers').filterBy('isCorrect').mapBy('id');
      var correctAnswers = this.get('question.answers').filterBy('isCorrect');

      return correctAnswers.filter(function (answer) {
        return !correctStudentAnswerIds.contains(answer.get('id'));
      });
    })

  });
});