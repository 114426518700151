define('vimme/router', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _vimmeConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('password.reset', { path: '/password/reset/:token' });
    this.route('contact');
    this.route('terms-privacy');
    this.route('coppa');

    this.resource('admin', function () {
      this.resource('admin.management', { path: '/management' }, function () {
        this.route('school-admin', { path: '/:school_admin_id' });
      });
      this.resource('admin.test-data', { path: '/test-data' }, function () {
        this.route('test', { path: '/:test_id' });
      });
      this.resource('admin.reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
        this.route('yearly', { path: '/:school_year_id' });
      });
      this.resource('admin.builder', { path: '/builder' }, function () {
        this.route('quiz-questions', { path: '/quiz-questions' }, function () {
          this.route('new', { path: '/new' });
          this.route('quiz-question', { path: '/:quiz_question_id' });
        });
        this.route('tests', { path: '/tests' }, function () {
          this.route('test', { path: '/:test_id' }, function () {
            this.route('questions', { path: '/questions' }, function () {
              this.route('new', { path: '/new' });
              this.route('question', { path: '/:question_id' });
            });
          });
        });
        this.route('resources', { path: '/resources' }, function () {
          this.route('new', { path: '/new' });
          this.route('resource', { path: '/:resource_id' });
        });
      });
    });
    this.route('admin.tests', { path: '/admin/tests' });
    this.resource('admin.test', { path: '/admin/tests/:test_id' }, function () {
      this.route('print');
      this.resource('admin.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });
    this.resource('admin.teachers', { path: '/admin/teachers' }, function () {
      this.route('teacher', { path: '/:teacher_id' });
    });

    this.resource('school-admin', function () {
      this.resource('school-admin.management', { path: '/management' }, function () {
        this.route('sub-admins', { path: '/sub-admins' }, function () {
          this.route('sub-admin', { path: '/:sub_admin_id' });
        });
        this.route('teachers', { path: '/teachers' }, function () {
          this.route('teacher', { path: '/:teacher_id' });
        });
        this.route('students', { path: '/students' }, function () {
          this.route('student', { path: '/:student_id' });
        });
      });
      this.route('reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
        this.route('yearly', { path: '/:school_year_id' });
      });
      this.route('schedule-tests', { path: '/schedule-tests' }, function () {
        this.route('day', { path: '/:day' });
      });
      this.route('resources');
    });
    this.route('school-admin.tests', { path: '/school-admin/tests' });
    this.resource('school-admin.test', { path: '/school-admin/tests/:test_id' }, function () {
      this.route('print');
      this.resource('school-admin.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });

    this.resource('sub-admin', function () {
      this.route('reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
        this.route('yearly', { path: '/:school_year_id' });
      });
      this.resource('sub-admin.quiz-builder', { path: '/quiz-builder' }, function () {
        this.route('quiz', { path: '/:quiz_id' });
      });
      this.route('resources');
    });
    this.route('sub-admin.tests', { path: '/sub-admin/tests' });
    this.resource('sub-admin.test', { path: '/sub-admin/tests/:test_id' }, function () {
      this.resource('sub-admin.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });
    this.route('sub-admin.quiz', { path: '/sub-admin/quizzes/:quiz_id/:quiz_question_ids' });

    this.resource('teacher', function () {
      this.resource('teacher.testing-calendar', { path: '/testing-calendar' }, function () {
        this.route('test', { path: '/:test_id' });
      });
      this.resource('teacher.management', { path: '/management' }, function () {
        this.route('student', { path: '/:student_id' });
      });
      this.resource('teacher.reports', { path: '/reports' }, function () {
        this.route('week', { path: '/week' });
        this.route('question', { path: '/question' });
      });
      this.resource('teacher.quiz-builder', { path: '/quiz-builder' }, function () {
        this.route('quiz', { path: '/:quiz_id' });
      });
      this.route('resources');
    });
    this.route('teacher.quiz', { path: '/teacher/quizzes/:quiz_id/:quiz_question_ids' });
    this.route('teacher.tests', { path: '/teacher/tests' });
    this.resource('teacher.test', { path: '/teacher/tests/:test_id' }, function () {
      this.resource('teacher.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });
    this.resource('student', function () {
      this.route('testing-calendar');
      this.route('progress-reports');
    });
    this.route('student.tests', { path: '/student/tests' });
    this.resource('student.test', { path: '/student/tests/:test_id' }, function () {
      this.resource('student.questions', { path: '/questions' }, function () {
        this.route('question', { path: '/:question_id' });
      });
    });
  });

  exports['default'] = Router;
});