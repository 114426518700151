define('vimme/routes/teacher/test', ['exports', 'vimme/routes/teacher'], function (exports, _vimmeRoutesTeacher) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model(params) {
            return this.store.find('test', params.test_id);
        },
        afterModel: function afterModel(test, transition) {
            var dateAvailable = moment(test.get('dateAvailable')),
                today = moment().startOf('day');

            if (dateAvailable.diff(today, 'day') > 0) {
                this.replaceWith('teacher.testing-calendar');
            } else if (transition.targetName === 'teacher.test.index') {
                this.replaceWith('teacher.questions');
            }
        }
    });
});