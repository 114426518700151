define('vimme/routes/admin/reports/yearly', ['exports', 'vimme/routes/admin', 'ember'], function (exports, _vimmeRoutesAdmin, _ember) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model(params) {
            var schoolYears = this.modelFor('admin.reports').schoolYears;
            var schoolYear = schoolYears.findBy('id', params.school_year_id);
            return _ember['default'].RSVP.hash({
                weeks: this.modelFor('admin.reports').weeks,
                grades: this.modelFor('admin.reports').grades,
                schoolYears: schoolYears,
                schoolYear: schoolYear,
                schoolYearGradeReports: schoolYear.get('schoolYearGradeReports')
            });
        },
        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            controller.set('selectedSchoolYear', model.schoolYear);
            controller.set('averages', model.schoolYearGradeReports);
            controller.set('currentSortDirection', 'desc');
            controller.set('currentSortColumn', 'grade-column');
        }
    });
});