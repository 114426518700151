define('vimme/components/vimme-quiz-question/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        attributeBindings: ['style'],
        classNames: ['vimme-quiz-question'],

        quizQuestion: null,
        index: '#',
        showImage: false,
        image: null,

        image64: null,

        alignTop: null,
        alignLeft: null,

        indexPlusOne: (function () {
            var index = this.get('index');
            return index === '#' ? index : index + 1;
        }).property('index'),

        style: (function () {

            var alignTop = this.get('alignTop');
            var alignLeft = this.get('alignLeft');
            var styles = '';

            if (alignTop !== null) {
                styles += 'top: ' + alignTop + 'px; ';
            }
            if (alignLeft !== null) {
                styles += 'left: ' + alignLeft + 'px;';
            }

            return styles;
        }).property('alignTop', 'alignLeft'),

        setImage64: (function () {
            var _this = this;

            var image = this.get('image');
            if (image) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    _this.set('image64', e.target.result);
                };
                reader.readAsDataURL(image);
            } else {
                this.set('image64', null);
            }
        }).observes('image'),

        imageStyle: (function () {
            var image64 = this.get('image64');
            var imagePath = this.get('quizQuestion.imagePath');
            var imageStyle = '';
            if (image64) {
                imageStyle = 'background-image: url(' + image64 + ');';
            } else if (imagePath) {
                imageStyle = 'background-image: url(' + imagePath + ');';
            }
            return imageStyle;
        }).property('image64', 'quizQuestion.imagePath'),

        formattedTitle: (function () {
            var title = this.get('quizQuestion.title');
            if (title) {
                title = title.replace(/ +/g, function (match) {
                    return match.split('').reduce(function (prev) {
                        if (!prev) {
                            return ' ';
                        } else if (match.length === prev.length) {
                            return prev + ' ';
                        }
                        return prev + '&nbsp';
                    }, '');
                });
                title = title.replace(/\n/g, '<br>');
            }
            return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + (title ? title : '');
        }).property('quizQuestion.title')

    });
});