define("vimme/models/school-admin", ["exports", "ember-data", "vimme/models/user"], function (exports, _emberData, _vimmeModelsUser) {
    exports["default"] = _vimmeModelsUser["default"].extend({
        firstName: _emberData["default"].attr('string'),
        lastName: _emberData["default"].attr('string'),
        eula: _emberData["default"].attr('boolean'),

        school: _emberData["default"].belongsTo('school', { async: true }),

        fullName: (function () {

            return this.get('firstName') + ' ' + this.get('lastName');
        }).property('firstName', 'lastName'),

        lastThenFirst: (function () {

            return this.get('lastName') + ', ' + this.get('firstName');
        }).property('firstName', 'lastName')

    });
});