define('vimme/routes/admin/builder/tests/test/index', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model() {
            var test = this.modelFor('admin.builder.tests.test').test;
            var grades = this.modelFor('admin.builder.tests.test').grades;
            var weeks = this.modelFor('admin.builder.tests.test').weeks;
            return _ember['default'].RSVP.hash({
                test: test,
                grades: grades,
                weeks: weeks,
                user: this.modelFor('admin').user
            });
        }
    });
});