define('vimme/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        title: _emberData['default'].attr('string'),
        questionType: _emberData['default'].attr('string'),
        image: _emberData['default'].attr('string'),
        imagePath: _emberData['default'].attr('string'),
        requiresTools: _emberData['default'].attr('boolean', { defaultValue: false }),
        order: _emberData['default'].attr('number'),

        test: _emberData['default'].belongsTo('test', { async: true }),
        answers: _emberData['default'].hasMany('answer', { async: true }),
        studentQuestions: _emberData['default'].hasMany('studentQuestion', { async: true }),

        createdAt: _emberData['default'].attr('isodate'),
        updatedAt: _emberData['default'].attr('isodate'),

        hasImage: (function () {
            return !!this.get('image') || !!this.get('imagePath');
        }).property('image', 'imagePath'),

        base64Image: (function () {
            if (this.get('image')) {
                return 'data:image/png;base64,' + this.get('image');
            }
        }).property('image'),

        src: (function () {
            return this.get('base64Image') || this.get('imagePath');
        }).property('imagePath', 'base64Image'),

        isVertical: (function () {

            var title = this.get('title');

            return title.indexOf('vertical-problem') >= 0 || title.indexOf('long-division-problem') >= 0;
        }).property('title'),

        isTextQuestion: (function () {
            return this.get('questionType') === 'text';
        }).property('questionType'),

        isMultipleQuestion: (function () {
            return this.get('questionType') === 'multiple';
        }).property('questionType')

    });
});