define('vimme/components/vimme-testing-calendar-teacher-test', ['exports', 'ember', 'vimme/utils/compare-dates'], function (exports, _ember, _vimmeUtilsCompareDates) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: 'li',

        classNames: ['testing-calendar-test'],
        classNameBindings: ['isSelected'],

        selectedDate: null,
        test: null,

        isSelected: (function () {

            var selected = this.get('selectedDate'),
                dateAvailable = this.get('test.dateAvailable');

            if (selected) {

                return (0, _vimmeUtilsCompareDates['default'])(moment(selected), moment(dateAvailable));
            }

            return false;
        }).property('selectedDate', 'test.dateAvailable'),

        teacherTest: (function () {

            var teacherTests = this.get('test.teacherTests');

            if (teacherTests && teacherTests.get('length') > 0) {

                return teacherTests.get('firstObject');
            }
        }).property('test.teacherTests.@each'),

        hasViewableResults: (function () {

            var teacherTest = this.get('teacherTest');

            if (teacherTest) {

                // return (teacherTest.get('isComplete') && !teacherTest.get('isActive'));
                return false;
            }

            return false;
        }).property('teacherTest.{isComplete,isActive}'),

        scrollTo: (function () {

            var isSelected = this.get('isSelected');

            if (isSelected) {

                _ember['default'].run.later(this, function () {

                    var el = _ember['default'].$('.testing-calendar-test.is-selected'),
                        parent = el.parent(),
                        offset = el.position().top;

                    if (offset < 0 || offset > parent.height()) {

                        offset = parent.scrollTop() + offset;

                        parent.animate({ scrollTop: offset - 8 }, 300);
                    }
                });
            }
        }).observes('isSelected')

    });
});