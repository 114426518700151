define('vimme/components/vimme-quiz-question-editor/component', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-quiz-question-editor'],
        classNameBindings: ['typeClass'],

        // inputs
        categories: [],
        difficulties: [],
        grades: [],
        quizQuestion: null,
        isSaving: false,

        cancelText: 'Cancel',

        image: null,

        quizQuestionTypes: [{
            id: 'text',
            value: 'All Text'
        }, {
            id: 'textimage',
            value: 'Text and Image'
        }],

        selectedQuizQuestionType: null,

        setSelectedQuizQuestionType: (function () {
            if (this.get('quizQuestion.imagePath')) {
                this.set('selectedQuizQuestionType', this.get('quizQuestionTypes.lastObject'));
            } else {
                this.set('selectedQuizQuestionType', this.get('quizQuestionTypes.firstObject'));
            }
        }).on('init'),

        typeClass: (function () {
            return 'vimme-quiz-question-editor--' + this.get('selectedQuizQuestionType.id');
        }).property('selectedQuizQuestionType.id'),

        showImage: _ember['default'].computed.equal('selectedQuizQuestionType.id', 'textimage'),

        imagePath: (function () {

            var image = this.get('image');
            var imagePath = this.get('quizQuestion.imagePath');

            if (image) {
                imagePath = image.name;
            }

            if (imagePath) {
                var splitForward = imagePath.split('/');
                var splitBack = imagePath.split('\\');
                if (splitForward.length > 0) {
                    imagePath = splitForward[splitForward.length - 1];
                } else if (splitBack.length > 0) {
                    imagePath = splitBack[splitBack.length - 1];
                }
                imagePath = decodeURI(imagePath);
            }

            return imagePath;
        }).property('quizQuestion.imagePath', 'image'),

        isUnsavable: (function () {
            var isUnsavable = this.get('isSaving') || !this.get('quizQuestion.title') || !this.get('quizQuestion.grade') || !this.get('quizQuestion.difficulty') || !this.get('quizQuestion.categories.length');
            var image = this.get('showImage') && !this.get('imagePath');
            return isUnsavable || image;
        }).property('isSaving', 'showImage', 'imagePath', 'quizQuestion.{title,grade,difficulty,categories.length}'),
        isSavable: _ember['default'].computed.not('isUnsavable'),

        actions: {
            save: function save() {
                var _this = this;

                var image = this.get('image');
                var showImage = this.get('showImage');
                var quizQuestion = this.get('quizQuestion');

                this.set('isSaving', true);

                if (showImage && image) {

                    _ember['default'].$.ajax({
                        type: 'POST',
                        url: _vimmeConfigEnvironment['default'].API.host + '/quiz-questions-upload-url',
                        contentType: 'application/json',
                        dataType: 'json',
                        data: JSON.stringify({
                            type: image.type,
                            name: image.name
                        })
                    }).then(function (data) {

                        _ember['default'].$.ajax({
                            type: 'PUT',
                            url: data.upload_url,
                            cache: false,
                            processData: false,
                            contentType: false,
                            headers: {
                                'Cache-Control': 'max-age=86400'
                            },
                            data: image
                        }).then(function () {
                            quizQuestion.set('imagePath', 'https://s3.amazonaws.com/' + _vimmeConfigEnvironment['default'].S3.bucket + '/quiz-questions/' + encodeURI(data.filename));
                            _this.sendAction('save', quizQuestion);
                        }, function () {
                            // upload error
                            _this.set('isSaving', false);
                        });
                    }, function () {
                        // upload url generation error
                        _this.set('isSaving', false);
                    });
                } else if (showImage) {
                    this.sendAction('save', quizQuestion);
                } else {
                    quizQuestion.set('imagePath', null);
                    this.sendAction('save', quizQuestion);
                }
            },
            cancel: function cancel() {
                this.set('image', null);
                this.sendAction('cancel');
            },
            createCategory: function createCategory(title) {
                var _this2 = this;

                var store = this.get('targetObject.store');
                var categories = this.get('categories');

                var category = categories.find(function (category) {
                    return category.get('lowercaseTitle') === title.toLowerCase();
                });

                if (category) {
                    category.set('title', title);
                    this.get('categories').removeObject(category);
                    _ember['default'].run.later(function () {
                        _this2.get('categories').addObject(category);
                    });
                } else {
                    category = store.createRecord('category', {
                        title: title
                    });
                }

                _ember['default'].run.later(function () {
                    _this2.get('quizQuestion.categories').addObject(category);
                });
            },
            deleteCategory: function deleteCategory(category) {
                if (!category.get('id')) {
                    category.deleteRecord();
                }
            }
        }

    });
});