define('vimme/models/quiz', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        title: _emberData['default'].attr('string'),

        quizQuestions: _emberData['default'].hasMany('quiz-question', { async: true }),
        subAdmin: _emberData['default'].belongsTo('sub-admin', { async: true }),
        teacher: _emberData['default'].belongsTo('teacher', { async: true }),

        createdAt: _emberData['default'].attr('isodate'),
        updatedAt: _emberData['default'].attr('isodate')
    });
});