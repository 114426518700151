define("vimme/components/vimme-test-question-editor/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 16
              },
              "end": {
                "line": 33,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-test-question-editor/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "answer-input", [], ["grade", ["subexpr", "@mut", [["get", "question.test.grade.id", ["loc", [null, [32, 41], [32, 63]]]]], [], []], "value", ["subexpr", "@mut", [["get", "answer.title", ["loc", [null, [32, 70], [32, 82]]]]], [], []], "class", "input"], ["loc", [null, [32, 20], [32, 98]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 16
              },
              "end": {
                "line": 35,
                "column": 16
              }
            },
            "moduleName": "vimme/components/vimme-test-question-editor/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "answer.title", ["loc", [null, [34, 46], [34, 58]]]]], [], []], "class", "input"], ["loc", [null, [34, 20], [34, 74]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 8
            },
            "end": {
              "line": 39,
              "column": 8
            }
          },
          "moduleName": "vimme/components/vimme-test-question-editor/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "answer-inner");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "btn-checkbox");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-icon-only btn-delete icon-delete");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[2] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "selectedQuestionType", ["loc", [null, [31, 26], [31, 46]]]], "text"], [], ["loc", [null, [31, 22], [31, 54]]]]], [], 0, 1, ["loc", [null, [31, 16], [35, 23]]]], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "answer.isCorrect", ["loc", [null, [36, 74], [36, 90]]]]], [], []]], ["loc", [null, [36, 42], [36, 92]]]], ["element", "action", ["deleteAnswer", ["get", "answer", ["loc", [null, [37, 97], [37, 103]]]]], [], ["loc", [null, [37, 73], [37, 105]]]]],
        locals: ["answer"],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-test-question-editor/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dialog question-dialog");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "dialog-inner");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "btn btn-icon-only btn-dialog-exit icon-delete");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-name");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-type");
          var el4 = dom.createTextNode("Preview Question");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-message test-question");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["hideQuestion"], [], ["loc", [null, [52, 11], [52, 36]]]], ["content", "question.test.titleWithGrade", ["loc", [null, [53, 33], [53, 65]]]], ["inline", "vimme-test-question", [], ["question", ["subexpr", "@mut", [["get", "question", ["loc", [null, [56, 43], [56, 51]]]]], [], []], "image", ["subexpr", "@mut", [["get", "image64", ["loc", [null, [56, 58], [56, 65]]]]], [], []]], ["loc", [null, [56, 12], [56, 67]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 7
          }
        },
        "moduleName": "vimme/components/vimme-test-question-editor/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "quiz-question-type");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quiz-question-type__label");
        var el3 = dom.createTextNode("Question Type:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--category");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--quiz-question-entry");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "for", "title");
        dom.setAttribute(el3, "class", "label");
        var el4 = dom.createTextNode("Enter Question");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--quiz-question-image");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "inner-input");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "for", "image");
        dom.setAttribute(el4, "class", "label");
        var el5 = dom.createTextNode("Image");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-icon-only icon-search");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn btn-icon-only icon-delete");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-container input-container--answers");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "label");
        var el4 = dom.createTextNode("Answers");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn btn-icon-only btn-save icon-add");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "buttons");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-orange btn-icon-only icon-eye");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-delete icon-delete");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-update icon-check");
        var el3 = dom.createTextNode("Save");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [2]);
        var element5 = dom.childAt(element4, [3]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element4, [5]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(fragment, [4]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var element13 = dom.childAt(element10, [5]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]), 3, 3);
        morphs[2] = dom.createMorphAt(element6, 3, 3);
        morphs[3] = dom.createMorphAt(element6, 7, 7);
        morphs[4] = dom.createElementMorph(element7);
        morphs[5] = dom.createElementMorph(element9);
        morphs[6] = dom.createMorphAt(element8, 5, 5);
        morphs[7] = dom.createElementMorph(element11);
        morphs[8] = dom.createAttrMorph(element12, 'disabled');
        morphs[9] = dom.createElementMorph(element12);
        morphs[10] = dom.createMorphAt(element12, 0, 0);
        morphs[11] = dom.createAttrMorph(element13, 'disabled');
        morphs[12] = dom.createElementMorph(element13);
        morphs[13] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "testQuestionTypes", ["loc", [null, [5, 20], [5, 37]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.value", "value", ["subexpr", "@mut", [["get", "selectedQuestionType", ["loc", [null, [8, 18], [8, 38]]]]], [], []]], ["loc", [null, [4, 8], [9, 10]]]], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "question.title", ["loc", [null, [15, 25], [15, 39]]]]], [], []], "id", "title", "class", "input"], ["loc", [null, [15, 8], [15, 66]]]], ["inline", "input", [], ["type", "text", "disabled", true, "value", ["subexpr", "@mut", [["get", "imagePath", ["loc", [null, [20, 52], [20, 61]]]]], [], []], "id", "image", "class", "input"], ["loc", [null, [20, 12], [20, 88]]]], ["inline", "vimme-file-input", [], ["file", ["subexpr", "@mut", [["get", "image", ["loc", [null, [22, 36], [22, 41]]]]], [], []], "accept", "image/*"], ["loc", [null, [22, 12], [22, 60]]]], ["element", "action", ["clear"], [], ["loc", [null, [24, 54], [24, 72]]]], ["element", "action", ["newAnswer"], [], ["loc", [null, [28, 60], [28, 82]]]], ["block", "each", [["get", "question.answers", ["loc", [null, [29, 16], [29, 32]]]]], [], 0, null, ["loc", [null, [29, 8], [39, 17]]]], ["element", "action", ["openQuestion"], [], ["loc", [null, [43, 58], [43, 83]]]], ["attribute", "disabled", ["get", "isSaving", ["loc", [null, [44, 82], [44, 90]]]]], ["element", "action", ["cancelEdit"], [], ["loc", [null, [44, 47], [44, 70]]]], ["content", "cancelText", ["loc", [null, [44, 93], [44, 107]]]], ["attribute", "disabled", ["get", "isUnsavable", ["loc", [null, [45, 75], [45, 86]]]]], ["element", "action", ["save"], [], ["loc", [null, [45, 46], [45, 63]]]], ["block", "if", [["get", "showQuestion", ["loc", [null, [48, 6], [48, 18]]]]], [], 1, null, ["loc", [null, [48, 0], [61, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});