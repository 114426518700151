define('vimme/components/date-formatter/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: '',

        date: moment(),

        format: 'l',

        result: (function () {

            if (this.get('date')) {
                return moment(this.get('date')).format(this.get('format'));
            }

            return '';
        }).property('date', 'format')

    });
});