define('vimme/controllers/teacher/quiz-builder/quiz', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        quizBuilder: _ember['default'].inject.controller('teacher.quiz-builder'),

        isSaving: false,

        addQuestions: _ember['default'].computed.alias('quizBuilder.addQuestions'),

        selectedCategories: '',
        selectedDifficulties: '',
        selectedGrades: '',
        page: 1,
        sort: 'grade',

        visibleCategoriesCache: [],
        visibleCategories: (function (key, value) {
            var _this = this;

            if (arguments.length > 1) {
                this.set('visibleCategoriesCache', value);
                return value;
            }
            this.store.find('category', {
                grades: this.get('selectedGrades')
            }).then(function (categories) {
                _this.set('visibleCategories', categories);
            });
            return this.get('visibleCategoriesCache');
        }).property('selectedGrades'),

        quizQuestionsCache: [],
        quizQuestions: (function (key, value) {
            var _this2 = this;

            if (arguments.length > 1) {
                this.set('quizQuestionsCache', value);
                return value;
            }
            this.store.find('quiz-question', {
                categories: this.get('selectedCategories'),
                difficulties: this.get('selectedDifficulties'),
                grades: this.get('selectedGrades'),
                page: this.get('page'),
                sort: this.get('sort')
            }).then(function (quizQuestions) {
                _this2.set('quizQuestions', quizQuestions);
            });
            return this.get('quizQuestionsCache');
        }).property('selectedCategories', 'selectedDifficulties', 'selectedGrades', 'page', 'sort'),

        actions: {
            'delete': function _delete() {
                var _this3 = this;

                var quiz = this.get('model.quiz');

                this.set('isSaving', true);

                quiz.destroyRecord().then(function () {
                    _this3.set('isSaving', false);
                    _this3.transitionToRoute('teacher.quiz-builder');
                })['catch'](function () {
                    quiz.rollback();
                    _this3.set('isSaving', false);
                });
            },
            save: function save(quiz) {
                var _this4 = this;

                this.set('isSaving', true);

                quiz.save().then(function () {
                    _this4.set('isSaving', false);
                })['catch'](function () {
                    quiz.rollback();
                    _this4.set('isSaving', false);
                });
            },
            hideAdd: function hideAdd() {
                this.set('addQuestions', false);
            },
            showAdd: function showAdd() {
                this.set('addQuestions', true);
            }
        }

    });
});