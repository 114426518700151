define('vimme/routes/school-admin/schedule-tests/day', ['exports', 'vimme/routes/school-admin', 'vimme/utils/date-in-range'], function (exports, _vimmeRoutesSchoolAdmin, _vimmeUtilsDateInRange) {
    exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
        model: function model(params) {
            var teacherTests = this.modelFor('school-admin.schedule-tests').teacherTests,
                length = teacherTests.get('length'),
                date = moment(params.day, 'MM/DD/YYYY').toDate(),
                model = null;

            if (length && moment(date).isValid()) {

                model = teacherTests.filter(function (teacherTest) {
                    return (0, _vimmeUtilsDateInRange['default'])(date, teacherTest.get('startDate'), teacherTest.get('endDate'));
                });

                if (model && model.get('length')) {
                    return {
                        teacherTests: model,
                        date: date
                    };
                }
            }

            this.replaceWith('school-admin.schedule-tests');
        },
        setupController: function setupController(controller, model) {

            this._super(controller, model);
            controller.set('selected', []);
        }
    });
});