define('vimme/routes/admin/test', ['exports', 'vimme/routes/admin'], function (exports, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        beforeModel: function beforeModel(transition) {

            this._super(transition);

            if (transition.targetName === 'admin.test.index') {
                this.replaceWith('admin.questions');
            }
        },
        model: function model(params) {
            return this.store.find('test', params.test_id);
        }
    });
});