define('vimme/routes/admin/builder/tests/index', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model() {
            var test = this.store.createRecord('test', {
                isDraft: true
            });
            var localGrades = this.store.all('grade');
            var localWeeks = this.store.all('week');
            return _ember['default'].RSVP.hash({
                test: test,
                grades: localGrades.get('length') > 0 ? localGrades : this.store.find('grade'),
                weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.find('week'),
                user: this.modelFor('admin').user
            });
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                this.store.all('test').filterBy('id', null).invoke('deleteRecord');
            }
        }
    });
});