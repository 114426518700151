define('vimme/controllers/student/testing-calendar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        selectedDate: null,
        selectedItems: null,

        sortedTeacherTests: (function () {

            var teacherTests = this.get('model.teacherTests');

            return _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                content: teacherTests.toArray(),
                sortProperties: ['startDate'],
                sortAscending: true
            });
        }).property('model.teacherTests.@each.startDate'),

        actions: {

            select: function select(day) {
                this.set('selectedDate', day);
            },

            selectToday: function selectToday() {
                this.set('selectedDate', moment().startOf('day').toDate());
            }

        }

    });
});