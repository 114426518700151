define("vimme/components/vimme-video/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 67
            }
          },
          "moduleName": "vimme/components/vimme-video/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("X");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "vimme/components/vimme-video/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("iframe");
        dom.setAttribute(el1, "width", "960");
        dom.setAttribute(el1, "height", "540");
        dom.setAttribute(el1, "frameborder", "0");
        dom.setAttribute(el1, "webkitallowfullscreen", "");
        dom.setAttribute(el1, "mozallowfullscreen", "");
        dom.setAttribute(el1, "allowfullscreen", "");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element0, 'src');
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "vimme-button", [], ["class", "vimme-button--close-video", "action", "close"], 0, null, ["loc", [null, [1, 0], [1, 84]]]], ["attribute", "src", ["get", "url", ["loc", [null, [2, 14], [2, 17]]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});