define('vimme/controllers/teacher/management', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].ArrayController.extend({

        needs: ['application', 'teacher/management/index'],

        queryParams: ['print'],
        print: false,

        sortAlphabetically: ['lastThenFirst:asc'],
        sortedStudents: _ember['default'].computed.sort('model', 'sortAlphabetically'),

        actions: {

            newStudent: function newStudent() {

                var teacher = this.store.find('teacher', this.get('session.content.id')),
                    _this = this;

                if (this.get('controllers.application.currentRouteName') !== 'teacher.management.index') {

                    this.transitionToRoute('teacher.management');
                }

                teacher.then(function (teacher) {

                    var index = _this.get('controllers.teacher/management/index');

                    index.set('lastName', '');
                    index.set('middleInitial', '');
                    index.set('firstName', '');
                    index.set('grade', teacher.get('grade'));
                    index.set('gradeNum', teacher.get('grade.id'));
                    index.set('teacher', null);
                    index.set('username', '');
                    index.set('password', '');
                });
            }

        },

        filteredStudents: (function () {

            var escape = function escape(term) {
                return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
            };

            var students = this.get('sortedStudents'),
                term = this.get('searchTerm'),
                filter = new RegExp(escape(term), 'i');

            if (term) {
                students = students.filter(function (student) {
                    return filter.test(student.get('fullName'));
                });
            }

            return students;
        }).property('searchTerm', 'model.[]')

    });
});