define('vimme/routes/school-admin/reports/question', ['exports', 'vimme/routes/school-admin'], function (exports, _vimmeRoutesSchoolAdmin) {
    exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
        model: function model() {
            return this.modelFor('school-admin.reports');
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            controller.set('selectedSchoolYear', null);
        }
    });
});