define('vimme/components/vimme-report-question/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        classNames: ['cell', 'vimme-report-question'],
        classNameBindings: ['paddedStudentQuestion.studentQuestion.correctIcon'],
        tagName: 'a',

        paddedStudentQuestion: null,

        click: function click() {
            if (this.get('paddedStudentQuestion.studentQuestion')) {
                this.sendAction('clickMe', this.get('paddedStudentQuestion'));
            }
        },
        mouseEnter: function mouseEnter() {
            if (this.get('paddedStudentQuestion.studentQuestion')) {
                this.sendAction('hoverMe', this.get('paddedStudentQuestion'), this.get('element'));
            }
        },
        mouseLeave: function mouseLeave() {
            this.sendAction('hoverMe', null);
        }
    });
});