define("vimme/templates/teacher/reports/week", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 16
              },
              "end": {
                "line": 5,
                "column": 126
              }
            },
            "moduleName": "vimme/templates/teacher/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Week View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 16
              },
              "end": {
                "line": 6,
                "column": 134
              }
            },
            "moduleName": "vimme/templates/teacher/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Question View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 8,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "view-choices");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element22, 1, 1);
          morphs[1] = dom.createMorphAt(element22, 3, 3);
          return morphs;
        },
        statements: [["block", "link-to", ["teacher.reports.week"], ["class", "view-choice"], 0, null, ["loc", [null, [5, 16], [5, 138]]]], ["block", "link-to", ["teacher.reports.question"], ["class", "view-choice"], 1, null, ["loc", [null, [6, 16], [6, 146]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 8
            },
            "end": {
              "line": 12,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Print View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element21);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [11, 34], [11, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 8
            },
            "end": {
              "line": 14,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Report View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element20);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [13, 34], [13, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 24
                  },
                  "end": {
                    "line": 33,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 3,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row reports-average-row");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "cell");
                var el3 = dom.createTextNode("Average");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var element11 = dom.childAt(element10, [3]);
                var element12 = dom.childAt(element10, [5]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element11, 'class');
                morphs[1] = dom.createMorphAt(element11, 0, 0);
                morphs[2] = dom.createAttrMorph(element12, 'class');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [30, 51], [30, 56]]]]]]], ["content", "formattedPercent", ["loc", [null, [30, 60], [30, 80]]]], ["attribute", "class", ["concat", ["cell ", ["get", "trend", ["loc", [null, [31, 51], [31, 56]]]]]]]],
              locals: ["formattedPercent", "color", "trend"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 20
                },
                "end": {
                  "line": 34,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [27, 53], [27, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [27, 73], [27, 80]]]]], [], []]], 0, null, ["loc", [null, [27, 24], [33, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 24
                  },
                  "end": {
                    "line": 42,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 3,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "cell");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1]);
                var element8 = dom.childAt(element7, [3]);
                var element9 = dom.childAt(element7, [5]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
                morphs[1] = dom.createAttrMorph(element8, 'class');
                morphs[2] = dom.createMorphAt(element8, 0, 0);
                morphs[3] = dom.createAttrMorph(element9, 'class');
                return morphs;
              },
              statements: [["content", "average.student.lastThenFirst", ["loc", [null, [38, 50], [38, 83]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [39, 51], [39, 56]]]]]]], ["content", "formattedPercent", ["loc", [null, [39, 60], [39, 80]]]], ["attribute", "class", ["concat", ["cell ", ["get", "trend", ["loc", [null, [40, 51], [40, 56]]]]]]]],
              locals: ["formattedPercent", "color", "trend"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 20
                },
                "end": {
                  "line": 43,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [36, 53], [36, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [36, 73], [36, 80]]]]], [], []]], 0, null, ["loc", [null, [36, 24], [42, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 24
                },
                "end": {
                  "line": 51,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element6, 'class');
              morphs[1] = dom.createElementMorph(element6);
              morphs[2] = dom.createMorphAt(element6, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header ", ["get", "week.shortName", ["loc", [null, [50, 54], [50, 68]]]]]]], ["element", "action", ["sortAveragesWeek", ["get", "week", ["loc", [null, [50, 100], [50, 104]]]]], [], ["loc", [null, [50, 72], [50, 106]]]], ["content", "week.shortName", ["loc", [null, [50, 107], [50, 125]]]]],
            locals: ["week"],
            templates: []
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 32
                    },
                    "end": {
                      "line": 58,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/teacher/reports/week.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element5, 'class');
                  morphs[1] = dom.createMorphAt(element5, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.average.color", ["loc", [null, [57, 55], [57, 88]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [57, 92], [57, 140]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 54,
                    "column": 24
                  },
                  "end": {
                    "line": 60,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 4,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row reports-average-row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [56, 40], [56, 60]]]]], [], 0, null, ["loc", [null, [56, 32], [58, 41]]]]],
              locals: ["formattedPercent", "color", "trend", "paddedWeeklyAverages"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 20
                },
                "end": {
                  "line": 61,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [54, 53], [54, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [54, 73], [54, 80]]]]], [], []]], 0, null, ["loc", [null, [54, 24], [60, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child4 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 32
                    },
                    "end": {
                      "line": 67,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/teacher/reports/week.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element4, 'class');
                  morphs[1] = dom.createMorphAt(element4, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.average.color", ["loc", [null, [66, 55], [66, 88]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [66, 92], [66, 140]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 24
                  },
                  "end": {
                    "line": 69,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 4,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [65, 40], [65, 60]]]]], [], 0, null, ["loc", [null, [65, 32], [67, 41]]]]],
              locals: ["formattedPercent", "color", "trend", "paddedWeeklyAverages"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 20
                },
                "end": {
                  "line": 70,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [63, 53], [63, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [63, 73], [63, 80]]]]], [], []]], 0, null, ["loc", [null, [63, 24], [69, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 8
              },
              "end": {
                "line": 73,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header average-column");
            var el5 = dom.createTextNode("Average");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header trend-column");
            var el5 = dom.createTextNode("Trend");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1, 1]);
            var element14 = dom.childAt(element13, [1]);
            var element15 = dom.childAt(element14, [1]);
            var element16 = dom.childAt(element14, [3]);
            var element17 = dom.childAt(element14, [5]);
            var element18 = dom.childAt(fragment, [3, 1]);
            var element19 = dom.childAt(element18, [1]);
            var morphs = new Array(10);
            morphs[0] = dom.createAttrMorph(element14, 'class');
            morphs[1] = dom.createElementMorph(element15);
            morphs[2] = dom.createElementMorph(element16);
            morphs[3] = dom.createElementMorph(element17);
            morphs[4] = dom.createMorphAt(element13, 3, 3);
            morphs[5] = dom.createMorphAt(element13, 4, 4);
            morphs[6] = dom.createAttrMorph(element19, 'class');
            morphs[7] = dom.createMorphAt(element19, 1, 1);
            morphs[8] = dom.createMorphAt(element18, 3, 3);
            morphs[9] = dom.createMorphAt(element18, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [21, 45], [21, 62]]]]]]], ["element", "action", ["sortAveragesOther", "student-column"], [], ["loc", [null, [22, 64], [22, 111]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [23, 64], [23, 111]]]], ["element", "action", ["sortAveragesOther", "trend-column"], [], ["loc", [null, [24, 62], [24, 107]]]], ["block", "each", [["get", "teacherAverages", ["loc", [null, [26, 28], [26, 43]]]]], [], 0, null, ["loc", [null, [26, 20], [34, 29]]]], ["block", "each", [["get", "averages", ["loc", [null, [35, 28], [35, 36]]]]], [], 1, null, ["loc", [null, [35, 20], [43, 29]]]], ["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [48, 45], [48, 62]]]]]]], ["block", "each", [["get", "model.weeks", ["loc", [null, [49, 32], [49, 43]]]]], [], 2, null, ["loc", [null, [49, 24], [51, 33]]]], ["block", "each", [["get", "teacherAverages", ["loc", [null, [53, 28], [53, 43]]]]], [], 3, null, ["loc", [null, [53, 20], [61, 29]]]], ["block", "each", [["get", "averages", ["loc", [null, [62, 28], [62, 36]]]]], [], 4, null, ["loc", [null, [62, 20], [70, 29]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 74,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [18, 14], [18, 22]]]]], [], 0, null, ["loc", [null, [18, 8], [73, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 84,
                    "column": 24
                  },
                  "end": {
                    "line": 86,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "print-item");
                var el2 = dom.createTextNode("W");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(": ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element0, 1, 1);
                morphs[1] = dom.createMorphAt(element0, 3, 3);
                return morphs;
              },
              statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [85, 54], [85, 81]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [85, 83], [85, 131]]]]],
              locals: ["paddedWeeklyAverage"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 12
                },
                "end": {
                  "line": 89,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 4,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "print-row column");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-general");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-name");
              var el4 = dom.createTextNode("Student: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-avg");
              var el4 = dom.createTextNode("Average: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-trend");
              var el4 = dom.createTextNode("Trend: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-details");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var element3 = dom.childAt(element2, [5, 1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
              morphs[2] = dom.createAttrMorph(element3, 'class');
              morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
              return morphs;
            },
            statements: [["content", "average.student.lastThenFirst", ["loc", [null, [79, 69], [79, 102]]]], ["content", "formattedPercent", ["loc", [null, [80, 68], [80, 88]]]], ["attribute", "class", ["concat", [["get", "trend", ["loc", [null, [81, 80], [81, 85]]]]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [84, 32], [84, 52]]]]], [], 0, null, ["loc", [null, [84, 24], [86, 33]]]]],
            locals: ["formattedPercent", "color", "trend", "paddedWeeklyAverages"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 8
              },
              "end": {
                "line": 90,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/reports/week.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [76, 41], [76, 52]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [76, 61], [76, 68]]]]], [], []]], 0, null, ["loc", [null, [76, 12], [89, 36]]]]],
          locals: ["average"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 4
            },
            "end": {
              "line": 91,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "averages", ["loc", [null, [75, 16], [75, 24]]]]], [], 0, null, ["loc", [null, [75, 8], [90, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 93,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/teacher/reports/week.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element23 = dom.childAt(fragment, [0]);
        var element24 = dom.childAt(element23, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element24, 1, 1);
        morphs[1] = dom.createMorphAt(element24, 3, 3);
        morphs[2] = dom.createMorphAt(element23, 3, 3);
        return morphs;
      },
      statements: [["block", "unless", [["get", "print", ["loc", [null, [3, 18], [3, 23]]]]], [], 0, null, ["loc", [null, [3, 8], [8, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [10, 18], [10, 23]]]]], [], 1, 2, ["loc", [null, [10, 8], [14, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [17, 14], [17, 19]]]]], [], 3, 4, ["loc", [null, [17, 4], [91, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});