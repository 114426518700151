define('vimme/components/vimme-score-chart/component', ['exports', 'ember', 'vimme/utils/color'], function (exports, _ember, _vimmeUtilsColor) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-score-chart'],
        classNameBindings: ['type', 'color'],

        transforms: ['-webkit-transform', '-ms-transform', 'transform'],

        type: 'old',

        delayedPercent: 0,
        interval: null,

        schedule: function schedule(f) {
            var _this = this;

            if (this.get('delayedPercent') >= this.get('percent')) {
                _ember['default'].run.cancel(this.get('interval'));
            } else {
                return _ember['default'].run.later(function () {
                    f();
                    _this.set('interval', _this.schedule(f));
                }, 10);
            }
        },
        updateDelayedPercent: (function () {
            var _this2 = this;

            if (this.get('type') !== 'old') {
                if (this.get('percent')) {
                    this.schedule(function () {
                        _this2.set('delayedPercent', _this2.get('delayedPercent') + 1);
                    });
                }
            } else {
                this.set('delayedPercent', this.get('percent'));
            }
        }).on('didInsertElement'),

        color: (function () {
            return (0, _vimmeUtilsColor['default'])(this.get('percent'));
        }).property('percent'),

        rotation: (function () {
            return Math.floor(this.get('delayedPercent') * 3.6) / 2;
        }).property('delayedPercent'),

        styles: (function () {
            var _this3 = this;

            return this.get('transforms').reduce(function (prev, transform) {
                return (prev ? prev + ' ' : '') + (transform + ': rotate(' + _this3.get('rotation') + 'deg);');
            }, '');
        }).property('transforms', 'rotation'),

        stylesFix: (function () {
            var _this4 = this;

            return this.get('transforms').reduce(function (prev, transform) {
                return (prev ? prev + ' ' : '') + (transform + ': rotate(' + _this4.get('rotation') * 2 + 'deg);');
            }, '');
        }).property('transforms', 'rotation')

    });
});