define('vimme/routes/sub-admin/test', ['exports', 'vimme/routes/sub-admin'], function (exports, _vimmeRoutesSubAdmin) {
    exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
        beforeModel: function beforeModel(transition) {

            this._super(transition);

            if (transition.targetName === 'sub-admin.test.index') {
                this.replaceWith('sub-admin.questions');
            }
        },
        model: function model(params) {
            return this.store.find('test', params.test_id);
        }
    });
});