define('vimme/controllers/admin/builder/quiz-questions/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        index: _ember['default'].inject.controller('admin.builder.quiz-questions.index'),

        isSaving: false,

        actions: {
            cancel: function cancel() {
                this.store.all('category').filterBy('id', null).invoke('deleteRecord');
                this.store.all('quizQuestion').filterBy('id', null).invoke('deleteRecord');
                this.transitionToRoute('admin.builder.quiz-questions');
            },
            save: function save(quizQuestion) {
                var _this = this;

                this.set('isSaving', true);

                quizQuestion.save().then(function (quizQuestion) {
                    _this.set('isSaving', false);
                    _this.get('index').notifyPropertyChange('quizQuestions');
                    _this.transitionToRoute('admin.builder.quiz-questions.quiz-question', quizQuestion.get('id'));
                })['catch'](function () {
                    _this.set('isSaving', false);
                });
            }
        }

    });
});