define("vimme/templates/admin/builder/tests/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/admin/builder/tests/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "vimme-test-editor", [], ["test", ["subexpr", "@mut", [["get", "model.test", ["loc", [null, [2, 9], [2, 19]]]]], [], []], "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [3, 11], [3, 23]]]]], [], []], "weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [4, 10], [4, 21]]]]], [], []], "canChangeStatus", ["subexpr", "@mut", [["get", "model.user.canEditLiveTests", ["loc", [null, [5, 20], [5, 47]]]]], [], []], "saveTest", ["subexpr", "route-action", ["saveTest"], [], ["loc", [null, [6, 13], [6, 38]]]], "deleteTest", ["subexpr", "route-action", ["deleteTest"], [], ["loc", [null, [7, 15], [7, 42]]]]], ["loc", [null, [1, 0], [8, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});