define('vimme/adapters/application', ['exports', 'ember-data', 'ember', 'vimme/config/environment'], function (exports, _emberData, _ember, _vimmeConfigEnvironment) {

    _emberData['default'].RESTAdapter.reopen({
        coalesceFindRequests: true
    });

    exports['default'] = _emberData['default'].ActiveModelAdapter.extend({
        host: _vimmeConfigEnvironment['default'].API.host,

        pathForType: function pathForType(type) {

            var decamelized = _ember['default'].String.decamelize(type),
                dasherized = _ember['default'].String.dasherize(decamelized);

            return _ember['default'].String.pluralize(dasherized);
        }
    });
});