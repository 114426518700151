define('vimme/controllers/school-admin/management/students', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        needs: ['application', 'school-admin/management/students/index'],

        queryParams: ['print'],
        print: false,

        selectedTeacher: null,
        selectedGrade: null,

        indexController: _ember['default'].computed.alias('controllers.school-admin/management/students/index'),

        resetSelectedTeacher: (function () {
            var selectedGrade = this.get('selectedGrade');
            if (!selectedGrade || selectedGrade.get('id') !== this.get('selectedTeacher.grade.id')) {
                this.set('selectedTeacher', null);
            }
        }).observes('selectedGrade'),

        actions: {

            newStudent: function newStudent() {

                if (this.get('controllers.application.currentRouteName') !== 'school-admin.management.students.index') {

                    this.transitionToRoute('school-admin.management.students');
                }

                var index = this.get('indexController');

                index.set('lastName', '');
                index.set('middleInitial', '');
                index.set('firstName', '');
                index.set('teacher', null);
                index.set('username', '');
                index.set('password', '');
            }

        },

        filteredTeachers: (function () {
            return this.get('model.teachers').filterBy('grade.id', this.get('selectedGrade.id'));
        }).property('model.teachers.[]', 'selectedGrade'),

        filteredStudents: (function () {

            var escape = function escape(term) {
                return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
            };

            var students = this.get('model.students') || [],
                term = this.get('searchTerm'),
                filter = new RegExp(escape(term), 'i'),
                selectedGrade = this.get('selectedGrade'),
                selectedTeacher = this.get('selectedTeacher');

            if (selectedGrade) {
                students = students.filterBy('grade.id', selectedGrade.get('id'));
            }

            if (selectedTeacher) {
                students = students.filterBy('teacher.id', selectedTeacher.get('id'));
            }

            if (term) {
                students = students.filter(function (student) {
                    return filter.test(student.get('fullName'));
                });
            }

            students = _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                content: students.toArray(),
                sortProperties: ['lastThenFirst'],
                sortAscending: true
            });

            return students;
        }).property('searchTerm', 'model.students.@each', 'selectedGrade', 'selectedTeacher'),

        filteredRealStudents: _ember['default'].computed.filterBy('filteredStudents', 'teacher.isPlaceholder', false)

    });
});