define('vimme/components/vimme-resource-editor/component', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['vimme-resource-editor'],
    classNameBindings: ['typeClass'],

    // inputs
    grades: [],
    weeks: [],
    resource: null,
    isSaving: false,

    cancelText: 'Cancel',

    upload: null,

    resourceTypes: [{
      id: 'video',
      value: 'Vimeo Video'
    }, {
      id: 'link',
      value: 'External Link'
    }, {
      id: 'upload',
      value: 'Upload'
    }],

    selectedResourceType: null,

    setSelectedResourceType: (function () {
      if (this.get('resource.isVideo')) {
        this.set('selectedResourceType', this.get('resourceTypes').findBy('id', 'video'));
      } else if (this.get('resource.isUpload')) {
        this.set('selectedResourceType', this.get('resourceTypes').findBy('id', 'upload'));
      } else {
        this.set('selectedResourceType', this.get('resourceTypes').findBy('id', 'link'));
      }
    }).on('init'),

    typeClass: (function () {
      return 'vimme-resource-editor--' + this.get('selectedResourceType.id');
    }).property('selectedResourceType.id'),

    showUpload: (function () {
      return this.get('selectedResourceType.id') === 'upload';
    }).property('selectedResourceType.id'),

    uploadPath: (function () {
      var upload = this.get('upload');
      var uploadPath = this.get('resource.url');

      if (upload) {
        uploadPath = encodeURI(upload.name);
      }

      if (uploadPath) {
        var splitForward = uploadPath.split('/');
        var splitBack = uploadPath.split('\\');
        if (splitForward.length > 0) {
          uploadPath = splitForward[splitForward.length - 1];
        } else if (splitBack.length > 0) {
          uploadPath = splitBack[splitBack.length - 1];
        }
        uploadPath = decodeURI(uploadPath);
      }

      return uploadPath;
    }).property('resource.url', 'upload'),

    // not quite right
    isUnsavable: (function () {
      var isUnsavable = this.get('isSaving') || !this.get('resource.title');
      var type = this.get('selectedResourceType.id');
      var upload = type === 'upload' && !this.get('uploadPath');
      var url = type !== 'upload' && (!this.get('resource.url') || !this.get('resource.url').match(/^https?:\/\//));
      var vimeo = type === 'video' && (!this.get('resource.url') || this.get('resource.url').indexOf('vimeo') < 0);
      return isUnsavable || upload || url || vimeo;
    }).property('isSaving', 'selectedResourceType.id', 'uploadPath', 'resource.{title,url}'),
    isSavable: _ember['default'].computed.not('isUnsavable'),

    actions: {
      save: function save() {
        var _this = this;

        var upload = this.get('upload');
        var showUpload = this.get('showUpload');
        var resource = this.get('resource');

        this.set('isSaving', true);

        if (showUpload && upload) {
          _ember['default'].$.ajax({
            type: 'POST',
            url: _vimmeConfigEnvironment['default'].API.host + '/resources-upload-url',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
              type: upload.type,
              name: upload.name
            })
          }).then(function (data) {
            _ember['default'].$.ajax({
              type: 'PUT',
              url: data.upload_url,
              cache: false,
              processData: false,
              contentType: false,
              headers: {
                'Cache-Control': 'max-age=86400'
              },
              data: upload
            }).then(function () {
              resource.set('url', 'https://s3.amazonaws.com/' + _vimmeConfigEnvironment['default'].S3.bucket + '/resources/' + encodeURI(data.filename));
              _this.sendAction('save', resource);
            }, function () {
              // upload error
              _this.set('isSaving', false);
            });
          }, function () {
            // upload url generation error
            _this.set('isSaving', false);
          });
        } else {
          this.sendAction('save', resource);
        }
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    }
  });
});