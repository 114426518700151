define('vimme/routes/school-admin/reports/week', ['exports', 'vimme/routes/school-admin', 'ember'], function (exports, _vimmeRoutesSchoolAdmin, _ember) {
    exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                weeks: this.modelFor('school-admin.reports').weeks,
                schoolYears: this.modelFor('school-admin.reports').schoolYears,
                grades: this.modelFor('school-admin.reports').grades,
                gradeAveragesRaw: this.store.find('perGradeBySchoolWeeklyAverage')
            });
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            controller.set('selectedSchoolYear', null);
        }
    });
});