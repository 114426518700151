define('vimme/controllers/admin/builder/resources/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['grades', 'weeks', 'page', 'sort'],
        grades: null,
        weeks: null,
        page: 1,
        sort: 'grade',

        resourcesCache: [],
        resources: (function (key, value) {
            var _this = this;

            if (arguments.length > 1) {
                this.set('resourcesCache', value);
                return value;
            }
            this.store.find('resource', {
                grades: this.get('grades'),
                weeks: this.get('weeks'),
                page: this.get('page'),
                sort: this.get('sort')
            }).then(function (resources) {
                _this.set('resources', resources);
            });
            return this.get('resourcesCache');
        }).property('grades', 'weeks', 'page', 'sort')

    });
});