define("vimme/templates/sub-admin/reports/week", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 16
              },
              "end": {
                "line": 11,
                "column": 128
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Week View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 16
              },
              "end": {
                "line": 12,
                "column": 136
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Question View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 27,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [22, 28], [22, 36]]]]], [], []], "optionLabelPath", "content.lastThenFirstPlusSchool", "value", ["subexpr", "@mut", [["get", "selectedTeacher", ["loc", [null, [24, 26], [24, 41]]]]], [], []], "prompt", "Select Teacher"], ["loc", [null, [21, 16], [26, 18]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 16
                },
                "end": {
                  "line": 31,
                  "column": 16
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "btn btn-pacing-guide btn-pacing-guide-2");
              dom.setAttribute(el1, "target", "_blank");
              var el2 = dom.createTextNode("View Pacing Guide");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element42 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element42, 'href');
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "pacingGuide", ["loc", [null, [30, 79], [30, 90]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 16
                },
                "end": {
                  "line": 33,
                  "column": 16
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "btn btn-pacing-guide-2 disabled");
              var el2 = dom.createTextNode("View Pacing Guide");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 12
              },
              "end": {
                "line": 34,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "pacingGuide", ["loc", [null, [29, 22], [29, 33]]]]], [], 0, 1, ["loc", [null, [29, 16], [33, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 35,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "view-choices");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element43 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(element43, 1, 1);
          morphs[2] = dom.createMorphAt(element43, 3, 3);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "schoolYears", ["loc", [null, [5, 24], [5, 35]]]]], [], []], "optionLabelPath", "content.nameSchool", "value", ["subexpr", "@mut", [["get", "selectedSchoolYear", ["loc", [null, [7, 22], [7, 40]]]]], [], []], "prompt", "Current Year"], ["loc", [null, [4, 12], [9, 14]]]], ["block", "link-to", ["sub-admin.reports.week"], ["class", "view-choice"], 0, null, ["loc", [null, [11, 16], [11, 140]]]], ["block", "link-to", ["sub-admin.reports.question"], ["class", "view-choice"], 1, null, ["loc", [null, [12, 16], [12, 148]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [15, 24], [15, 36]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedGrade", ["loc", [null, [17, 22], [17, 35]]]]], [], []], "prompt", "Select Grade"], ["loc", [null, [14, 12], [19, 14]]]], ["block", "if", [["get", "showTeachers", ["loc", [null, [20, 18], [20, 30]]]]], [], 2, null, ["loc", [null, [20, 12], [27, 19]]]], ["block", "unless", [["get", "isDemo", ["loc", [null, [28, 22], [28, 28]]]]], [], 3, null, ["loc", [null, [28, 12], [34, 23]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 16
              },
              "end": {
                "line": 39,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "grade-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedGrade.title", ["loc", [null, [38, 46], [38, 69]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 16
              },
              "end": {
                "line": 42,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "teacher-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedTeacher.lastThenFirst", ["loc", [null, [41, 48], [41, 81]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 8
            },
            "end": {
              "line": 44,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "print-crumb");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element41 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element41, 1, 1);
          morphs[1] = dom.createMorphAt(element41, 2, 2);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedGrade", ["loc", [null, [37, 22], [37, 35]]]]], [], 0, null, ["loc", [null, [37, 16], [39, 23]]]], ["block", "if", [["get", "selectedTeacher", ["loc", [null, [40, 22], [40, 37]]]]], [], 1, null, ["loc", [null, [40, 16], [42, 23]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 8
            },
            "end": {
              "line": 48,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Print View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element40 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element40);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [47, 34], [47, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 8
            },
            "end": {
              "line": 50,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Report View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element39 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element39);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [49, 34], [49, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 28
                    },
                    "end": {
                      "line": 70,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" (");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(")");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element28 = dom.childAt(fragment, [1]);
                  var element29 = dom.childAt(element28, [1]);
                  var element30 = dom.childAt(element28, [3]);
                  var element31 = dom.childAt(element28, [5]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element29, 0, 0);
                  morphs[1] = dom.createMorphAt(element29, 2, 2);
                  morphs[2] = dom.createAttrMorph(element30, 'class');
                  morphs[3] = dom.createMorphAt(element30, 0, 0);
                  morphs[4] = dom.createAttrMorph(element31, 'class');
                  return morphs;
                },
                statements: [["content", "average.grade.title", ["loc", [null, [66, 54], [66, 77]]]], ["content", "average.school.title", ["loc", [null, [66, 79], [66, 103]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [67, 55], [67, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [67, 64], [67, 84]]]], ["attribute", "class", ["concat", ["cell ", ["get", "trend", ["loc", [null, [68, 55], [68, 60]]]]]]]],
                locals: ["formattedPercent", "color", "trend"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 24
                  },
                  "end": {
                    "line": 71,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [64, 57], [64, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [64, 77], [64, 84]]]]], [], []]], 0, null, ["loc", [null, [64, 28], [70, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 20
                },
                "end": {
                  "line": 72,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header grade-column");
              var el3 = dom.createTextNode("Grade");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header trend-column");
              var el3 = dom.createTextNode("Trend");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element32 = dom.childAt(fragment, [1]);
              var element33 = dom.childAt(element32, [1]);
              var element34 = dom.childAt(element32, [3]);
              var element35 = dom.childAt(element32, [5]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element32, 'class');
              morphs[1] = dom.createElementMorph(element33);
              morphs[2] = dom.createElementMorph(element34);
              morphs[3] = dom.createElementMorph(element35);
              morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [58, 49], [58, 66]]]]]]], ["element", "action", ["sortAveragesOther", "grade-column"], [], ["loc", [null, [59, 66], [59, 111]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [60, 68], [60, 115]]]], ["element", "action", ["sortAveragesOther", "trend-column"], [], ["loc", [null, [61, 66], [61, 111]]]], ["block", "each", [["get", "averages", ["loc", [null, [63, 32], [63, 40]]]]], [], 0, null, ["loc", [null, [63, 24], [71, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 80,
                      "column": 28
                    },
                    "end": {
                      "line": 86,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element21 = dom.childAt(fragment, [1]);
                  var element22 = dom.childAt(element21, [3]);
                  var element23 = dom.childAt(element21, [5]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element21, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element22, 'class');
                  morphs[2] = dom.createMorphAt(element22, 0, 0);
                  morphs[3] = dom.createAttrMorph(element23, 'class');
                  return morphs;
                },
                statements: [["content", "average.teacher.lastThenFirstPlusSchool", ["loc", [null, [82, 54], [82, 97]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [83, 55], [83, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [83, 64], [83, 84]]]], ["attribute", "class", ["concat", ["cell ", ["get", "trend", ["loc", [null, [84, 55], [84, 60]]]]]]]],
                locals: ["formattedPercent", "color", "trend"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 79,
                    "column": 24
                  },
                  "end": {
                    "line": 87,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [80, 57], [80, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [80, 77], [80, 84]]]]], [], []]], 0, null, ["loc", [null, [80, 28], [86, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 73,
                  "column": 20
                },
                "end": {
                  "line": 88,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header teacher-column");
              var el3 = dom.createTextNode("Teacher");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header trend-column");
              var el3 = dom.createTextNode("Trend");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element24 = dom.childAt(fragment, [1]);
              var element25 = dom.childAt(element24, [1]);
              var element26 = dom.childAt(element24, [3]);
              var element27 = dom.childAt(element24, [5]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element24, 'class');
              morphs[1] = dom.createElementMorph(element25);
              morphs[2] = dom.createElementMorph(element26);
              morphs[3] = dom.createElementMorph(element27);
              morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [74, 49], [74, 66]]]]]]], ["element", "action", ["sortAveragesOther", "teacher-column"], [], ["loc", [null, [75, 68], [75, 115]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [76, 68], [76, 115]]]], ["element", "action", ["sortAveragesOther", "trend-column"], [], ["loc", [null, [77, 66], [77, 111]]]], ["block", "each", [["get", "averages", ["loc", [null, [79, 32], [79, 40]]]]], [], 0, null, ["loc", [null, [79, 24], [87, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 96,
                      "column": 28
                    },
                    "end": {
                      "line": 102,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element14 = dom.childAt(fragment, [1]);
                  var element15 = dom.childAt(element14, [3]);
                  var element16 = dom.childAt(element14, [5]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element14, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element15, 'class');
                  morphs[2] = dom.createMorphAt(element15, 0, 0);
                  morphs[3] = dom.createAttrMorph(element16, 'class');
                  return morphs;
                },
                statements: [["content", "average.student.lastThenFirst", ["loc", [null, [98, 54], [98, 87]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [99, 55], [99, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [99, 64], [99, 84]]]], ["attribute", "class", ["concat", ["cell ", ["get", "trend", ["loc", [null, [100, 55], [100, 60]]]]]]]],
                locals: ["formattedPercent", "color", "trend"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 24
                  },
                  "end": {
                    "line": 103,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [96, 57], [96, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [96, 77], [96, 84]]]]], [], []]], 0, null, ["loc", [null, [96, 28], [102, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 20
                },
                "end": {
                  "line": 104,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header student-column");
              var el3 = dom.createTextNode("Student");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header trend-column");
              var el3 = dom.createTextNode("Trend");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var element18 = dom.childAt(element17, [1]);
              var element19 = dom.childAt(element17, [3]);
              var element20 = dom.childAt(element17, [5]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element17, 'class');
              morphs[1] = dom.createElementMorph(element18);
              morphs[2] = dom.createElementMorph(element19);
              morphs[3] = dom.createElementMorph(element20);
              morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [90, 49], [90, 66]]]]]]], ["element", "action", ["sortAveragesOther", "student-column"], [], ["loc", [null, [91, 68], [91, 115]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [92, 68], [92, 115]]]], ["element", "action", ["sortAveragesOther", "trend-column"], [], ["loc", [null, [93, 66], [93, 111]]]], ["block", "each", [["get", "averages", ["loc", [null, [95, 32], [95, 40]]]]], [], 0, null, ["loc", [null, [95, 24], [103, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 24
                },
                "end": {
                  "line": 112,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element13, 'class');
              morphs[1] = dom.createElementMorph(element13);
              morphs[2] = dom.createMorphAt(element13, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header ", ["get", "week.shortName", ["loc", [null, [111, 54], [111, 68]]]]]]], ["element", "action", ["sortAveragesWeek", ["get", "week", ["loc", [null, [111, 100], [111, 104]]]]], [], ["loc", [null, [111, 72], [111, 106]]]], ["content", "week.shortName", ["loc", [null, [111, 107], [111, 125]]]]],
            locals: ["week"],
            templates: []
          };
        })();
        var child4 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 117,
                      "column": 32
                    },
                    "end": {
                      "line": 119,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element12 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element12, 'class');
                  morphs[1] = dom.createMorphAt(element12, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.average.color", ["loc", [null, [118, 55], [118, 88]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [118, 92], [118, 140]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 115,
                    "column": 24
                  },
                  "end": {
                    "line": 121,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
              },
              arity: 4,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [117, 40], [117, 60]]]]], [], 0, null, ["loc", [null, [117, 32], [119, 41]]]]],
              locals: ["formattedPercent", "color", "trend", "paddedWeeklyAverages"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 20
                },
                "end": {
                  "line": 122,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [115, 53], [115, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [115, 73], [115, 80]]]]], [], []]], 0, null, ["loc", [null, [115, 24], [121, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 8
              },
              "end": {
                "line": 125,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element36 = dom.childAt(fragment, [1, 1]);
            var element37 = dom.childAt(fragment, [3, 1]);
            var element38 = dom.childAt(element37, [1]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(element36, 1, 1);
            morphs[1] = dom.createMorphAt(element36, 2, 2);
            morphs[2] = dom.createMorphAt(element36, 3, 3);
            morphs[3] = dom.createAttrMorph(element38, 'class');
            morphs[4] = dom.createMorphAt(element38, 1, 1);
            morphs[5] = dom.createMorphAt(element37, 3, 3);
            return morphs;
          },
          statements: [["block", "if", [["get", "showGradeAverages", ["loc", [null, [57, 26], [57, 43]]]]], [], 0, null, ["loc", [null, [57, 20], [72, 27]]]], ["block", "if", [["get", "showTeacherAverages", ["loc", [null, [73, 26], [73, 45]]]]], [], 1, null, ["loc", [null, [73, 20], [88, 27]]]], ["block", "if", [["get", "showStudentAverages", ["loc", [null, [89, 26], [89, 45]]]]], [], 2, null, ["loc", [null, [89, 20], [104, 27]]]], ["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [109, 45], [109, 62]]]]]]], ["block", "each", [["get", "model.weeks", ["loc", [null, [110, 32], [110, 43]]]]], [], 3, null, ["loc", [null, [110, 24], [112, 33]]]], ["block", "each", [["get", "averages", ["loc", [null, [114, 28], [114, 36]]]]], [], 4, null, ["loc", [null, [114, 20], [122, 29]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 4
            },
            "end": {
              "line": 126,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [54, 14], [54, 22]]]]], [], 0, null, ["loc", [null, [54, 8], [125, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 138,
                        "column": 32
                      },
                      "end": {
                        "line": 140,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element8, 1, 1);
                    morphs[1] = dom.createMorphAt(element8, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [139, 62], [139, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [139, 91], [139, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 130,
                      "column": 20
                    },
                    "end": {
                      "line": 143,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                },
                arity: 4,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Grade: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-trend");
                  var el4 = dom.createTextNode("Trend: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("i");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1]);
                  var element10 = dom.childAt(element9, [1]);
                  var element11 = dom.childAt(element10, [5, 1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
                  morphs[2] = dom.createAttrMorph(element11, 'class');
                  morphs[3] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.grade.title", ["loc", [null, [133, 75], [133, 98]]]], ["content", "formattedPercent", ["loc", [null, [134, 76], [134, 96]]]], ["attribute", "class", ["concat", [["get", "trend", ["loc", [null, [135, 88], [135, 93]]]]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [138, 40], [138, 60]]]]], [], 0, null, ["loc", [null, [138, 32], [140, 41]]]]],
                locals: ["formattedPercent", "color", "trend", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 129,
                    "column": 16
                  },
                  "end": {
                    "line": 144,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [130, 49], [130, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [130, 69], [130, 76]]]]], [], []]], 0, null, ["loc", [null, [130, 20], [143, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 128,
                  "column": 12
                },
                "end": {
                  "line": 145,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [129, 24], [129, 32]]]]], [], 0, null, ["loc", [null, [129, 16], [144, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 156,
                        "column": 32
                      },
                      "end": {
                        "line": 158,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element4, 1, 1);
                    morphs[1] = dom.createMorphAt(element4, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [157, 62], [157, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [157, 91], [157, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 148,
                      "column": 20
                    },
                    "end": {
                      "line": 161,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                },
                arity: 4,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Teacher: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-trend");
                  var el4 = dom.createTextNode("Trend: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("i");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var element6 = dom.childAt(element5, [1]);
                  var element7 = dom.childAt(element6, [5, 1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
                  morphs[2] = dom.createAttrMorph(element7, 'class');
                  morphs[3] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.teacher.lastThenFirst", ["loc", [null, [151, 77], [151, 110]]]], ["content", "formattedPercent", ["loc", [null, [152, 76], [152, 96]]]], ["attribute", "class", ["concat", [["get", "trend", ["loc", [null, [153, 88], [153, 93]]]]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [156, 40], [156, 60]]]]], [], 0, null, ["loc", [null, [156, 32], [158, 41]]]]],
                locals: ["formattedPercent", "color", "trend", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 147,
                    "column": 16
                  },
                  "end": {
                    "line": 162,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [148, 49], [148, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [148, 69], [148, 76]]]]], [], []]], 0, null, ["loc", [null, [148, 20], [161, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 146,
                  "column": 12
                },
                "end": {
                  "line": 163,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [147, 24], [147, 32]]]]], [], 0, null, ["loc", [null, [147, 16], [162, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 174,
                        "column": 32
                      },
                      "end": {
                        "line": 176,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element0, 1, 1);
                    morphs[1] = dom.createMorphAt(element0, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [175, 62], [175, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [175, 91], [175, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 166,
                      "column": 20
                    },
                    "end": {
                      "line": 179,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
                },
                arity: 4,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Student: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-trend");
                  var el4 = dom.createTextNode("Trend: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("i");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var element2 = dom.childAt(element1, [1]);
                  var element3 = dom.childAt(element2, [5, 1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
                  morphs[2] = dom.createAttrMorph(element3, 'class');
                  morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.student.lastThenFirst", ["loc", [null, [169, 77], [169, 110]]]], ["content", "formattedPercent", ["loc", [null, [170, 76], [170, 96]]]], ["attribute", "class", ["concat", [["get", "trend", ["loc", [null, [171, 88], [171, 93]]]]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [174, 40], [174, 60]]]]], [], 0, null, ["loc", [null, [174, 32], [176, 41]]]]],
                locals: ["formattedPercent", "color", "trend", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 165,
                    "column": 16
                  },
                  "end": {
                    "line": 180,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [166, 49], [166, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [166, 69], [166, 76]]]]], [], []]], 0, null, ["loc", [null, [166, 20], [179, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 164,
                  "column": 12
                },
                "end": {
                  "line": 181,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [165, 24], [165, 32]]]]], [], 0, null, ["loc", [null, [165, 16], [180, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 127,
                "column": 8
              },
              "end": {
                "line": 182,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "showGradeAverages", ["loc", [null, [128, 18], [128, 35]]]]], [], 0, null, ["loc", [null, [128, 12], [145, 19]]]], ["block", "if", [["get", "showTeacherAverages", ["loc", [null, [146, 18], [146, 37]]]]], [], 1, null, ["loc", [null, [146, 12], [163, 19]]]], ["block", "if", [["get", "showStudentAverages", ["loc", [null, [164, 18], [164, 37]]]]], [], 2, null, ["loc", [null, [164, 12], [181, 19]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 126,
              "column": 4
            },
            "end": {
              "line": 183,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [127, 14], [127, 22]]]]], [], 0, null, ["loc", [null, [127, 8], [182, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 185,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/sub-admin/reports/week.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element44 = dom.childAt(fragment, [0]);
        var element45 = dom.childAt(element44, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element45, 1, 1);
        morphs[1] = dom.createMorphAt(element45, 3, 3);
        morphs[2] = dom.createMorphAt(element44, 3, 3);
        return morphs;
      },
      statements: [["block", "unless", [["get", "print", ["loc", [null, [3, 18], [3, 23]]]]], [], 0, 1, ["loc", [null, [3, 8], [44, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [46, 18], [46, 23]]]]], [], 2, 3, ["loc", [null, [46, 8], [50, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [53, 14], [53, 19]]]]], [], 4, 5, ["loc", [null, [53, 4], [183, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});