define('vimme/controllers/index', ['exports', 'ember', 'simple-auth/mixins/login-controller-mixin', 'vimme/config/environment'], function (exports, _ember, _simpleAuthMixinsLoginControllerMixin, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_simpleAuthMixinsLoginControllerMixin['default'], {
    authenticator: 'authenticator:custom',

    loginError: false,

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this._super().then(function () {
          _this.set('loginError', false);
        }, function () {
          _this.set('loginError', true);
          alert('The username and/or password entered are invalid.');
        });
      },
      showForgotDialog: function showForgotDialog() {
        this.set('showForgotDialog', true);
      },
      cancelForgotDialog: function cancelForgotDialog() {
        this.set('forgotEmail', null);
        this.set('isResetError', false);
        this.set('isResetSuccess', false);
        this.set('showForgotDialog', false);
      },
      sendResetLink: function sendResetLink(forgotEmail) {
        var _this2 = this;

        var hash = {
          type: 'POST',
          url: _vimmeConfigEnvironment['default'].API.host + '/password/reset-link',
          data: {
            email: forgotEmail
          }
        };
        this.set('isResetError', false);
        this.set('isResetSuccess', false);
        _ember['default'].$.ajax(hash).done(function (data) {
          _this2.set('message', data.message);
          _this2.set('isResetError', false);
          _this2.set('isResetSuccess', true);
        }).fail(function (xhr) {
          _this2.set('message', xhr.responseJSON.message);
          _this2.set('isResetError', true);
          _this2.set('isResetSuccess', false);
        });
      }
    }
  });
});