define("vimme/templates/admin/builder/tests/test/questions/new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/admin/builder/tests/test/questions/new.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "vimme-test-question-editor", [], ["question", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 13], [2, 18]]]]], [], []], "saveQuestion", ["subexpr", "route-action", ["saveQuestion"], [], ["loc", [null, [3, 17], [3, 46]]]], "cancel", ["subexpr", "route-action", ["cancel"], [], ["loc", [null, [4, 11], [4, 34]]]], "reroute", ["subexpr", "route-action", ["reroute"], [], ["loc", [null, [5, 12], [5, 36]]]]], ["loc", [null, [1, 0], [6, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});