define('vimme/models/week', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        number: _emberData['default'].attr('number'),

        tests: _emberData['default'].hasMany('test', { async: true }),

        shortName: (function () {
            return 'W' + this.get('number');
        }).property('number'),

        longName: (function () {
            return 'Week ' + this.get('number');
        }).property('number')

    });
});