define('vimme/routes/teacher/testing-calendar', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model() {
            var _this = this;

            return _ember['default'].RSVP.hash({
                tests: _this.modelFor('teacher').tests,
                teacherTests: _this.modelFor('teacher').teacherTests
            });
        }
    });
});