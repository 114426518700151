define('vimme/routes/school-admin/management/teachers', ['exports', 'ember', 'vimme/routes/school-admin'], function (exports, _ember, _vimmeRoutesSchoolAdmin) {
    exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                students: this.store.find('student'),
                teachers: this.store.find('teacher'),
                grades: this.store.find('grade')
            });
        }
    });
});