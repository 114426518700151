define('vimme/routes/student/test', ['exports', 'vimme/routes/student', 'ember'], function (exports, _vimmeRoutesStudent, _ember) {
    exports['default'] = _vimmeRoutesStudent['default'].extend({

        findTeacherTest: function findTeacherTest(teacherTests) {
            if (!teacherTests || teacherTests.get('length') <= 0) {
                throw 'Missing Teacher Test';
            }
            return teacherTests.get('firstObject');
        },
        findStudentsAndStudentTests: function findStudentsAndStudentTests(teacherTest) {
            if (!teacherTest.get('isActive') && !teacherTest.get('isReviewable')) {
                throw 'Test should not be viewable';
            }
            return _ember['default'].RSVP.hash({
                teacherTest: teacherTest,
                studentTests: teacherTest.get('studentTests'),
                student: this.store.find('student', this.get('session.content.id'))
            });
        },
        findOrCreateStudentTest: function findOrCreateStudentTest(_ref) {
            var teacherTest = _ref.teacherTest;
            var studentTests = _ref.studentTests;
            var student = _ref.student;

            var studentTest;
            if (studentTests && studentTests.get('length') > 0) {
                studentTest = studentTests.get('firstObject');
            }

            if (studentTest) {
                if (studentTest.get('isComplete') && !teacherTest.get('isReviewable')) {
                    throw 'Test is not reviewable';
                }
                if (!studentTest.get('isComplete') && !teacherTest.get('isActive')) {
                    throw 'Test cannot be continued because teacherTest is inactive';
                }
            } else {
                if (!teacherTest.get('isActive')) {
                    throw 'Cannot create student test for inactive teacher test.';
                }
                studentTest = this.store.createRecord('studentTest', {
                    teacherTest: teacherTest,
                    student: student
                }).save();
            }

            return _ember['default'].RSVP.hash({
                studentTests: studentTests,
                studentTest: studentTest
            });
        },

        model: function model(params) {
            return this.store.find('test', params.test_id);
        },
        afterModel: function afterModel(test, transition) {
            var _this = this;

            return test.get('teacherTests').then(this.findTeacherTest).then(this.findStudentsAndStudentTests.bind(this)).then(this.findOrCreateStudentTest.bind(this)).then(function (_ref2) {
                var studentTests = _ref2.studentTests;
                var studentTest = _ref2.studentTest;

                studentTests.pushObject(studentTest);
                if (transition.targetName === 'student.test.index') {
                    _this.replaceWith('student.questions');
                }
            })['catch'](function () /*err*/{
                // console.log(err);
                _this.store.all('studentTest').filterBy('id', null).invoke('deleteRecord');
                _this.replaceWith('student.testing-calendar');
            });
        },

        actions: {
            error: function error() /*error, transition*/{
                // console.log(error);
                this.store.all('studentTest').filterBy('id', null).invoke('deleteRecord');
                this.replaceWith('student.testing-calendar');
            }
        }
    });
});