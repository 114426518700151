define('vimme/controllers/student/questions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        application: _ember['default'].inject.controller(),

        totalQuestions: _ember['default'].computed.alias('model.questions.length'),

        rulerIN: false,
        rulerCM: false,
        protractor: false,

        showDialog: false,

        isSaving: false,

        // set in question route
        currentQuestion: null,

        showProtractor: (function () {
            return parseInt(this.get('model.test.grade.id'), 10) !== 2;
        }).property('model.test.grade.id'),

        isReviewing: (function () {
            return this.get('model.teacherTest.isReviewable') && this.get('model.studentTest.isComplete');
        }).property('model.{teacherTest.isReviewable,studentTest.isComplete}'),

        answered: _ember['default'].computed('model.studentQuestions.@each.{answers.length,question.isTextQuestion,textAnswer}', function () {
            var studentQuestions = this.get('model.studentQuestions');
            if (!studentQuestions) {
                return 0;
            }
            return studentQuestions.reduce(function (answered, studentQuestion) {
                var isTextQuestion = studentQuestion.get('question.isTextQuestion');

                if (isTextQuestion) {
                    if (!!studentQuestion.get('textAnswer')) {
                        answered += 1;
                    }
                } else {
                    if (studentQuestion.get('answers') && studentQuestion.get('answers.length') > 0) {
                        answered += 1;
                    }
                }
                return answered;
            }, 0);
        }),

        unanswered: (function () {
            return this.get('totalQuestions') - this.get('answered');
        }).property('answered'),

        isUnfinished: (function () {
            return this.get('unanswered') > 0 || this.get('isSaving');
        }).property('unanswered', 'isSaving'),

        finishText: (function () {

            var unanswered = this.get('unanswered');

            if (unanswered > 0) {
                return unanswered + ' left';
            }

            return 'Finish';
        }).property('unanswered'),

        currentIndex: (function () {
            return this.get('model.questions').indexOf(this.get('currentQuestion')) + 1;
        }).property('currentQuestion'),

        previousIndex: (function () {

            var currentIndex = this.get('currentIndex');

            if (currentIndex <= 1) {
                return this.get('totalQuestions');
            }

            return --currentIndex;
        }).property('currentIndex'),

        nextIndex: (function () {

            var currentIndex = this.get('currentIndex');

            if (currentIndex >= this.get('totalQuestions')) {
                return 1;
            }

            return ++currentIndex;
        }).property('currentIndex'),

        requiresTools: (function () {

            this.set('rulerIN', false);
            this.set('rulerCM', false);
            this.set('protractor', false);

            return this.get('currentQuestion.requiresTools');
        }).property('currentQuestion'),

        actions: {
            showDialog: function showDialog() {

                if (this.get('isSaving')) {
                    return;
                }

                this.set('showDialog', true);
            },
            cancel: function cancel() {
                this.set('showDialog', false);
            },
            finish: function finish(route) {
                var _this = this;

                if (this.get('isSaving')) {
                    return;
                }

                var studentTest = this.get('model.studentTest');

                studentTest.set('isComplete', true);

                studentTest.save().then(function () {
                    _this.set('showDialog', false);
                    if (studentTest.get('teacherTest.test.isPractice')) {
                        _this.transitionToRoute('student.index');
                    } else {
                        _this.transitionToRoute(route, { queryParams: { complete: true } });
                    }
                })['catch'](function () {
                    studentTest.set('isComplete', false);
                });
            },
            exit: function exit(route) {

                if (this.get('isSaving')) {
                    return;
                }

                var oldRoute = this.get('application.savedRoute'),
                    oldModel = this.get('application.savedModel');

                if (oldRoute && oldModel) {
                    this.transitionToRoute(oldRoute, oldModel);
                    this.set('application.savedRoute', null);
                    this.set('application.savedModel', null);
                } else if (oldRoute) {
                    this.transitionToRoute(oldRoute);
                    this.set('application.savedRoute', null);
                } else {
                    this.transitionToRoute(route);
                }
            },
            toggleRulerIN: function toggleRulerIN() {
                this.set('rulerCM', false);
                this.set('protractor', false);
                this.toggleProperty('rulerIN');
            },
            toggleRulerCM: function toggleRulerCM() {
                this.set('rulerIN', false);
                this.set('protractor', false);
                this.toggleProperty('rulerCM');
            },
            toggleProtractor: function toggleProtractor() {
                this.set('rulerCM', false);
                this.set('rulerIN', false);
                this.toggleProperty('protractor');
            }
        }

    });
});