define('vimme/components/vimme-test-question-list/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-test-question-list'],

        // inputs
        test: null,

        slipList: null,

        beforeReorder: null,
        beforeSwipe: null,
        beforeWait: null,
        reorder: null,

        hasReordered: false,

        componentDidMount: (function () {
            var _this = this;

            var list = this.$().find('.sortable-list').get(0);

            this.beforeReorder = function (e) {
                if (/quiz-builder__question-reorder/.test(e.target.className) === false) {
                    e.preventDefault();
                }
            };
            this.beforeSwipe = function (e) {
                e.preventDefault();
            };
            this.beforeWait = function (e) {
                e.preventDefault();
            };
            this.reorder = function (e) {
                var items = _this.get('test.questions');
                var item = items.objectAt(e.detail.originalIndex);

                _this.beginPropertyChanges();
                items.removeObject(item);
                items.insertAt(e.detail.spliceIndex, item);
                _this.endPropertyChanges();
            };

            list.addEventListener('slip:beforereorder', this.beforeReorder, false);
            list.addEventListener('slip:beforeswipe', this.beforeSwipe, false);
            list.addEventListener('slip:beforewait', this.beforeWait, false);
            list.addEventListener('slip:reorder', this.reorder, false);

            this.set('slipList', new Slip(list));
        }).on('didInsertElement'),

        componentWillUnmount: (function () {

            var list = this.$().find('.sortable-list').get(0);

            list.removeEventListener('slip:beforereorder', this.beforeRorder);
            list.removeEventListener('slip:beforeswipe', this.beforeSwipe);
            list.removeEventListener('slip:beforewait', this.beforeWait);
            list.removeEventListener('slip:reorder', this.reorder);

            this.get('slipList').detach();
            this.set('slipList', null);
        }).on('willDestroyElement'),

        updateOrder: (function () {
            this.set('hasReordered', true);
            _ember['default'].run.schedule('afterRender', this, function () {
                var questions = this.get('test.questions');
                questions.forEach(function (question, index) {
                    question.set('order', index + 1);
                });
            });
        }).observes('test.questions.[]'),

        actions: {
            reset: function reset() {
                var _this2 = this;

                this.get('test.questions').reload().then(function () {
                    return _this2.set('hasReordered', false);
                });
            },
            save: function save() {
                var _this3 = this;

                // not good to save each of these individually!
                var questions = this.get('test.questions').filter(function (question) {
                    return question.get('isDirty');
                });
                _ember['default'].RSVP.all(questions.invoke('save')).then(function () {
                    _this3.set('hasReordered', false);
                })['catch'](function () {
                    alert('error saving');
                });
            },
            viewDelDialog: function viewDelDialog(question) {
                this.set('currentQuestion', question);
                this.set('showDelDialog', true);
            },
            cancelDelDialog: function cancelDelDialog() {
                this.set('currentQuestion', null);
                this.set('showDelDialog', false);
            },
            'delete': function _delete() {
                var _this4 = this;

                this.get('currentQuestion').destroyRecord().then(function () {
                    _this4.set('currentQuestion', null);
                    _this4.set('showDelDialog', false);
                })['catch'](function () {
                    alert('error deleting');
                });
            }
        }
    });
});