define('vimme/routes/sub-admin/questions/question', ['exports', 'vimme/routes/sub-admin'], function (exports, _vimmeRoutesSubAdmin) {
    exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
        model: function model(params) {
            var questions = this.modelFor('sub-admin.questions').questions,
                length = questions.get('length');

            if (params.question_id > 0 && params.question_id <= length) {

                return questions.objectAt(params.question_id - 1);
            }

            this.replaceWith('sub-admin.questions.question', 1);
        },
        setupController: function setupController(controller, model) {

            controller.set('model', model);

            this.controllerFor('sub-admin.questions').set('currentQuestion', model);
        }
    });
});