define('vimme/controllers/sub-admin/quiz-builder', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: [{ addQuestions: 'add-questions' }],
        addQuestions: false,

        application: _ember['default'].inject.controller(),
        index: _ember['default'].inject.controller('sub-admin.quiz-builder.index'),

        actions: {

            newQuiz: function newQuiz() {

                this.set('addQuestions', false);

                if (this.get('application.currentRouteName') !== 'sub-admin.quiz-builder.index') {
                    this.transitionToRoute('sub-admin.quiz-builder.index');
                } else {
                    this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
                    this.set('index.model.quiz', this.store.createRecord('quiz', {
                        title: null,
                        subAdmin: this.get('model.subAdmin')
                    }));
                }
            }

        },

        filteredQuizzes: (function () {

            var escape = function escape(term) {
                return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
            };

            var quizzes = this.get('model.quizzes').filterBy('isNew', false).filterBy('isDeleted', false),
                term = this.get('searchTerm'),
                filter = new RegExp(escape(term), 'i'),
                unsorted;

            if (term) {
                unsorted = quizzes.filter(function (quiz) {
                    return filter.test(quiz.get('title'));
                });
            } else {
                unsorted = quizzes;
            }

            return _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                content: unsorted.toArray(),
                sortProperties: ['title'],
                sortAscending: true
            });
        }).property('searchTerm', 'model.quizzes.@each.title')

    });
});