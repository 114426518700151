define('vimme/routes/teacher/resources', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model() {
            var session = this.get('session.content');
            var teacher = this.store.findRecord('teacher', session.id);
            var week0 = _ember['default'].Object.create({
                id: 'none',
                number: 0,
                longName: 'General'
            });
            var localGrades = this.store.all('grade');
            var localWeeks = this.store.all('week');
            var grades = localGrades.get('length') > 0 ? localGrades : this.store.find('grade');
            var weeks = [week0];
            if (localWeeks.get('length')) {
                weeks = weeks.concat(localWeeks.toArray());
            } else {
                weeks = this.store.find('week').then(function (moreWeeks) {
                    return weeks.concat(moreWeeks.toArray());
                });
            }
            return teacher.then(function (teacher) {
                return _ember['default'].RSVP.hash({
                    grade: teacher.get('grade.id'),
                    grades: grades,
                    weeks: weeks
                });
            });
        }
    });
});