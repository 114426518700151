define("vimme/templates/school-admin/reports/question", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 16
              },
              "end": {
                "line": 11,
                "column": 131
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Week View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 16
              },
              "end": {
                "line": 12,
                "column": 139
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Question View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 12
              },
              "end": {
                "line": 33,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [28, 28], [28, 36]]]]], [], []], "optionLabelPath", "content.lastThenFirst", "value", ["subexpr", "@mut", [["get", "selectedTeacher", ["loc", [null, [30, 26], [30, 41]]]]], [], []], "prompt", "Select Teacher"], ["loc", [null, [27, 16], [32, 18]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 34,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "view-choices");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(element20, 1, 1);
          morphs[2] = dom.createMorphAt(element20, 3, 3);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "schoolYears", ["loc", [null, [5, 24], [5, 35]]]]], [], []], "optionLabelPath", "content.name", "value", ["subexpr", "@mut", [["get", "selectedSchoolYear", ["loc", [null, [7, 22], [7, 40]]]]], [], []], "prompt", "Current Year"], ["loc", [null, [4, 12], [9, 14]]]], ["block", "link-to", ["school-admin.reports.week"], ["class", "view-choice"], 0, null, ["loc", [null, [11, 16], [11, 143]]]], ["block", "link-to", ["school-admin.reports.question"], ["class", "view-choice"], 1, null, ["loc", [null, [12, 16], [12, 151]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [15, 24], [15, 36]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedGrade", ["loc", [null, [17, 22], [17, 35]]]]], [], []], "prompt", "Select Grade"], ["loc", [null, [14, 12], [19, 14]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [21, 24], [21, 35]]]]], [], []], "optionLabelPath", "content.longName", "value", ["subexpr", "@mut", [["get", "selectedWeek", ["loc", [null, [23, 22], [23, 34]]]]], [], []], "prompt", "Select Week"], ["loc", [null, [20, 12], [25, 14]]]], ["block", "if", [["get", "showTeachers", ["loc", [null, [26, 18], [26, 30]]]]], [], 2, null, ["loc", [null, [26, 12], [33, 19]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 16
              },
              "end": {
                "line": 38,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "grade-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedGrade.title", ["loc", [null, [37, 46], [37, 69]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 16
              },
              "end": {
                "line": 41,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "week-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedWeek.longName", ["loc", [null, [40, 45], [40, 70]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 16
              },
              "end": {
                "line": 44,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "teacher-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedTeacher.lastThenFirst", ["loc", [null, [43, 48], [43, 81]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 8
            },
            "end": {
              "line": 46,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "print-crumb");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element19, 1, 1);
          morphs[1] = dom.createMorphAt(element19, 2, 2);
          morphs[2] = dom.createMorphAt(element19, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedGrade", ["loc", [null, [36, 22], [36, 35]]]]], [], 0, null, ["loc", [null, [36, 16], [38, 23]]]], ["block", "if", [["get", "selectedWeek", ["loc", [null, [39, 22], [39, 34]]]]], [], 1, null, ["loc", [null, [39, 16], [41, 23]]]], ["block", "if", [["get", "selectedTeacher", ["loc", [null, [42, 22], [42, 37]]]]], [], 2, null, ["loc", [null, [42, 16], [44, 23]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 8
            },
            "end": {
              "line": 50,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Print View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element18);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [49, 34], [49, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 8
            },
            "end": {
              "line": 52,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Report View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element17);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [51, 34], [51, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 20
                },
                "end": {
                  "line": 72,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "cell");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var element10 = dom.childAt(element9, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
              morphs[1] = dom.createElementMorph(element10);
              morphs[2] = dom.createMorphAt(element10, 0, 0);
              return morphs;
            },
            statements: [["content", "studentTest.student.lastThenFirst", ["loc", [null, [69, 46], [69, 83]]]], ["element", "action", ["gotoTestReview", ["get", "studentTest.teacherTest.test.id", ["loc", [null, [70, 70], [70, 101]]]], 1, ["get", "studentTest.id", ["loc", [null, [70, 104], [70, 118]]]]], [], ["loc", [null, [70, 44], [70, 120]]]], ["content", "studentTest.formattedPercent", ["loc", [null, [70, 121], [70, 153]]]]],
            locals: ["studentTest"],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 24
                },
                "end": {
                  "line": 80,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element8, 'class');
              morphs[1] = dom.createElementMorph(element8);
              morphs[2] = dom.createMorphAt(element8, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header ", ["get", "indexedQuestion.shortName", ["loc", [null, [79, 54], [79, 79]]]]]]], ["element", "action", ["sortQuestion", ["get", "indexedQuestion", ["loc", [null, [79, 107], [79, 122]]]]], [], ["loc", [null, [79, 83], [79, 124]]]], ["content", "indexedQuestion.shortName", ["loc", [null, [79, 125], [79, 154]]]]],
            locals: ["indexedQuestion"],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 84,
                    "column": 28
                  },
                  "end": {
                    "line": 86,
                    "column": 28
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                             ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("%");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                         ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element7, 'class');
                morphs[1] = dom.createMorphAt(element7, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedAverage.studentQuestion.color", ["loc", [null, [85, 51], [85, 86]]]]]]], ["content", "paddedAverage.studentQuestion.average", ["loc", [null, [85, 90], [85, 131]]]]],
              locals: ["paddedAverage"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 20
                },
                "end": {
                  "line": 88,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row reports-average-row");
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                     ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "paddedAverages", ["loc", [null, [84, 36], [84, 50]]]]], [], 0, null, ["loc", [null, [84, 28], [86, 37]]]]],
            locals: ["paddedAverages"],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 92,
                      "column": 32
                    },
                    "end": {
                      "line": 98,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/question.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "vimme-report-question", [], ["paddedStudentQuestion", ["subexpr", "@mut", [["get", "paddedStudentQuestion", ["loc", [null, [94, 62], [94, 83]]]]], [], []], "clickMe", "showQuestion", "hoverMe", "setQuickQuestion"], ["loc", [null, [93, 36], [97, 38]]]]],
                locals: ["paddedStudentQuestion"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 90,
                    "column": 24
                  },
                  "end": {
                    "line": 100,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedStudentQuestions", ["loc", [null, [92, 40], [92, 62]]]]], [], 0, null, ["loc", [null, [92, 32], [98, 41]]]]],
              locals: ["paddedStudentQuestions"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 20
                },
                "end": {
                  "line": 101,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [90, 61], [90, 70]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "studentTest.studentQuestions", ["loc", [null, [90, 88], [90, 116]]]]], [], []]], 0, null, ["loc", [null, [90, 24], [100, 52]]]]],
            locals: ["studentTest"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 8
              },
              "end": {
                "line": 104,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header score-column");
            var el5 = dom.createTextNode("Total");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell");
            var el5 = dom.createTextNode("Average");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("%");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header currentSortColumn}}");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1, 1]);
            var element12 = dom.childAt(element11, [1]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element12, [3]);
            var element15 = dom.childAt(element11, [3, 3]);
            var element16 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element12, 'class');
            morphs[1] = dom.createElementMorph(element13);
            morphs[2] = dom.createElementMorph(element14);
            morphs[3] = dom.createAttrMorph(element15, 'class');
            morphs[4] = dom.createMorphAt(element15, 0, 0);
            morphs[5] = dom.createMorphAt(element11, 5, 5);
            morphs[6] = dom.createMorphAt(dom.childAt(element16, [1]), 1, 1);
            morphs[7] = dom.createMorphAt(element16, 3, 3);
            morphs[8] = dom.createMorphAt(element16, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [59, 45], [59, 62]]]]]]], ["element", "action", ["sortOther", "student-column"], [], ["loc", [null, [60, 64], [60, 103]]]], ["element", "action", ["sortOther", "score-column"], [], ["loc", [null, [61, 62], [61, 99]]]], ["attribute", "class", ["concat", ["cell ", ["get", "studentAverageColor", ["loc", [null, [65, 43], [65, 62]]]]]]], ["content", "studentAverage", ["loc", [null, [65, 66], [65, 84]]]], ["block", "each", [["get", "studentTests", ["loc", [null, [67, 28], [67, 40]]]]], [], 0, null, ["loc", [null, [67, 20], [72, 29]]]], ["block", "each", [["get", "indexedQuestions", ["loc", [null, [78, 32], [78, 48]]]]], [], 1, null, ["loc", [null, [78, 24], [80, 33]]]], ["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [82, 57], [82, 66]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "averages", ["loc", [null, [82, 84], [82, 92]]]]], [], []]], 2, null, ["loc", [null, [82, 20], [88, 48]]]], ["block", "each", [["get", "studentTests", ["loc", [null, [89, 28], [89, 40]]]]], [], 3, null, ["loc", [null, [89, 20], [101, 29]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 4
            },
            "end": {
              "line": 105,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "studentTests", ["loc", [null, [56, 14], [56, 26]]]]], [], 0, null, ["loc", [null, [56, 8], [104, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 24
                  },
                  "end": {
                    "line": 116,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "print-item");
                var el2 = dom.createTextNode("Q");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(": ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var element4 = dom.childAt(element3, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3, 1, 1);
                morphs[1] = dom.createAttrMorph(element4, 'class');
                return morphs;
              },
              statements: [["content", "paddedStudentQuestion.num", ["loc", [null, [115, 54], [115, 83]]]], ["attribute", "class", ["concat", [["get", "paddedStudentQuestion.studentQuestion.correctIcon", ["loc", [null, [115, 97], [115, 146]]]]]]]],
              locals: ["paddedStudentQuestion"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 12
                },
                "end": {
                  "line": 119,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "print-row column");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-general");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-name");
              var el4 = dom.createTextNode("Student: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-avg");
              var el4 = dom.createTextNode("Total: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-details");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
              return morphs;
            },
            statements: [["content", "studentTest.student.lastThenFirst", ["loc", [null, [110, 69], [110, 106]]]], ["content", "studentTest.formattedPercent", ["loc", [null, [111, 66], [111, 98]]]], ["block", "each", [["get", "paddedStudentQuestions", ["loc", [null, [114, 32], [114, 54]]]]], [], 0, null, ["loc", [null, [114, 24], [116, 33]]]]],
            locals: ["paddedStudentQuestions"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 8
              },
              "end": {
                "line": 120,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [107, 49], [107, 58]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "studentTest.studentQuestions", ["loc", [null, [107, 76], [107, 104]]]]], [], []]], 0, null, ["loc", [null, [107, 12], [119, 40]]]]],
          locals: ["studentTest"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 4
            },
            "end": {
              "line": 121,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "studentTests", ["loc", [null, [106, 16], [106, 28]]]]], [], 0, null, ["loc", [null, [106, 8], [120, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 125,
              "column": 0
            },
            "end": {
              "line": 138,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dialog question-dialog");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "dialog-inner");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "btn btn-icon-only btn-dialog-exit icon-delete");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-name");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-type");
          var el4 = dom.createTextNode("Week ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(": Question ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-message test-question");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createMorphAt(element2, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["hideQuestion"], [], ["loc", [null, [129, 11], [129, 36]]]], ["content", "openQuestion.studentQuestion.student.lastThenFirst", ["loc", [null, [130, 33], [130, 87]]]], ["content", "openQuestion.studentQuestion.question.test.week.id", ["loc", [null, [131, 38], [131, 92]]]], ["content", "openQuestion.num", ["loc", [null, [131, 103], [131, 123]]]], ["inline", "vimme-test-question", [], ["question", ["subexpr", "@mut", [["get", "openQuestion.studentQuestion.question", ["loc", [null, [133, 43], [133, 80]]]]], [], []], "studentQuestion", ["subexpr", "@mut", [["get", "openQuestion.studentQuestion", ["loc", [null, [133, 97], [133, 125]]]]], [], []], "isReviewing", true], ["loc", [null, [133, 12], [133, 144]]]]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 140,
              "column": 0
            },
            "end": {
              "line": 148,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-quick-question", [], ["studentQuestion", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion", ["loc", [null, [142, 24], [142, 53]]]]], [], []], "question", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion.question", ["loc", [null, [143, 17], [143, 55]]]]], [], []], "answers", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion.question.answers", ["loc", [null, [144, 16], [144, 62]]]]], [], []], "alignLeft", ["subexpr", "@mut", [["get", "alignLeft", ["loc", [null, [145, 18], [145, 27]]]]], [], []], "alignTop", ["subexpr", "@mut", [["get", "alignTop", ["loc", [null, [146, 17], [146, 25]]]]], [], []]], ["loc", [null, [141, 4], [147, 6]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 149,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/school-admin/reports/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element21 = dom.childAt(fragment, [0]);
        var element22 = dom.childAt(element21, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element22, 1, 1);
        morphs[1] = dom.createMorphAt(element22, 3, 3);
        morphs[2] = dom.createMorphAt(element21, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "print", ["loc", [null, [3, 18], [3, 23]]]]], [], 0, 1, ["loc", [null, [3, 8], [46, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [48, 18], [48, 23]]]]], [], 2, 3, ["loc", [null, [48, 8], [52, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [55, 14], [55, 19]]]]], [], 4, 5, ["loc", [null, [55, 4], [121, 15]]]], ["block", "if", [["get", "showQuestion", ["loc", [null, [125, 6], [125, 18]]]]], [], 6, null, ["loc", [null, [125, 0], [138, 7]]]], ["block", "if", [["get", "quickQuestion", ["loc", [null, [140, 6], [140, 19]]]]], [], 7, null, ["loc", [null, [140, 0], [148, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});