define('vimme/components/vimme-quiz-editor/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-quiz-editor'],
        classNameBindings: ['addVisible'],

        // inputs
        quiz: null,
        categories: [],
        difficulties: [],
        grades: [],
        isNew: false,
        addVisible: false,
        userType: 'teacher',

        currentQuizQuestion: null,
        alignLeft: 0,
        alignTop: 0,

        slipList: null,

        currentIndex: (function () {
            return this.get('quiz.quizQuestions').indexOf(this.get('currentQuizQuestion'));
        }).property('currentQuizQuestion'),

        isPrintable: _ember['default'].computed.bool('quiz.quizQuestions.length'),
        isNotPrintable: _ember['default'].computed.not('isPrintable'),

        isCancelable: (function () {
            var isNew = this.get('isNew');
            return isNew ? this.get('isPrintable') || this.get('quiz.title') : true;
        }).property('isNew', 'isPrintable', 'quiz.title'),
        isNotCancelable: _ember['default'].computed.not('isCancelable'),

        isSavable: _ember['default'].computed.and('isPrintable', 'quiz.title'),
        isNotSavable: _ember['default'].computed.not('isSavable'),

        beforeReorder: null,
        beforeSwipe: null,
        beforeWait: null,
        reorder: null,

        componentDidMount: (function () {
            var _this = this;

            var list = this.$().find('.sortable-list').get(0);

            this.beforeReorder = function (e) {
                if (/quiz-builder__question-reorder/.test(e.target.className) === false) {
                    e.preventDefault();
                }
            };
            this.beforeSwipe = function (e) {
                e.preventDefault();
            };
            this.beforeWait = function (e) {
                e.preventDefault();
            };
            this.reorder = function (e) {
                var items = _this.get('quiz.quizQuestions');
                var item = items.objectAt(e.detail.originalIndex);

                _this.beginPropertyChanges();
                items.removeObject(item);
                items.insertAt(e.detail.spliceIndex, item);
                _this.endPropertyChanges();
            };

            list.addEventListener('slip:beforereorder', this.beforeReorder, false);
            list.addEventListener('slip:beforeswipe', this.beforeSwipe, false);
            list.addEventListener('slip:beforewait', this.beforeWait, false);
            list.addEventListener('slip:reorder', this.reorder, false);

            this.set('slipList', new Slip(list));
        }).on('didInsertElement'),

        componentWillUnmount: (function () {

            var list = this.$().find('.sortable-list').get(0);

            list.removeEventListener('slip:beforereorder', this.beforeRorder);
            list.removeEventListener('slip:beforeswipe', this.beforeSwipe);
            list.removeEventListener('slip:beforewait', this.beforeWait);
            list.removeEventListener('slip:reorder', this.reorder);

            this.get('slipList').detach();
            this.set('slipList', null);
        }).on('willDestroyElement'),

        actions: {
            cancel: function cancel() {
                this.sendAction('cancel');
            },
            save: function save() {
                this.sendAction('save', this.get('quiz'));
            },
            remove: function remove(quizQuestion) {
                this.get('quiz.quizQuestions').removeObject(quizQuestion);
            },
            print: function print() {
                var quizId = this.get('quiz.id') || 'new';
                var userType = this.get('userType');
                var quizQuestionIds = this.get('quiz.quizQuestions').map(function (quizQuestion) {
                    return quizQuestion.get('id');
                }).join();
                window.open('/' + userType + '/quizzes/' + quizId + '/' + quizQuestionIds, '_blank');
            },
            togglePreview: function togglePreview(quizQuestion, element) {
                var currentQuizQuestion = this.get('currentQuizQuestion');
                if (currentQuizQuestion && currentQuizQuestion.get('id') === quizQuestion.get('id')) {
                    this.set('currentQuizQuestion', null);
                } else {
                    var rect = _ember['default'].$(element).get(0).getBoundingClientRect();
                    this.set('alignLeft', rect.left - 260);
                    this.set('alignTop', rect.top - 3);
                    this.set('currentQuizQuestion', quizQuestion);
                }
            },
            showAdd: function showAdd() {
                this.sendAction('showAdd');
            }
        }

    });
});