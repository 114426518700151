define('vimme/components/vimme-question-view-row', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: '',

        questions: null,
        studentQuestions: null,

        paddedStudentQuestions: (function () {

            var studentQuestions = this.get('studentQuestions'),
                questions = this.get('questions');

            if (!questions) {
                return;
            }

            return questions.map(function (question, num) {
                var studentQuestion = studentQuestions.find(function (studentQuestion) {
                    return studentQuestion.get('question.id') === question.get('id');
                });

                return { studentQuestion: studentQuestion || null, num: num + 1 };
            });
        }).property('studentQuestions')

    });
});