define('vimme/routes/teacher/reports/week', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model() {

            return _ember['default'].RSVP.hash({
                weeks: this.modelFor('teacher.reports').weeks,
                teacherAveragesRaw: this.store.find('perTeacherAverage'),
                studentTests: this.store.find('studentTest')
            });
        }
    });
});