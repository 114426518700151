define('vimme/routes/teacher/quiz-builder', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model() {
            var localDifficulties = this.store.all('difficulty');
            var localGrades = this.store.all('grade');
            var teacher = this.modelFor('teacher').user;
            return _ember['default'].RSVP.hash({
                categories: this.store.find('category'),
                difficulties: localDifficulties.get('length') > 0 ? localDifficulties : this.store.find('difficulty'),
                grades: localGrades.get('length') > 0 ? localGrades : this.store.find('grade'),
                quizzes: teacher.get('quizzes'),
                teacher: teacher
            });
        }
    });
});