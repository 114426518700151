define('vimme/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        hideHeader: false,

        referenceSheet: (function () {

            var grade = this.get('session.grade');

            if (grade >= 4 && grade <= 8) {
                return this.assets.resolve('assets/reference-sheets/istep-grade-' + grade + '-reference-sheet.pdf');
            }
        }).property('session.grade'),

        showEula: (function () {

            var authenticated = this.get('session.isAuthenticated'),
                role = this.get('session.role'),
                eula = this.get('session.eula');

            return authenticated && role !== 'Student' && !eula;
        }).property('session.{isAuthenticated,role,eula}'),

        actions: {

            agree: function agree() {

                var _this = this,
                    user = this.store.find(this.get('session.role').dasherize(), this.get('session.id'));

                user.then(function (user) {
                    user.set('eula', true);
                    user.save().then(function (user) {
                        _this.set('session.eula', user.get('eula'));
                    });
                });
            }

        }

    });
});