define("vimme/models/school-year-grade-report", ["exports", "ember-data", "vimme/utils/round"], function (exports, _emberData, _vimmeUtilsRound) {
    exports["default"] = _emberData["default"].Model.extend({
        average: _emberData["default"].attr('number'),

        grade: _emberData["default"].belongsTo('grade', { async: true }),
        schoolYear: _emberData["default"].belongsTo('schoolYear', { async: true }),

        schoolYearWeeklyReports: _emberData["default"].hasMany('schoolYearWeeklyReport', { async: false }),

        formattedPercent: (function () {
            var percent = this.get('average');
            if (percent) {
                return (0, _vimmeUtilsRound["default"])(percent) + '%';
            }
        }).property('average'),

        color: (function () {
            var percent = this.get('average');
            if (percent >= 85) {
                return 'benchmark';
            } else if (percent >= 63) {
                return 'strategic';
            } else if (percent) {
                return 'intensive';
            }
        }).property('average')
    });
});