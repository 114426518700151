define('vimme/routes/info', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('application').set('hideLogin', true);
        },

        deactivate: function deactivate() {
            this.controllerFor('application').set('hideLogin', false);
        }

    });
});