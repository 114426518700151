define("vimme/models/school-year-weekly-report", ["exports", "ember-data", "vimme/utils/round"], function (exports, _emberData, _vimmeUtilsRound) {
    exports["default"] = _emberData["default"].Model.extend({
        average: _emberData["default"].attr('number'),

        week: _emberData["default"].belongsTo('week', { async: true }),
        schoolYearGradeReport: _emberData["default"].belongsTo('schoolYearGradeReport', { async: true }),

        formattedPercent: (function () {
            var percent = this.get('average');
            if (percent) {
                return (0, _vimmeUtilsRound["default"])(percent) + '%';
            }
        }).property('average'),

        color: (function () {
            var percent = this.get('average');
            if (percent >= 85) {
                return 'benchmark';
            } else if (percent >= 63) {
                return 'strategic';
            } else if (percent) {
                return 'intensive';
            }
        }).property('average')
    });
});