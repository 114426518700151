define('vimme/controllers/school-admin/management/students/student', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        showDialogStudent: false,
        showDialogScore: false,
        showDialogTransfer: false,
        showDialogPassword: false,

        password: '',
        passwordConfirmation: '',

        selectedScore: null,

        noSelectedScore: _ember['default'].computed.not('selectedScore'),

        teacher: null,

        teachersByGrade: _ember['default'].computed.filter('model.teachers', function (teacher) {
            return teacher.get('grade.id') === this.get('model.student.grade.id') && !teacher.get('isPlaceholder');
        }),

        setTeacher: (function () {

            var teachers = this.get('model.teachers');
            var student = this.get('model.student');

            if (teachers) {

                var teacher = teachers.filterBy('isPlaceholder', false).findBy('id', student.get('teacher.id'));
                if (!teacher) {
                    teacher = teachers.filterBy('grade.id', student.get('grade.id')).get('firstObject');
                }
                this.set('teacher', teacher);
            }
        }).on('init').observes('model.{student.teacher.id,teachers}'),

        isClean: _ember['default'].computed.not('model.student.isDirty'),

        studentTestSorting: ['teacherTest.startDate'],
        sortedStudentTests: _ember['default'].computed.sort('model.student.studentTests', 'studentTestSorting'),

        inProgress: _ember['default'].computed.filterBy('model.student.studentTests', 'isComplete', false),

        resetSelectedScore: (function () {
            this.set('selectedScore', null);
        }).on('init').observes('model.id'),

        validPassword: _ember['default'].computed('password', 'passwordConfirmation', function () {
            var password = this.get('password'),
                passwordConfirmation = this.get('passwordConfirmation');

            return !!password && password.length >= 5 && password === passwordConfirmation;
        }),
        invalidPassword: _ember['default'].computed.not('validPassword'),

        isValid: (function () {

            var firstName = (this.get('model.student.firstName') + '').trim(),
                lastName = (this.get('model.student.lastName') + '').trim();

            return !!firstName && !!lastName;
        }).property('model.student.{firstName,lastName}'),

        isUnsavable: (function () {

            return this.get('isClean') || !this.get('isValid');
        }).property('isClean', 'isValid'),

        actions: {

            showDialogStudent: function showDialogStudent() {
                this.set('showDialogStudent', true);
            },

            cancelDialogStudent: function cancelDialogStudent() {
                this.set('showDialogStudent', false);
            },

            showDialogScore: function showDialogScore() {
                this.set('showDialogScore', true);
            },

            cancelDialogScore: function cancelDialogScore() {
                this.set('showDialogScore', false);
            },

            showDialogTransfer: function showDialogTransfer() {
                this.set('showDialogTransfer', true);
            },

            cancelDialogTransfer: function cancelDialogTransfer() {
                this.set('showDialogTransfer', false);
            },

            showDialogPassword: function showDialogPassword() {
                this.set('showDialogPassword', true);
            },

            cancelDialogPassword: function cancelDialogPassword() {
                this.set('showDialogPassword', false);
            },

            setSelected: function setSelected(score) {
                this.set('selectedScore', score);
            },

            save: function save(student) {
                student.save();
            },

            'delete': function _delete(student) {
                var _this = this;

                student.destroyRecord().then(function () {
                    _this.set('showDialogStudent', false);
                    _this.transitionToRoute('school-admin.management.students');
                })['catch'](function () {
                    student.rollback();
                });
            },

            unassign: function unassign(student) {
                var _this2 = this;

                _ember['default'].$.ajax({
                    type: 'POST',
                    url: _vimmeConfigEnvironment['default'].API.host + '/unassigned-transfers',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        unassigned_transfer: {
                            student_id: student.get('id')
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function (student) {
                    _this2.store.pushPayload(student);
                    _this2.store.find('student', student.student.id).then(function (student) {
                        return student.get('studentTests').reload();
                    });
                    _this2.set('showDialogStudent', false);
                    _this2.set('password', '');
                    _this2.set('passwordConfirmation', '');
                });
            },

            deleteScore: function deleteScore(selectedTest) {
                var _this3 = this;

                selectedTest.destroyRecord().then(function () {
                    _this3.set('selectedScore', null);
                    _this3.set('showDialogScore', false);
                })['catch'](function () {
                    selectedTest.rollback();
                });
            },

            changePassword: function changePassword(password, passwordConfirmation) {
                var _this4 = this;

                _ember['default'].$.ajax({
                    type: 'PUT',
                    url: _vimmeConfigEnvironment['default'].API.host + '/students/' + this.get('model.student.id') + '/password',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        student: {
                            password: password,
                            password_confirmation: passwordConfirmation
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function (student) {
                    _this4.store.pushPayload(student);
                    _this4.set('showDialogPassword', false);
                    _this4.set('password', '');
                    _this4.set('passwordConfirmation', '');
                });
            },

            transfer: function transfer(teacher) {
                var _this5 = this;

                _ember['default'].$.ajax({
                    type: 'POST',
                    url: _vimmeConfigEnvironment['default'].API.host + '/student-transfers',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        student_transfer: {
                            student_id: this.get('model.student.id'),
                            teacher_id: teacher.get('id')
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function (student) {
                    _this5.store.pushPayload(student);
                    _this5.store.find('student', student.student.id).then(function (student) {
                        return student.get('studentTests').reload();
                    });
                    _this5.set('showDialogTransfer', false);
                    _this5.set('password', '');
                    _this5.set('passwordConfirmation', '');
                });
            }

        }

    });
});