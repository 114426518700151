define('vimme/components/vimme-week-view-row', ['exports', 'ember', 'vimme/utils/round'], function (exports, _ember, _vimmeUtilsRound) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: '',

        paddedWeeklyAverages: (function () {

            var weeklyAverages = this.get('average.weeklyAverages'),
                weeks = this.get('weeks');

            return weeks.map(function (week, num) {
                var weeklyAverage = weeklyAverages.find(function (weeklyAverage) {
                    if (weeklyAverage.get('test')) {
                        return weeklyAverage.get('test.week.number') === week.get('number');
                    } else if (weeklyAverage.get('teacherTest')) {
                        return weeklyAverage.get('teacherTest.test.week.number') === week.get('number');
                    }
                });

                return { average: weeklyAverage || null, num: num + 1 };
            });
        }).property('average.weeklyAverages'),

        percentages: _ember['default'].computed.mapBy('average.weeklyAverages', 'percent'),

        percentagesSum: _ember['default'].computed.sum('percentages'),

        trend: (function () {

            var percentages = this.get('percentages'),
                rest = percentages.slice(0, percentages.get('length') - 1),
                tail = percentages.slice(percentages.get('length') - 1, percentages.get('length'))[0],
                avg,
                sum;

            if (rest.get('length') === 0) {
                return 'icon-same';
            }

            sum = rest.reduce(function (last, next) {
                return last + next;
            }, 0);
            avg = sum / rest.get('length');

            if (avg === tail) {
                return 'icon-same';
            } else if (avg > tail) {
                return 'icon-down';
            } else {
                return 'icon-up';
            }
        }).property('percentages'),

        percent: (function () {

            return this.get('percentagesSum') / this.get('average.weeklyAverages.length');
        }).property('average.weeklyAverages.length', 'percentagesSum'),

        formattedPercent: (function () {

            return (0, _vimmeUtilsRound['default'])(this.get('percent')) + '%';
        }).property('percent'),

        color: (function () {

            var percent = (0, _vimmeUtilsRound['default'])(this.get('percent'));

            if (percent >= 85) {
                return 'benchmark';
            } else if (percent >= 63) {
                return 'strategic';
            } else {
                return 'intensive';
            }
        }).property('percent')

    });
});