define('vimme/controllers/teacher/management/student', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        showDialogStudent: false,
        showDialogScore: false,
        showDialogPassword: false,

        password: '',
        passwordConfirmation: '',

        selectedScore: null,

        noSelectedScore: _ember['default'].computed.not('selectedScore'),

        gradeNum: _ember['default'].computed.oneWay('model.grade.id'),

        isClean: _ember['default'].computed.not('model.isDirty'),

        validPassword: _ember['default'].computed('password', 'passwordConfirmation', function () {
            var password = this.get('password'),
                passwordConfirmation = this.get('passwordConfirmation');

            return !!password && password.length >= 5 && password === passwordConfirmation;
        }),
        invalidPassword: _ember['default'].computed.not('validPassword'),

        studentTestSorting: ['teacherTest.startDate'],
        sortedStudentTests: _ember['default'].computed.sort('model.studentTests', 'studentTestSorting'),

        resetSelectedScore: (function () {
            this.set('selectedScore', null);
        }).on('init').observes('model.id'),

        isValid: (function () {

            var firstName = this.get('model.firstName').trim(),
                lastName = this.get('model.lastName').trim(),
                gradeNum = this.get('model.grade.id');

            return !!firstName && !!lastName && !!gradeNum;
        }).property('model.{firstName,middleInitial,lastName,grade}'),

        isUnsavable: (function () {

            return this.get('isClean') || !this.get('isValid');
        }).property('isClean', 'isValid'),

        updateGrade: (function () {

            var gradeNum = this.get('gradeNum'),
                grade = this.get('model.grade'),
                _this = this;

            if (gradeNum && gradeNum !== grade.get('id')) {

                this.store.find('grade', gradeNum).then(function (grade) {

                    _this.set('model.grade', grade);

                    _this.get('model').send('becomeDirty');
                })['catch'](function () {

                    _this.set('gradeNum', grade.get('id'));
                });
            }
        }).observes('gradeNum'),

        actions: {

            showDialogStudent: function showDialogStudent() {
                this.set('showDialogStudent', true);
            },

            cancelDialogStudent: function cancelDialogStudent() {
                this.set('showDialogStudent', false);
            },

            showDialogScore: function showDialogScore() {
                this.set('showDialogScore', true);
            },

            cancelDialogScore: function cancelDialogScore() {
                this.set('showDialogScore', false);
            },

            showDialogPassword: function showDialogPassword() {
                this.set('showDialogPassword', true);
            },

            cancelDialogPassword: function cancelDialogPassword() {
                this.set('showDialogPassword', false);
            },

            setSelected: function setSelected(score) {
                this.set('selectedScore', score);
            },

            save: function save(student) {

                student.save();
            },

            'delete': function _delete(student) {
                var _this2 = this;

                student.destroyRecord().then(function () {
                    _this2.set('showDialogStudent', false);
                    _this2.transitionToRoute('teacher.management');
                })['catch'](function () {
                    student.rollback();
                });
            },

            unassign: function unassign(student) {
                var _this3 = this;

                _ember['default'].$.ajax({
                    type: 'POST',
                    url: _vimmeConfigEnvironment['default'].API.host + '/unassigned-transfers',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        unassigned_transfer: {
                            student_id: student.get('id')
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function () {
                    student.get('studentTests').then(function (studentTests) {
                        studentTests.invoke('deleteRecord');
                        _ember['default'].run.later(function () {
                            _this3.set('showDialogStudent', false);
                            _this3.set('password', '');
                            _this3.set('passwordConfirmation', '');
                            _this3.transitionToRoute('teacher.management');
                        });
                    });
                    student.deleteRecord();
                });
            },

            deleteScore: function deleteScore(selectedTest) {
                var _this4 = this;

                selectedTest.destroyRecord().then(function () {
                    _this4.set('selectedScore', null);
                    _this4.set('showDialogScore', false);
                })['catch'](function () {
                    selectedTest.rollback();
                });
            },

            changePassword: function changePassword(password, passwordConfirmation) {
                var _this5 = this;

                _ember['default'].$.ajax({
                    type: 'PUT',
                    url: _vimmeConfigEnvironment['default'].API.host + '/students/' + this.get('model.id') + '/password',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        student: {
                            password: password,
                            password_confirmation: passwordConfirmation
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function (student) {
                    _this5.store.pushPayload(student);
                    _this5.set('showDialogPassword', false);
                    _this5.set('password', '');
                    _this5.set('passwordConfirmation', '');
                });
            }

        }

    });
});