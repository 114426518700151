define('vimme/routes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel() {
            var session = this.get('session.content');

            if (session) {
                if (session.role === 'Admin') {
                    this.transitionTo('admin.management');
                } else if (session.role === 'SchoolAdmin') {
                    this.transitionTo('school-admin.schedule-tests');
                } else if (session.role === 'SubAdmin') {
                    this.transitionTo('sub-admin.reports.week');
                } else if (session.role === 'Teacher') {
                    this.transitionTo('teacher.testing-calendar');
                } else if (session.role === 'Student') {
                    this.transitionTo('student.testing-calendar');
                }
            }
        },
        activate: function activate() {
            _ember['default'].$('body').addClass('index-page');
        },
        deactivate: function deactivate() {
            _ember['default'].$('body').removeClass('index-page');
        }

    });
});