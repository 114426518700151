define('vimme/routes/admin/builder/resources/resource', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model(params) {
            var localGrades = this.store.all('grade');
            var localWeeks = this.store.all('week');
            return _ember['default'].RSVP.hash({
                resource: this.store.find('resource', params.resource_id),
                grades: localGrades.get('length') > 0 ? localGrades : this.store.find('grade'),
                weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.find('week')
            });
        }
    });
});