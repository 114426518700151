define('vimme/components/vimme-calendar-month-header/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-calendar-month-header'],

        month: null,
        format: 'MMMM YYYY',

        title: (function () {

            var month = this.get('month'),
                format = this.get('format');

            return moment(month).format(format);
        }).property('month', 'format'),

        actions: {

            prev: function prev() {
                this.get('calendar').prevMonth();
            },

            next: function next() {
                this.get('calendar').nextMonth();
            }

        }

    });
});