define('vimme/controllers/school-admin/management/sub-admins', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        needs: ['application', 'school-admin/management/sub-admins/index'],

        indexController: _ember['default'].computed.alias('controllers.school-admin/management/sub-admins/index'),

        actions: {

            newSubAdmin: function newSubAdmin() {

                if (this.get('controllers.application.currentRouteName') !== 'school-admin.management.sub-admins.index') {

                    this.transitionToRoute('school-admin.management.sub-admins');
                }

                var index = this.get('indexController');

                index.set('lastName', '');
                index.set('firstName', '');
                index.set('email', '');
                index.set('password', '');
            }

        },

        filteredSubAdmins: (function () {

            var escape = function escape(term) {

                return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
            };

            var subAdmins = this.get('model'),
                term = this.get('searchTerm'),
                filter = new RegExp(escape(term), 'i'),
                unsorted;

            if (subAdmins) {

                if (term) {

                    unsorted = subAdmins.filter(function (subAdmin) {
                        return filter.test(subAdmin.get('fullName'));
                    });
                } else {

                    unsorted = subAdmins;
                }

                return _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                    content: unsorted.toArray(),
                    sortProperties: ['lastName'],
                    sortAscending: true
                });
            }

            return subAdmins;
        }).property('searchTerm', 'model.@each')

    });
});