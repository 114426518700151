define('vimme/controllers/school-admin/management/teachers', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['application', 'school-admin/management/teachers/index'],

    indexController: _ember['default'].computed.alias('controllers.school-admin/management/teachers/index'),

    actions: {
      newTeacher: function newTeacher() {
        if (this.get('controllers.application.currentRouteName') !== 'school-admin.management.teachers.index') {
          this.transitionToRoute('school-admin.management.teachers');
        }

        var index = this.get('indexController');

        index.set('lastName', '');
        index.set('firstName', '');
        index.set('grade', null);
        index.set('email', '');
        index.set('password', '');
      }
    },

    filteredTeachers: (function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var teachers = this.get('model.teachers').filterBy('isPlaceholder', false),
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          unsorted;

      if (teachers) {
        if (term) {
          unsorted = teachers.filter(function (teacher) {
            return filter.test(teacher.get('fullName'));
          });
        } else {
          unsorted = teachers;
        }

        return _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
          content: unsorted.toArray(),
          sortProperties: ['lastName'],
          sortAscending: true
        });
      }

      return teachers;
    }).property('searchTerm', 'model.teachers.@each')
  });
});