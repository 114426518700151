define('vimme/routes/teacher/questions', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        beforeModel: function beforeModel(transition) {

            this._super(transition);

            if (transition.targetName === 'teacher.questions.index') {

                if (transition.queryParams && transition.queryParams['test-review-id']) {

                    this.replaceWith('teacher.questions.question', 1, { queryParams: { testReviewID: transition.queryParams['test-review-id'] } });
                } else {

                    this.replaceWith('teacher.questions.question', 1);
                }
            }
        },
        model: function model() {
            var _this = this;

            return _ember['default'].RSVP.hash({
                studentTests: _this.modelFor('teacher.test').get('studentTests'),
                questions: _this.modelFor('teacher.test').get('questions'),
                answers: _this.modelFor('teacher.test').get('answers')
            });
        },
        afterModel: function afterModel(model) {

            if (model.questions.get('length') === 0) {

                this.replaceWith('teacher');
            }
        }
    });
});