define('vimme/routes/teacher/quiz-builder/index', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model() {
            var teacher = this.modelFor('teacher.quiz-builder').teacher;
            return _ember['default'].RSVP.hash({
                categories: this.modelFor('teacher.quiz-builder').categories,
                difficulties: this.modelFor('teacher.quiz-builder').difficulties,
                grades: this.modelFor('teacher.quiz-builder').grades,
                quiz: this.store.createRecord('quiz', {
                    title: null,
                    teacher: teacher
                }),
                teacher: teacher
            });
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
            }
        }
    });
});