define('vimme/controllers/admin/management', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        needs: ['application', 'admin/management/index'],

        actions: {

            newSchoolAdmin: function newSchoolAdmin() {

                if (this.get('controllers.application.currentRouteName') !== 'admin.management.index') {

                    this.transitionToRoute('admin.management');
                }

                var index = this.get('controllers.admin/management/index');

                index.set('lastName', '');
                index.set('firstName', '');
                index.set('school', null);
                index.set('username', '');
                index.set('password', '');
            }

        },

        filteredSchoolAdmins: (function () {

            var escape = function escape(term) {

                return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
            };

            var schoolAdmins = this.get('model.schoolAdmins'),
                term = this.get('searchTerm'),
                filter = new RegExp(escape(term), 'i'),
                unsorted;

            if (schoolAdmins) {

                if (term) {

                    unsorted = schoolAdmins.filter(function (schoolAdmin) {
                        return filter.test(schoolAdmin.get('fullName'));
                    });
                } else {

                    unsorted = schoolAdmins;
                }

                return _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                    content: unsorted.toArray(),
                    sortProperties: ['lastName'],
                    sortAscending: true
                });
            }

            return schoolAdmins;
        }).property('searchTerm', 'model.schoolAdmins.@each')

    });
});