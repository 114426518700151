define('vimme/components/answer-input', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].TextField.extend({

        grade: 1,

        type: 'text',

        allowedRegEx: /[-., \/0-9]/,

        allowedOther: ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'],

        didInsertElement: function didInsertElement() {
            var _this = this;

            if (parseInt(this.get('grade'), 10) <= 6) {
                this.$().keypress(function (e) {

                    var character = String.fromCharCode(e.which);

                    if (!character.match(_this.get('allowedRegEx')) && _this.get('allowedOther').indexOf(e.key) < 0) {
                        return false;
                    }
                });
            }
        }

    });
});