define('vimme/initializers/simple-auth-oauth2', ['exports', 'simple-auth-oauth2/configuration', 'simple-auth-oauth2/authenticators/oauth2', 'simple-auth-oauth2/authorizers/oauth2', 'vimme/config/environment'], function (exports, _simpleAuthOauth2Configuration, _simpleAuthOauth2AuthenticatorsOauth2, _simpleAuthOauth2AuthorizersOauth2, _vimmeConfigEnvironment) {
  exports['default'] = {
    name: 'simple-auth-oauth2',
    before: 'simple-auth',
    initialize: function initialize(container, application) {
      _simpleAuthOauth2Configuration['default'].load(container, _vimmeConfigEnvironment['default']['simple-auth-oauth2'] || {});
      container.register('simple-auth-authorizer:oauth2-bearer', _simpleAuthOauth2AuthorizersOauth2['default']);
      container.register('simple-auth-authenticator:oauth2-password-grant', _simpleAuthOauth2AuthenticatorsOauth2['default']);
    }
  };
});