define('vimme/controllers/school-admin/management/sub-admins/sub-admin', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        showDialog: false,
        showDialogPassword: false,

        password: '',
        passwordConfirmation: '',

        validPassword: _ember['default'].computed('password', 'passwordConfirmation', function () {
            var password = this.get('password'),
                passwordConfirmation = this.get('passwordConfirmation');

            return !!password && password.length >= 5 && password === passwordConfirmation;
        }),
        invalidPassword: _ember['default'].computed.not('validPassword'),

        isClean: _ember['default'].computed.not('model.isDirty'),

        isValid: (function () {

            var firstName = (this.get('model.firstName') + '').trim(),
                lastName = (this.get('model.lastName') + '').trim(),
                username = (this.get('model.username') + '').trim();

            return !!firstName && !!lastName && !!username;
        }).property('model.{firstName,lastName,username}'),

        isUnsavable: (function () {

            return this.get('isClean') || !this.get('isValid');
        }).property('isClean', 'isValid'),

        actions: {

            showDialog: function showDialog() {
                this.set('showDialog', true);
            },

            cancelDialog: function cancelDialog() {
                this.set('showDialog', false);
            },

            showDialogPassword: function showDialogPassword() {
                this.set('showDialogPassword', true);
            },

            cancelDialogPassword: function cancelDialogPassword() {
                this.set('showDialogPassword', false);
            },

            save: function save(subAdmin) {

                subAdmin.set('username', subAdmin.get('email'));

                subAdmin.save();
            },

            'delete': function _delete(subAdmin) {
                var _this = this;

                subAdmin.destroyRecord().then(function () {
                    _this.set('showDialog', false);
                    _this.transitionToRoute('school-admin.management.sub-admins');
                })['catch'](function () {
                    subAdmin.rollback();
                });
            },

            changePassword: function changePassword(password, passwordConfirmation) {
                var _this2 = this;

                _ember['default'].$.ajax({
                    type: 'PUT',
                    url: _vimmeConfigEnvironment['default'].API.host + '/sub-admins/' + this.get('model.id') + '/password',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        sub_admin: {
                            password: password,
                            password_confirmation: passwordConfirmation
                        }
                    })
                }).fail(function (error) {

                    var message = _ember['default'].get(error, 'responseJSON.message');

                    if (message) {
                        alert(message);
                    } else {
                        alert('An error has occurred.');
                    }
                }).done(function (subAdmin) {
                    _this2.store.pushPayload(subAdmin);
                    _this2.set('showDialogPassword', false);
                    _this2.set('password', '');
                    _this2.set('passwordConfirmation', '');
                });
            }

        }

    });
});