define('vimme/components/vimme-progress-reports-student-test/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        // external
        studentTest: null,

        firstMissed: 1,

        teacherTest: _ember['default'].computed.alias('studentTest.teacherTest'),

        isComplete: _ember['default'].computed.alias('studentTest.isComplete'),

        isReviewable: (function () {

            return this.get('teacherTest.isReviewable') && this.get('teacherTest.isViewable') && this.get('isComplete');
        }).property('teacherTest.{isReviewable,isViewable}', 'isComplete'),

        findFirstMissed: (function () {

            var isReviewable = this.get('isReviewable'),
                studentQuestions,
                firstMissed = 1,
                _this = this;

            if (isReviewable) {
                studentQuestions = this.get('studentTest.studentQuestions');
            }

            if (studentQuestions) {

                studentQuestions.then(function (studentQuestions) {

                    studentQuestions.sortBy('question.id').find(function (question) {

                        if (!question.get('isCorrect')) {
                            return true;
                        }

                        firstMissed++;

                        return false;
                    });

                    if (firstMissed > studentQuestions.get('length')) {
                        _this.set('firstMissed', 1);
                    } else {
                        _this.set('firstMissed', firstMissed);
                    }
                });
            }
        }).on('init').observes('studentTest', 'isReviewable'),

        actions: {
            gotoReview: function gotoReview(id, queryParam, model) {
                this.send('gotoTestReview', id, this.get('firstMissed'), queryParam, model);
            }
        }

    });
});