define('vimme/models/answer', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        title: _emberData['default'].attr('string'),
        isCorrect: _emberData['default'].attr('boolean', { defaultValue: false }),

        question: _emberData['default'].belongsTo('question', { async: true }),

        createdAt: _emberData['default'].attr('isodate'),
        updatedAt: _emberData['default'].attr('isodate')
    });
});