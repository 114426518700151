define('vimme/routes/admin/builder/tests/test/questions/new', ['exports', 'vimme/routes/admin'], function (exports, _vimmeRoutesAdmin) {
    exports['default'] = _vimmeRoutesAdmin['default'].extend({
        model: function model() {
            return this.store.createRecord('question', {
                test: this.modelFor('admin.builder.tests.test').test,
                questionType: 'normal'
            });
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                this.store.all('question').filterBy('id', null).invoke('deleteRecord');
            }
        }
    });
});