define('vimme/components/vimme-button/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'button',
        classNames: ['vimme-button'],

        attributeBindings: ['disabled'],

        click: function click() {
            this.sendAction('action', this.get('model'));
        }
    });
});