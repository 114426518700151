define('vimme/components/vimme-search-item', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: 'li',

        classNames: ['vimme-search-item'],

        classNameBindings: ['focused'],

        attributeBindings: ['tabindex'],

        tabindex: -1,

        search: null,

        focused: (function () {

            var focused = this.get('search.focused');

            return focused === this;
        }).property('search.focused'),

        focusOnMouseenter: (function () {

            this.get('search').focusItem(this);
        }).on('mouseEnter'),

        select: (function () {

            this.sendAction('action', this.get('item'));
            this.get('search').set('inputValue', '');
        }).on('click'),

        register: (function () {

            this.get('search').registerItem(this);
        }).on('willInsertElement'),

        deregister: (function () {

            this.get('search').deregisterItem(this);
        }).on('willDestroyElement')

    });
});