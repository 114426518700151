define('vimme/routes/teacher/quiz-builder/quiz', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
    exports['default'] = _vimmeRoutesTeacher['default'].extend({
        model: function model(params) {
            return _ember['default'].RSVP.hash({
                categories: this.modelFor('teacher.quiz-builder').categories,
                difficulties: this.modelFor('teacher.quiz-builder').difficulties,
                grades: this.modelFor('teacher.quiz-builder').grades,
                quiz: this.store.find('quiz', params.quiz_id),
                teacher: this.modelFor('teacher.quiz-builder').teacher
            });
        },
        resetController: function resetController(controller) {
            var quiz = controller.get('model.quiz');
            var quizQuestions = quiz.get('quizQuestions');
            quiz.rollback();
            quizQuestions.reload();
        }
    });
});