define('vimme/components/vimme-test-editor/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['vimme-test-editor'],

        // inputs
        test: null,
        weeks: null,
        canChangeStatus: false,

        statusDisabled: _ember['default'].computed.not('canChangeStatus'),

        showDialog: false,

        statuses: [{
            id: 'draft',
            title: 'Draft'
        }, {
            id: 'practice',
            title: 'Practice Test'
        }, {
            id: 'published',
            title: 'Published Test'
        }],

        selectedStatus: 'draft',
        selectedGrade: null,
        selectedWeek: null,

        initSelectedStatus: (function () {
            var test = this.get('test');
            if (test) {
                if (test.get('isDraft')) {
                    this.set('selectedStatus', 'draft');
                } else if (test.get('isPractice')) {
                    this.set('selectedStatus', 'practice');
                } else {
                    this.set('selectedStatus', 'published');
                }
            }
        }).on('init').observes('test.{isDraft,isPractice}'),

        initSelectedGrade: (function () {
            var test = this.get('test');
            if (test) {
                this.set('selectedGrade', test.get('grade.id') ? test.get('grade') : null);
            }
        }).on('init').observes('test.grade.id'),

        initSelectedWeek: (function () {
            var test = this.get('test');
            if (test) {
                this.set('selectedWeek', test.get('week.id') ? test.get('week') : null);
            }
        }).on('init').observes('test.week.id'),

        setDraftPractice: (function () {
            var selectedStatus = this.get('selectedStatus');
            switch (selectedStatus) {
                case 'draft':
                    this.set('test.isDraft', true);
                    this.set('test.isPractice', false);
                    break;
                case 'practice':
                    this.set('test.isDraft', false);
                    this.set('test.isPractice', true);
                    break;
                default:
                    this.set('test.isDraft', false);
                    this.set('test.isPractice', false);
            }
        }).observes('selectedStatus'),

        isValid: _ember['default'].computed.and('test.title', 'selectedGrade', 'selectedWeek'),
        isInvalid: _ember['default'].computed.not('isValid'),

        isReallyDirty: _ember['default'].computed('test.{grade,week,isDirty}', 'selectedGrade', 'selectedWeek', function () {
            return this.get('test.isDirty') || this.get('test.grade.id') !== this.get('selectedGrade.id') || this.get('test.week.id') !== this.get('selectedWeek.id');
        }),
        isNotReallyDirty: _ember['default'].computed.not('isReallyDirty'),

        isSavable: _ember['default'].computed.and('isValid', 'isReallyDirty'),
        isNotSavable: _ember['default'].computed.not('isSavable'),

        isNotDraft: _ember['default'].computed.not('test.isDraft'),

        actions: {
            saveTestLocal: function saveTestLocal(test) {
                test.set('grade', this.get('selectedGrade'));
                test.set('week', this.get('selectedWeek'));
                this.attrs.saveTest(test);
            },
            openDialog: function openDialog() {
                this.set('showDialog', true);
            },
            cancelDialog: function cancelDialog() {
                this.set('showDialog', false);
            }
        }
    });
});