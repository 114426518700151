define('vimme/controllers/student/progress-reports', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['complete'],
        complete: false,

        sortBy: ['teacherTest.test.week.number:desc'],
        sortedStudentTests: _ember['default'].computed.sort('model.studentTests', 'sortBy'),
        realStudentTests: _ember['default'].computed.filterBy('sortedStudentTests', 'teacherTest.test.isPractice', false),
        completeStudentTests: _ember['default'].computed.filterBy('realStudentTests', 'isComplete'),

        sortColumnName: (function () {

            switch (this.get('sortColumn')) {
                case 'teacherTest.test.week.number':
                    return 'sort-test';
                case 'percent':
                    return 'sort-score';
            }
        }).property('sortColumn'),

        sortColumn: 'teacherTest.test.week.number',
        sortAscending: false,

        filteredStudentTests: (function () {

            var tests = this.get('completeStudentTests');

            if (tests) {

                tests = _ember['default'].ArrayProxy.createWithMixins(_ember['default'].SortableMixin, {
                    content: tests.toArray(),
                    sortProperties: [this.get('sortColumn')],
                    sortAscending: this.get('sortAscending')
                });
            }

            return tests;
        }).property('completeStudentTests.@each', 'sortColumn', 'sortAscending'),

        newTest: _ember['default'].computed.alias('completeStudentTests.firstObject'),
        newPercent: (function () {
            return Math.round(this.get('newTest.percent'));
        }).property('newTest.percent'),

        oldTest: (function () {
            if (this.get('completeStudentTests.length') > 1) {
                return this.get('completeStudentTests').objectAt(1);
            }
        }).property('completeStudentTests.length'),
        oldPercent: (function () {
            return Math.round(this.get('oldTest.percent'));
        }).property('oldTest.percent'),

        diffPercent: (function () {
            var newPercent = this.get('newPercent') ? this.get('newPercent') : 0;
            var oldPercent = this.get('oldPercent') ? this.get('oldPercent') : 0;
            return newPercent - oldPercent;
        }).property('newPercent', 'oldPercent'),
        formattedDiffPercent: (function () {
            var percent = this.get('diffPercent');
            if (percent === 0) {
                return '=';
            } else if (percent > 0) {
                return '+' + percent + '%';
            } else {
                return percent + '%';
            }
        }).property('diffPercent'),

        showType: (function () {
            var oldPercent = this.get('oldPercent');
            var newPercent = this.get('newPercent');
            var type = 'both';
            if (!oldPercent && oldPercent !== 0 || newPercent === 100) {
                type = 'full';
            }
            return type;
        }).property('oldPercent', 'newPercent'),

        showFull: _ember['default'].computed.equal('showType', 'full'),
        showBoth: _ember['default'].computed.equal('showType', 'both'),

        messages: (function () {
            var newPercent = this.get('newPercent');
            var diffPercent = this.get('diffPercent');

            if (newPercent === 100) {
                return ["You Rock!", "Wowzer!", "Holy Cow!", "Look At You!"];
            } else if (isNaN(diffPercent)) {
                return ['Good First Score'];
            } else if (newPercent >= 80 && newPercent < 100 && diffPercent <= 0) {
                return ["Fantastic", "Amazing Work", "Strong Score", "Keep Up the Great Work"];
            } else if (newPercent >= 70 && newPercent < 80 && diffPercent <= 0) {
                return ["Good Score", "Keep Working Hard"];
            } else if (newPercent >= 70 && diffPercent > 0) {
                return ["Nice Jump in Score", "Way to Grow", "Movin' On Up", "Great Work"];
            } else if (newPercent < 70 && diffPercent > 0 && diffPercent <= 10) {
                return ["Nice Jump in Score", "Way to Show You Are Learning"];
            } else if (newPercent < 70 && diffPercent > 10) {
                return ["That's What I'm Talking About!", "Wowzer!", "Great Jump in Score"];
            } else if (newPercent >= 50 && newPercent < 70 && diffPercent > -20) {
                return ["Way to Stay Steady", "Keep Working Hard"];
            } else if (newPercent < 70 && diffPercent <= -20) {
                return ["You Can Do This", "Let's Fix This", "We've Got Work to Do"];
            } else {
                return ["Keep Working Hard"];
            }
        }).property('newPercent', 'diffPercent'),

        message: (function () {
            var messages = this.get('messages');
            return messages[Math.floor(Math.random() * messages.get('length'))];
        }).property('messages.length'),

        actions: {

            sort: function sort(column) {

                var sortColumn = this.get('sortColumn');

                if (sortColumn === column) {
                    this.toggleProperty('sortAscending');
                } else {
                    this.set('sortColumn', column);
                    this.set('sortAscending', false);
                }
            }

        }

    });
});