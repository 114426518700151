define('vimme/components/vimme-testing-calendar-student-test/component', ['exports', 'ember', 'vimme/utils/date-in-range'], function (exports, _ember, _vimmeUtilsDateInRange) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: 'li',
        classNames: ['testing-calendar-test'],
        classNameBindings: ['isSelected'],

        // external
        teacherTest: null,
        selectedDate: null,

        firstMissed: 1,

        isSelected: (function () {

            var selected = this.get('selectedDate'),
                startDate = moment(this.get('teacherTest.startDate')),
                endDate = moment(this.get('teacherTest.endDate'));

            return !!selected && (0, _vimmeUtilsDateInRange['default'])(selected, startDate, endDate);
        }).property('selectedDate', 'teacherTest.{startDate,endDate}'),

        studentTest: (function () {

            return this.get('teacherTest.studentTests.firstObject');
        }).property('teacherTest.studentTests.@each'),

        isComplete: _ember['default'].computed.alias('studentTest.isComplete'),

        isTakable: (function () {

            var isComplete = this.get('isComplete'),
                isActive = this.get('teacherTest.isActive');

            return isActive && !isComplete;
        }).property('teacherTest.isActive', 'isComplete'),

        isReviewable: (function () {

            return this.get('teacherTest.isReviewable') && this.get('teacherTest.isViewable') && this.get('isComplete');
        }).property('teacherTest.{isReviewable,isViewable}', 'isComplete'),

        findFirstMissed: (function () {

            var isReviewable = this.get('isReviewable'),
                studentQuestions,
                firstMissed = 1,
                _this = this;

            if (isReviewable) {
                studentQuestions = this.get('studentTest.studentQuestions');
            }

            if (studentQuestions) {

                studentQuestions.then(function (studentQuestions) {

                    var questionsPromises = studentQuestions.map(function (sq) {
                        return sq.get('question');
                    });

                    _ember['default'].RSVP.all(questionsPromises).then(function () {

                        studentQuestions.toArray().sort(function (prev, next) {

                            var prevOrder = prev.get('question.order');
                            var nextOrder = next.get('question.order');

                            if (prevOrder && nextOrder) {
                                return parseInt(prevOrder, 10) - parseInt(nextOrder, 10);
                            }
                            if (prevOrder) {
                                return -1;
                            }
                            if (nextOrder) {
                                return 1;
                            }

                            return parseInt(prev.get('question.id'), 10) - parseInt(next.get('question.id'), 10);
                        }).find(function (question) {

                            if (!question.get('isCorrect')) {
                                return true;
                            }

                            firstMissed++;

                            return false;
                        });

                        if (firstMissed > studentQuestions.get('length')) {
                            _this.set('firstMissed', 1);
                        } else {
                            _this.set('firstMissed', firstMissed);
                        }
                    });
                });
            }
        }).on('init').observes('isReviewable', 'studentTest'),

        scrollTo: (function () {

            var isSelected = this.get('isSelected');

            if (isSelected) {

                _ember['default'].run.later(this, function () {

                    var el = _ember['default'].$('.testing-calendar-test.is-selected'),
                        parent = el.parent(),
                        offset = el.position().top;

                    if (offset < 0 || offset > parent.height()) {

                        offset = parent.scrollTop() + offset;

                        parent.animate({ scrollTop: offset - 8 }, 300);
                    }
                });
            }
        }).observes('isSelected')

    });
});