define("vimme/templates/teacher/quiz", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 8
            },
            "end": {
              "line": 8,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/quiz.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-quiz-question", [], ["quizQuestion", ["subexpr", "@mut", [["get", "quizQuestion", ["loc", [null, [6, 47], [6, 59]]]]], [], []], "showImage", ["subexpr", "@mut", [["get", "quizQuestion.imagePath", ["loc", [null, [6, 70], [6, 92]]]]], [], []], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [6, 99], [6, 104]]]]], [], []]], ["loc", [null, [6, 12], [6, 106]]]], ["inline", "vimme-printable-quiz-break", [], ["index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [7, 47], [7, 52]]]]], [], []], "total", ["subexpr", "@mut", [["get", "model.quizQuestions.length", ["loc", [null, [7, 59], [7, 85]]]]], [], []]], ["loc", [null, [7, 12], [7, 87]]]]],
        locals: ["quizQuestion", "index"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "vimme/templates/teacher/quiz.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "printable-quiz");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "printable-quiz__title");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "printable-quiz__name");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "printable-quiz__questions");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        return morphs;
      },
      statements: [["inline", "if", [["get", "model.quiz", ["loc", [null, [2, 43], [2, 53]]]], ["get", "model.quiz.title", ["loc", [null, [2, 54], [2, 70]]]], "New Quiz"], [], ["loc", [null, [2, 38], [2, 83]]]], ["block", "each", [["get", "model.quizQuestions", ["loc", [null, [5, 16], [5, 35]]]]], [], 0, null, ["loc", [null, [5, 8], [8, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});